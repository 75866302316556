import React from "react";
import { Grid } from "@mui/material";
import { PageLoader } from "../../../components/loader";
import plate from "../../../assets/images/plate.jpg";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DrawerComponent from "../../../components/Drawer/Index";
import MenuCardDrawer from "./MenuCardDrawer";
import { useDiner } from "../../../utils/hooks";
import NoDataComponent from "../../../components/NoDataComponent";
import { useLocation, useParams } from "react-router-dom";

function MenuCard({ list, isLoading }) {
  const { id: restId } = useParams();
  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");
  const {
    toggleDrawer,
    isDrawerOpenValue,
    setDrawerOpenValue,
    isDrawerOpen,
    OrderModel,
    PersonalizeEvent,
  } = useDiner();

  /**
   * Handles the click event for a specific item.
   * @param {{object}} item - The item that was clicked.
   * @returns None
   */
  const handleClick = (item) => {
    toggleDrawer();
    PersonalizeEvent({ itemIds: [item?._id], evenType: "CLICK" });
    setDrawerOpenValue(item);
  };

  return (
    <>
      {isLoading || !list || isLoading ? (
        <PageLoader />
      ) : (
        <div>
          <Grid
            container
            direction="column"
            sm={12}
            className={`${OrderModel ? "mb-[58px]" : ""}`}
          >
            {list.length === 0 ? (
              <NoDataComponent tittle={"Menu Not Found "} />
            ) : (
              list?.map((item) => (
                <Grid item onClick={() => handleClick(item)}>
                  <div
                    key={item?._id}
                    className="flex items-center justify-between h-[88px]  bg-white border border-gray-200 px-[16px] "
                  >
                    <div className="flex max-w-[75%] truncate">
                      <div className="max-w-[75%] flex flex-col justify-center  truncate ml-3 ">
                        <h1 className=" font-quickSand font-[700] text-[16px]">
                          {item?.item_name}
                        </h1>
                        <h2 className="font-quickSand font-[700] text-[14px]  text-[#7F7F7F]">
                          AED {item?.oPrice - item?.dPrice}
                        </h2>

                        <p className="  font-quickSand font-[500] text-[12px] w-[70vw] text-[#3F3F3F] truncate">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                    <img
                      src={item?.imageURL}
                      alt="Menu"
                      className="w-[80px]  h-[80px] rounded-[50%]"
                    />
                    {/* <ArrowForwardIosIcon /> */}
                  </div>
                </Grid>
              ))
            )}
          </Grid>
        </div>
      )}
      <DrawerComponent
        position="bottom"
        open={isDrawerOpen}
        visiblePuller={true}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
          },
        }}
        onClose={() => toggleDrawer({ restId })}
      >
        <MenuCardDrawer
          data={isDrawerOpenValue}
          addToChart={() => toggleDrawer({ restId, tableId })}
        />
      </DrawerComponent>
    </>
  );
}

export default MenuCard;
