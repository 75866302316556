import React from "react";
import logo from "../../assets/images/Frame.png";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
import { Controller } from "react-hook-form";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

export default function Login({
  toggleIcon,
  togglePasswordIcon,
  onSubmit,
  control,
  handleSubmit,
  loading,
}) {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-[100vh]">
      <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow  ">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <img
            src={logo}
            alt="Main Logo"
            className="bg-[#ED1703] rounded-t  "
          />

          <div className=" flex  flex-col gap-5  p-4 sm:p-6 md:p-8 ">
            <div>
              <Controller
                name="username"
                control={control}
                rules={{
                  required: "Username is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      label="Username"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      type={toggleIcon ? "Password" : "text"}
                      variant="outlined"
                      placeholder=""
                      label="Password"
                      postContent={
                        toggleIcon ? (
                          <RemoveRedEyeIcon
                            sx={{ color: "#171A1FFF" }}
                            onClick={togglePasswordIcon}
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{ color: "#171A1FFF" }}
                            onClick={togglePasswordIcon}
                          />
                        )
                      }
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
            <div className="text-sm font-medium flex justify-between">
              <BasicButton
                color="primary"
                type="submit"
                sx={{ textTransform: "none", width: "45%" }}
                disabled={loading}
              >
                Login
              </BasicButton>
              <BasicButton
                color="grey"
                onClick={() => navigate("onBoarding")}
                sx={{ textTransform: "none", width: "45%", color: "white" }}
              >
                Sign Up
              </BasicButton>
            </div>

            <div className="text-sm font-medium text-gray-500 flex ">
              <p
                className="text-genieRed hover:underline "
                onClick={() => {
                  navigate("/resetpassword");
                }}
              >
                Forgot Password
              </p>
            </div>
            {/* <div className="text-sm font-medium text-gray-500 flex justify-center">
            Or
            </div> 
           
             <BasicButton
              color="grey"
              type="submit"
              sx={{ textTransform: "none" }}
              disabled={loading}
            >
              Sign Up
            </BasicButton> */}
          </div>
        </form>
      </div>
    </div>
  );
}
