import React from "react";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import share from "../../../assets/Icons/share.svg";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Star } from "@mui/icons-material";

function RestaurantInfo({
  data,
  toggleDrawer,
  isDrawerOpenValue,
  isDrawerOpen,
}) {
  return (
    <>
      <div
        className={` 
          mt-[0.5rem]
          flex 
          min-h-[72px] 
          justify-between 
          items-center 
          mx-[16px] 
          px-[12px] 
          border-2 
          border-[#d9dbdf] 
          rounded-lg 
          bg-white 
          bg-opacity-60
          backdrop-blur-[10px]
          `}
      >
        <div className="flex items-center">
          <img
            src={data?.result?.logoURL}
            alt="Logo"
            className="rounded-full  w-[56px] h-[56px] object-cover"
          />
          <div className="flex flex-col justify-center ml-2">
            <h1 className="font-QuickSand font-700 text-[14px]  text-black">
              {data?.result?.name}
            </h1>
            <div className="flex items-center gap-1">
              <h3 className="font-QuickSand font-500 text-[12px] text-[#484848] underline md:max-w-full">
                {data?.result?.address}
              </h3>
              <ArrowDropDownOutlinedIcon sx={{ cursor: "pointer" }} />
            </div>
            <div className="flex items-center gap-1 justify-between">
              <span className="text-[12px] text-[#777777]">10 - 20 min</span>
              <div className="text-[#377931] flex items-center gap-[3px] ml-2">
                <Star sx={{ height: "15px", width: "15px" }} />
                <span className="font-normal">4.6</span>
              </div>
            </div>
            {/* {isDrawerOpenValue ? (
              <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
                international Cuisine
              </h3>
            ) : (
              ""
            )} */}
            {/* <h3 className="font-QuickSand font-500 text-[12px] text-[#777777]">
              {data?.result?.workingHours?.from}- {data?.result?.workingHours?.to}
            </h3> */}
          </div>
        </div>
        <div className="flex flex-col w-auto gap-2">
          <div className=" flex justify-end">
            {/* <ShareIcon sx={{ color: "#3f3f3f" }} /> */}
            <a href={data?.result?.customDomain} target="_blank" rel="noreferrer">
              <img src={share} alt="" />
            </a>
          </div>
          <div className="flex justify-around xs:gap-2">
            <a href={data?.result?.facebookURL} target="_blank" rel="noreferrer">
              <FacebookRoundedIcon sx={{ color: "#3f3f3f", height: "20px" }} />
            </a>
            <a href={data?.result?.instagramURL} target="_blank" rel="noreferrer">
              <InstagramIcon sx={{ color: "#3f3f3f", height: "20px" }} />
            </a>
            <a href={data?.result?.youtubeURL} target="_blank" rel="noreferrer">
              <YouTubeIcon sx={{ color: "#3f3f3f", height: "20px" }} />
            </a>
          </div>
          {/* {!isDrawerOpenValue ? (
            <div className=" flex justify-end" onClick={toggleDrawer}>
              <InfoOutlinedIcon />
            </div>
          ) : (
            <div className=" flex justify-end">
              <a href={data?.result?.name} target="_blank">
                <DirectionsIcon sx={{ color: "red", marginRight: "6px" }} />
              </a>
              <ShareIcon sx={{ color: "red" }} />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}

export default RestaurantInfo;
