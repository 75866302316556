import React, { useRef, useEffect, useState } from "react";

const WatermarkImage = ({ src, watermarkText, setValue, handleRegenerate }) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      const maxWidth = 200;
      const maxHeight = 200;

      const widthRatio = maxWidth / img.width;
      const heightRatio = maxHeight / img.height;
      const ratio = Math.min(widthRatio, heightRatio);

      const targetWidth = img.width * ratio;
      const targetHeight = img.height * ratio;

      canvas.width = maxWidth;
      canvas.height = maxHeight;

      // Center the image
      const xOffset = (maxWidth - targetWidth) / 2;
      const yOffset = (maxHeight - targetHeight) / 2;

      ctx.drawImage(img, xOffset, yOffset, targetWidth, targetHeight);

      // Set watermark style
      ctx.font = "16px serif";
      ctx.fillStyle = "rgba(255,255,255, 0.9)";
      ctx.textAlign = "right";
      ctx.textBaseline = "bottom";

      const padding = 10;
      ctx.fillText(watermarkText, maxWidth - 32, maxHeight - padding);

      setImageLoaded(true);
    };

    img.src = src;
  }, [src, watermarkText]);
  const handleDelete = () => {
    setValue("image", {
      url: "",
      AiGenerated: false,
    });
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="w-262 h-47 cursor-pointer mb-2">
          <canvas
            ref={canvasRef}
            style={{ display: imageLoaded ? "block" : "none" }}
          />
        </div>
        {imageLoaded &&<div className="flex mb-5">
          <div
            // onClick={handleDelete}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Like</p>
          </div>
          <div
            // onClick={handleDelete}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Dislike</p>
          </div>

          <div
            onClick={handleRegenerate}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Regenerate</p>
          </div>
          <div
            onClick={handleDelete}
            className="avatarOptions cursor-pointer text-genieRed m-4"
          >
            <p className="text-button delete">Delete</p>
          </div>
        </div>}
      </div>
      {!imageLoaded && <p>Loading...</p>}
    </div>
  );
};

export default WatermarkImage;
