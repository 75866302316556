import React, { useEffect, useState } from "react";
import { useDiner } from "../../../utils/hooks";
import BasicButton from "../../../components/Button";
import Counter from "../../../components/Counter";
import cancel_dark from "../../../assets/Icons/cancel_dark.svg"
import { Checkbox } from "@mui/material";
import spiceIcon from "../../../assets/Icons/spiceIcon.png";
import broccoli from "../../../assets/Icons/broccoli.png";
import checked_box from "../../../assets/Icons/checked_box.svg"

function MenuCardDrawer({ data, addToChart, cartList, restId, tableId, onClose, maxHeight = "90vh" }) {
  const { setCount, PersonalizeEvent } = useDiner();
  const [isExpanded, setIsExpanded] = useState(false);
  const [itemCount, setItemCount] = useState(1)
  const [currentCartItemState, setCurrentCartItem] = useState({})

  /**
   * useEffect hook that triggers a personalize event when the drawer is open.
   * @param {Function} PersonalizeEvent - The function to trigger the personalize event.
   * @param {boolean} isDrawerOpen - A boolean indicating whether the drawer is open.
   * @param {Object} data - The data object containing the _id.
   */
  // useEffect(() => {
  //   if (isDrawerOpen)
  //     PersonalizeEvent({ itemIds: [data?._id], evenType: "VIEW" });
  // }, [isDrawerOpen]);

  // useEffect(() => {
  //   increment()
  //   PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
  // }, [data?._id])

  const [customizationArray, setCustomizationArray] = useState([]);
  const handleCustomization = (customization, item) => {
    setCustomizationArray(prevArray => {
      const existingCustomizationIndex = prevArray.findIndex(c => c.customizationId === customization?._id)

      if (existingCustomizationIndex !== -1) {
        const newArray = [...prevArray]
        const existingCustomization = newArray[existingCustomizationIndex]
        const itemIndex = existingCustomization.items.indexOf(item?._id)

        if (itemIndex !== -1) {
          existingCustomization.items.splice(itemIndex, 1)
          return existingCustomization.items.length === 0
            ? newArray.filter((_, index) => index !== existingCustomizationIndex)
            : newArray
        } else {
          existingCustomization.items.push(item?._id)
          return newArray
        }
      } else {
        return [
          ...prevArray,
          {
            customizationId: customization?._id,
            title: customization?.title,
            items: [item?._id]
          }
        ]
      }
    })
  }

  const handleAddToCart = () => {
    PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
    addToChart({ restId, tableId, customizationArray }, currentCartItemState.quantity > 0 ? itemCount - currentCartItemState.quantity : itemCount)
  }

  useEffect(() => {
    const currentCartItem = cartList ? cartList.find(cartItem => cartItem.item_id === data?._id) : null
    if (currentCartItem) {
      console.log(currentCartItem)
      setCurrentCartItem(currentCartItem)
      setCustomizationArray(currentCartItem.customization.map(customization => {
        return {
          customizationId: customization.customizationId,
          title: customization.title,
          items: customization.items
        }
      }) || [])
      // setCount(currentCartItem.quantity)
      setItemCount(currentCartItem.quantity)
    } else {
      setItemCount(1)
    }
  }, [data, cartList, setCount])

  return (
    <div className={`flex flex-col justify-around w-[100vw] bg-[#f4f3f3] max-h-[${maxHeight}] overflow-hidden`}>
      <div className="self-start w-full">
        <div className="w-full px-4 pt-4 flex justify-between border-b-[2px] border-[#F8F8F8] pb-[8px]">
          <div>
            <p className="text-[14px] text-[#595454]">{data?.item_name}</p>
            <p className="text-[16px] font-medium">Customize as per your taste</p>
          </div>
          <img className="cursor-pointer self-baseline" onClick={onClose} src={cancel_dark} alt="" style={{ color: '#4A4747' }} />
        </div>
      </div>

      <div className="overflow-y-auto scroll-shadows">
        <div className={`flex justify-between border-b-[2px] border-[#F8F8F8] pb-[10px] px-4 gap-8 py-[8px] ${data?.customization?.length > 0 ? "border-b-[2px]" : "border-none"}`}>
          <div className=" flex flex-col justify-start truncate">
            {/* TODO: spice & veg logo */}
            <div className="flex items-center">
              <div className="mr-1 mb-[4px]">
                {[...Array(data?.spicinessLevel)].map((_, index) => (
                  <img
                    key={index}
                    src={spiceIcon}
                    alt="Spice"
                    className="w-3 h-3 inline-block"
                  />
                ))}
              </div>
              <div>
                {[...Array(1)].map((_, index) => (
                  <img
                    key={index}
                    src={broccoli}
                    alt="Veg"
                    className="w-3 h-3 inline-block"
                  />
                ))}
              </div>
            </div>
            <h1 className=" font-quickSand text-[18px] text-black font-normal leading-[20px] mb-[18px]">
              {data?.item_name}
            </h1>
            <p
              className={`text-wrap text-[0.75rem] text-[#3E3E3E] ${isExpanded ? 'line-clamp-none' : 'line-clamp-3'} font-normal cursor-pointer`}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {data?.description}
              {/* {data?.ingredients?.reduce((acc, ingredient, index, array) => {
                      return acc + ingredient + (index === array.length - 1 ? "" : ", ");
                    }, "")} */}
            </p>
          </div>
          <img
            src={data?.image?.url}
            alt="Menu"
            className="rounded-[10px] h-[102px] w-[115px] object-cover"
          />
        </div>

        {/* Customization Section */}
        <div className={`px-4 py-2 ${data?.customization?.length > 0 ? "block" : "hidden"}`}>
          {/* <Button onClick={() => { console.log(customizationArray) }}>Check customization</Button> */}
          {data?.customization?.map((customization, index) => {
            return (
              <div key={index}>
                <div className="flex justify-between customizations-center my-[10px]">
                  <p className="font-medium text-black">{customization.title}{customization.isRequired && <span className="text-[#B80E0C]">*</span>}</p>
                  {customization?.isRequired && <p className="font-light text-[#B80E0C]">Required</p>}
                </div>

                <div className="bg-white px-3 flex flex-col gap-2 py-[10px] rounded-[10px] border border-[#EEEEEE]">
                  {customization?.items.map((item, index) => {
                    return (
                      <div className="flex justify-between items-center">
                        <p className="font-normal text-black">{item?.itemName}</p>

                        <div className="flex gap-[6px] items-center font-normal text-black">
                          {item?.price > 0 && <p>+AED {item?.price}</p>}
                          <Checkbox
                            checkedIcon={<img className="h-[24px]" src={checked_box} alt="" />}
                            sx={{
                              color: "#595454",
                              '&.Mui-checked': {
                                color: "#377931",
                              },
                              padding: "0px",
                            }}
                            checked={customizationArray.some((customizationItem) => customizationItem.customizationId === customization?._id && customizationItem.items.includes(item?._id))}
                            onClick={() => handleCustomization(customization, item)}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {/* <div className=" mt-6 flex   items-start px-[16px]">
        <img
          src={logo}
          alt="Logo"
          className="rounded-full   w-[80px] h-[80px]"
        />
        <div className="flex flex-col ml-5 gap-0.8">
          <h1 className="font-QuickSand font-700 text-[14px]  text-[#484848]">
            {data?.item_name}
          </h1>
          <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
            AED {data?.oPrice - data?.dPrice}
          </h3>

          <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
            {data?.description}
          </h3>
          <div className="flex  items-center">
            <WarningAmberIcon
              sx={{ color: "red", fontSize: "14px", marginRight: "1px" }}
            />{" "}
            <span className="text-[14px] font-[600] text-[#3F3F3F] mr-1">
              Contains:
            </span>
            <span>
              {data?.contains?.map((item, index) => (
                <span key={index}>
                  {item.allergen}
                  {index !== data.contains.length - 1 && ", "}
                </span>
              ))}
            </span>
          </div>
        </div>
      </div> */}

      {/* <div className="flex justify-between px-[16px] mb-4 mt-[16px]">
        <button
          onClick={decrement}
          className="p-5 bg-[#D1D1D14D] rounded-full text-black"
          disabled={count <= 0}
        >
          <RemoveIcon />
        </button>
        <span className="flex items-center font-quicksand font-[600] text-[28px]">
          {count}
        </span>
        <button
          className="p-5 bg-[#ED1703] rounded-full text-white"
          onClick={() => (
            increment(),
            PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
          )}
        >
          <AddIcon />
        </button>
      </div> */}

      <div className="self-end flex items-center justify-between w-full px-4 py-3 gap-[12px] bg-white border-t border-[#d1d1d1">
        <Counter
          value={itemCount}
          increment={() => {
            // increment()
            // PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
            setItemCount(prev => prev + 1)
          }}
          decrement={() => setItemCount(prev => prev - 1)}
        />

        <BasicButton
          color="genieRed"
          sx={{
            textTransform: "none",
            width: "100%",
            height: "36px",
            padding: "9px 20px",
            color: "white",
          }}
          onClick={handleAddToCart}
        >
          {/* TODO: Add computed price */}
          <div className="font-medium">
            Add Item | <span className="text-[10px] leading-[15px] self-end">AED</span> {data?.oPrice - data?.dPrice}
          </div>
        </BasicButton>
      </div>
    </div>
  );
}

export default MenuCardDrawer;
