import React from "react";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";

function NoDataComponent({ tittle, icon = <RestaurantMenuIcon/> }) {
  return (
    <div className="flex justify-center items-center  flex-col h-[50vh] gap-4"  >
      {icon}
      <h1 className=" flex justify-center items-center text-[18px] font-Quicksand">
        {tittle}
      </h1>
    </div>
  );
}

export default NoDataComponent;
