import React from "react";
import { Navigate } from "react-router-dom";
import LocalStorageService from "../Constants/localStorage";
import { jwtDecode } from "jwt-decode";

const PublicRoute = ({ children }) => {
  const LocalStorage = LocalStorageService.getService();

  const accessLoginToken = LocalStorage.getAccessToken();

  try {
    const decoded = jwtDecode(accessLoginToken);

    if (accessLoginToken && decoded?.userType === "super-admin") {
      return <Navigate to="/superadmin/dashboard" />;
    }
    if (accessLoginToken && decoded?.userType === "restaurant-partner") {
      return <Navigate to="/restaurant/dashboard" />;
    }
  } catch (error) {
    console.log(error);
  }
  return children;
};

export default PublicRoute;
