import { Grid } from "@mui/material";
import React from "react";
import BasicButton from "../../../components/Button";
import { PageLoader } from "../../../components/loader";
import { useParams } from "react-router-dom";
import { useDiner } from "../../../utils/hooks";

function DietaryPreferences({
  dietaryPreferences,
  setDietaryPreferences,
  // setDietaryPreferencesText,
}) {
  const restaurantId = useParams();
  const { useGetFoodType } = useDiner();
  const { data: foodTypeData, isLoading: foodTypeIsLoading } = useGetFoodType(
    restaurantId?.id
  );
  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      spacing={2}
      className=" p-3   "
    >
      <Grid item>
        <h1 className="font-quicksand font-[700] text-[16px]">
          Do you have dietary preferences?
        </h1>
      </Grid>
      {!foodTypeIsLoading ? (
        foodTypeData?.result?.map((item) => {
          return (
            <Grid container item xs={12} direction="column" key={item?._id}>
              <BasicButton
                color={
                  dietaryPreferences.id === `${item?._id}` ? "primary" : "grey"
                }
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: `${
                    dietaryPreferences.id === `${item?._id}` ? "" : "#000"
                  }`,
                  fontWeight: "600",
                }}
                onClick={() =>
                  // setDietaryPreferencesText(""),
                  setDietaryPreferences({
                    id: `${item?._id}`,
                    value: `${item?.food_type}`,
                  })
                }
              >
                {item?.food_type}
              </BasicButton>
            </Grid>
          );
        })
      ) : (
        <Grid container item xs={12} direction="column">
          <PageLoader />
        </Grid>
      )}

      {/* <Grid
        container
        xs={12}
        item
        justifyContent={"center"}
        alignItems={"Center"}
        spacing={2}
      >
        <Grid item xs={5.5}>
          <div className="border-t-2 "></div>
        </Grid>
        <Grid item>
          <h1>or</h1>
        </Grid>
        <Grid item xs={5.5}>
          <div className="border-t-2 "></div>
        </Grid>
      </Grid> */}
      {/* <Grid
        container
        xs={12}
        item
        justifyContent={"center"}
        alignItems={"Center"}
      >
        <TextInput
          onChange={(e) => (
            setDietaryPreferences(""), setDietaryPreferencesText(e.target.value)
          )}
          value={dietaryPreferencesText}
          variant="outlined"
          label="Mention preference here"
          multiline
          rows={3}
        />
      </Grid> */}
    </Grid>
  );
}

export default DietaryPreferences;
