import { Grid } from "@mui/material";
import React from "react";
import BasicButton from "../../../components/Button";

function SpicePreferences({
  spicyPreferences,
  setSpicyPreferences,
  spiceLevel,
}) {
  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      spacing={2}
      className=" p-3   "
    >
      <Grid item>
        <h1 className="font-quicksand font-[700] text-[16px]">
          What is your tolerance to spicy food?
        </h1>
      </Grid>
      {spiceLevel?.result?.map((Spice) => (
        <Grid container item xs={12} direction="column">
          <BasicButton
            color={spicyPreferences?.id === `${Spice?._id}` ? "primary" : "grey"}
            variant="outlined"
            sx={{
              textTransform: "none",
              color: `${spicyPreferences?.id === `${Spice?._id}` ? "" : "#000"}`,
              fontWeight: "600",
            }}
            onClick={() =>
              setSpicyPreferences({
                id: `${Spice?._id}`,
                value: `${Spice?.spice_level}`,
              })
            }
          >
            {Spice?.spice_level}
          </BasicButton>
        </Grid>
      ))}
    </Grid>
  );
}

export default SpicePreferences;
