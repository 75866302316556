import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { FormHelperText, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function TimePickerComponent({ value, onChange, helpertext, error, label, disabled, postText }) {
  if (value) {
    const formattedTime = (new Date(`01/01/1970 ${value}`)).toLocaleTimeString('en-US', { hour12: false });
    value = dayjs().startOf('day').set("hour", formattedTime.split(":")[0]).set("minute", formattedTime.split(":")[1])
  }

  return (
    <DemoContainer
      components={["TimePicker", "MobileTimePicker", "DesktopTimePicker"]}
      sx={{ padding: "0px" }}
    >
      <DemoItem label={label}>
        <div className="flex items-center">
          <TimePicker
            value={value}
            onChange={onChange}
            disabled={disabled}
            slotProps={{
              textField: {
                helperText: helpertext,
                variant: "outlined",
                error: error,
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "0px",
                    paddingLeft: "12px",
                    color: "#7a7a7a",
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F7F7F7",
                    height: "42px",
                    width: "125px"
                  },
                  "& .MuiOutlinedInput-root": {
                    fontWeight: "400",
                    "& fieldset": {
                      border: "none"
                    },
                    borderRadius: "4px",
                    borderTopRightRadius: postText ? "0px" : "",
                    borderBottomRightRadius: postText ? "0px" : "",
                    border: "1px solid #E2E8F0"
                  },
                  "& .MuiButtonBase-root": {
                    width: "30px",
                    height: "30px",
                  }
                },
              },
              openPickerIcon: { component: (props) => <ArrowDropDownIcon {...props} sx={{ color: '#1C1B1F' }} /> },
            }}
            sx={{
              backgroundColor: "#000",
            }}
          />
          {postText &&
            <p className={`bg-white w-[61px] h-[42px] border border-[#E2E8F0] rounded-[4px] -ml-[4px] z-10 flex items-center pl-[10px] text-base ${disabled ? "text-[#999999]" : "text-[#262626]"} font-normal`}>{postText}</p>
          }
        </div>
        {error && helpertext && (
          <FormHelperText className="text-xs leading-5 text-red-600">
            <Typography variant="body2">{helpertext}</Typography>
          </FormHelperText>
        )}
      </DemoItem>
    </DemoContainer>
  );
}

export default TimePickerComponent;
