import { Button } from '@mui/material'
import React, { useRef } from 'react'

const ButtonWithIcon = (props) => {
    const {
        onClick,
        icon,
        text,
        iconComponent,
        sx,
        imageClass,
        backgroundColor,
        active = false,
        activeColor = "#e7ffd3",
        maxHeight,
        disableShadow,
        position = "left"
    } = props
    const img = useRef();

    return (
        <Button
            className={`bg-[#ebebeb] flex gap-2 items-center justify-start px-2 py-1 rounded-full ${maxHeight}`}
            variant='contained'
            sx={{
                backgroundColor: active ? activeColor : (backgroundColor || '#ebebeb'),
                color: '#000',
                '&:hover': {
                    backgroundColor: active ? activeColor : '#ebebeb',
                    color: '#000',
                    boxShadow: disableShadow ? 'none' : 'initial',
                },
                borderRadius: '20px',
                padding: '3px 8px',
                boxShadow: 'none',
                textTransform: 'none',
                fontWeight: '500',
                fontSize: "12px",
                width: 'fit-content',
                justifyContent: 'flex-start',

                ...sx
            }}
            onClick={onClick}
        >
            {position === "right" &&
                <span className='truncate'>
                    {text}
                </span>
            }
            {iconComponent ?
                <div className='bg-[#b80e0c] h-[20px] w-[20px] flex items-center justify-center rounded-full'>
                    {React.cloneElement(iconComponent, { style: { width: '12px', height: '12px', color: '#fff', display: 'flex' } })}
                </div> :
                <img ref={img} className={imageClass} src={icon} alt="" />}
            {position === "left" &&
                <span className='truncate'>
                    {text}
                </span>
            }
        </Button>
    )
}

export default ButtonWithIcon