import React from "react";
import { Navigate } from "react-router-dom";
import LocalStorageService from "../Constants/localStorage";
import { jwtDecode } from "jwt-decode";
import { getLoginUrl } from "../utils/helper";

const PrivateRoute = ({ children, roles }) => {
  const token = LocalStorageService.getAccessToken();

  // Check if token exists
  if (!token) {
    // Token is missing, redirect to login page on the login subdomain

    if (window.location?.href.includes("token")) {
      return children;
    
    } else {
      window.location.href = getLoginUrl("portal");
    }

    return null;
  }

  try {
    // Decode the token
    const decoded = jwtDecode(token);
    // // Check if user's role is allowed
    if (!roles.includes(decoded?.userType)) {
      // Navigate to unauthorized route
      return <Navigate to="/unauthorized" />;
    }
  } catch (error) {
    console.error("Error decoding token:", error.message);
    // Handle token decoding errors gracefully

    // window.location.href = getLoginUrl();
    return null; // Return null as we're doing a full page redirect
  }

  return children;
};

export default PrivateRoute;
