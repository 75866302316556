import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import logo from "../../../assets/images/Frame.png";
import { useAuth } from "../../../utils/hooks";
import { TextInput } from "../../../components/InputBox";
import BasicButton from "../../../components/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function SignUpWrapper() {
  const { toggleIcon, togglePasswordIcon, onPartnerSignUp } = useAuth();

  const { handleSubmit, control, watch } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    onPartnerSignUp(data);
  };
  const password = watch("password");

  return (
    <div className="flex justify-center items-center h-[100vh]">
      <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow  ">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <img
            src={logo}
            alt="Main Logo"
            className="bg-[#ED1703] rounded-t  "
          />

          <div className=" flex  flex-col gap-5  p-4 sm:p-6 md:p-8 ">
            <div>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      type="email"
                      variant="outlined"
                      label="Email"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone number is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      type="number"
                      variant="outlined"
                      label="Phone Number"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      type={toggleIcon ? "Password" : "text"}
                      variant="outlined"
                      placeholder=""
                      label="Password"
                      postcontent={
                        toggleIcon ? (
                          <RemoveRedEyeIcon
                            sx={{ color: "#171A1FFF" }}
                            onClick={togglePasswordIcon}
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{ color: "#171A1FFF" }}
                            onClick={togglePasswordIcon}
                          />
                        )
                      }
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="cpassword"
                control={control}
                rules={{
                  required: " confirm Password is Required",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      type={toggleIcon ? "Password" : "text"}
                      variant="outlined"
                      placeholder=""
                      label="Confirm Password"
                      postcontent={
                        toggleIcon ? (
                          <RemoveRedEyeIcon
                            sx={{ color: "#171A1FFF" }}
                            onClick={togglePasswordIcon}
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{ color: "#171A1FFF" }}
                            onClick={togglePasswordIcon}
                          />
                        )
                      }
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>

            <BasicButton
              color="primary"
              type="submit"
              sx={{ textTransform: "none" }}
              // disabled={loading}
            >
              Sign Up
            </BasicButton>
          </div>
        </form>
      </div>
    </div>
  );
}
