import { Box, Grid, Step, StepLabel, Stepper, styled } from "@mui/material";
import React, { useMemo } from "react";
import logo from "../../../assets/images/FrameTrimmed2.png";
import BasicButton from "../../../components/Button";
import { useAuth } from "../../../utils/hooks";
import { useFieldArray, useForm } from "react-hook-form";
import RestaurantLoginDetails from "./RestaurantLoginDetails";
import RestaurantDetails from "./RestaurantDetails";
import CousineDetails from "./CousineDetails";
import LocationDetails from "./LocationDetails";
import SocialMediaDetails from "./SocialMediaDetails";
import ServiceType from "./Servicetype";
import { PageLoader } from "../../../components/loader";
import { toast } from "react-toastify";
import TimingsDetails from "./TimingDeatils";
import CustomLinkDetails from "./Customlink";

function OnBoarding() {
  const {
    onPartnerSignUp,
    nextPage,
    PageNumber,
    onEmailCheckUp,
    isEmailCheckLoading,
    selectedCousine,
    previousPage,
    selectedServiceType,
    selectedType,
    onFileUploadUp,
    handleKeyDown,
    steps,
  } = useAuth();

  const { handleSubmit, control, setValue, watch, reset } = useForm({
    mode: "all",
    defaultValues: useMemo(
      () => ({
        email: "",
        phone: "",
        password: "",
        cpassword: "",
        name: "",

        Address: "",
        Location: {},
        Timing: [
          {
            day: "Monday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Tuesday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Wednesday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Thursday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Friday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Saturday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Sunday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
        ],
      }),
      []
    ),
  });

  const { fields: timingList, update } = useFieldArray({
    control,
    name: "Timing",
  });

  const onSubmit = (data) => {
    if (PageNumber == 1) {
      onEmailCheckUp(data?.email);
    }

    if (PageNumber == 2) {
      if (selectedType.length == 0) {
        toast.error("Please Select the Establishment Type ");
        return;
      } else {
        console.log(data);
        onFileUploadUp(data);
        nextPage();
      }
    }

    if (PageNumber == 3) {
      selectedCousine.length > 0
        ? nextPage()
        : toast.error("Please Select the Cousine");
    }

    if (PageNumber == 4) {
      nextPage();
    }
    if (PageNumber == 5) {
      nextPage();
    }
    if (PageNumber == 6) {
      nextPage();
    }
    if (PageNumber == 8) {
      // onPartnerSignUp(data);
    }

    if (PageNumber == 7) {
      if (selectedServiceType.length == 0) {
        toast.error("Please Select the Service Type ");
        return;
      } else {
        // onPartnerSignUp(data);
        nextPage();
      }
    }
  };

  const handleAppendSchedule = (index) => {
    const newTimingList = [...timingList];
    newTimingList[index].schedule.push({ fromTime: "", toTime: "" });
    update(index, newTimingList[index]);
    setValue("Timing", newTimingList);
  };

  const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
    color: "grey", // Default color for inactive steps
    fontSize: "1.5rem", // Increase font size for labels
    "& .MuiStepIcon-root": {
      color: "grey", // Default color for inactive icons
      fontSize: "2rem", // Increase size for icons
      "&.Mui-active": {
        color: "black", // Color for active icons
      },
      "&.Mui-completed": {
        color: "#fff", // Color for completed icons
      },
    },
    "& .MuiStepLabel-label": {
      color: "#C6C3C2 !important", // Default color for inactive labels
      fontSize: "1.2rem", // Increase font size for labels
      "&.Mui-active": {
        color: "#000 !important", // Color for active labels
      },
      "&.Mui-completed": {
        color: "#fff !important", // Color for completed labels
      },
    },
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <Grid container sx={12}>
          <Grid item xs={3} sx={{ height: "100vh" }}>
            <Grid
              container
              direction="column"
              // justifyContent="space-between"
              sx={{ background: "#ED1703", height: "100vh" }}
            >
              <img src={logo} alt="Main Logo" className=" w-1/2 mt-6 " />
              {PageNumber == 1 ? (
                <>
                  <Grid item>
                    <h1 className="text-white text-3xl  mt-[200px]  leading-normal mx-5">
                      Few Click away From Creating Restaurant
                    </h1>
                  </Grid>
                  <Grid item>
                    <h1 className="text-white text-sm mt-[20px] leading-snug mx-5">
                      Start Your Restaurant in minutes save time and money
                    </h1>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        // justifyContent: "center",
                        marginTop: "110px",
                      }}
                      className="mx-5"
                    >
                      <Stepper
                        activeStep={PageNumber - 1}
                        orientation="vertical"
                      >
                        {steps.map((label) => (
                          <Step key={label}>
                            <CustomStepLabel>{label}</CustomStepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={9} sx={{ paddingLeft: "25px" }}>
            <Grid container direction="column">
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <h1 className=" text-sm m-6">
                  having Troubles?{" "}
                  <span className="text-genieRed"> Get help</span>
                </h1>
              </Grid>

              {PageNumber == 1 && (
                <RestaurantLoginDetails control={control} watch={watch} />
              )}

              {PageNumber == 2 && <RestaurantDetails control={control} />}

              {PageNumber == 3 && <CousineDetails />}
              {PageNumber == 4 && (
                <LocationDetails control={control} setValue={setValue} />
              )}
              {PageNumber == 5 && (
                <TimingsDetails
                  watch={watch}
                  control={control}
                  handleAppendSchedule={handleAppendSchedule}
                  timingList={timingList}
                />
              )}
              {PageNumber == 6 && <SocialMediaDetails control={control} />}

              {PageNumber == 7 && <ServiceType />}
              {PageNumber == 8 && <CustomLinkDetails   control={control}/>}

              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <div className="  absolute  bottom-0 p-4 ">
                  <div className="  flex justify-end    gap-2  ">
                    {PageNumber != 1 && (
                      <BasicButton
                        variant="outlined"
                        color="primary"
                        sx={{
                          textTransform: "none",
                          width: "236px",
                          height: "36px",
                        }}
                        onClick={() => previousPage()}
                      >
                        Previous
                      </BasicButton>
                    )}
                    <BasicButton
                      color="primary"
                      type="submit"
                      sx={{
                        textTransform: "none",
                        width: "236px",
                        height: "36px",
                      }}
                      disabled={isEmailCheckLoading}
                    >
                      {isEmailCheckLoading ? (
                        <PageLoader />
                      ) : PageNumber == 8 ? (
                        "Finish"
                      ) : (
                        "Next"
                      )}
                    </BasicButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default OnBoarding;
