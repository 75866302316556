import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { TextInput } from "../../components/InputBox";
import BasicButton from "../../components/Button";
import HeaderWrapper from "../../components/headerwrapper/HeaderWrapper";
import dayjs from "dayjs";
import { useRestaurant, useSuperAdmin } from "../../utils/hooks";
import { toast } from "react-toastify";
import MapComponent from "../../Map";
import { Grid } from "@mui/material";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import mapPointer from "../../assets/Icons/map.svg";
import plus from "../../assets/Icons/plus.svg";
import { BasicModal } from "../../components/Modal/Modal";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelectDropdown from "../../components/InputBox/MultiSelect.tsx";
import { Checkbox, FormControlLabel } from "@mui/material";
import TimePickerComponent from "../../components/TimePicker";
import DragAndDropLogoPic from "../../components/Dropzone/DropAndCrop.jsx";
import penSvg from "../../assets/Icons/pen.svg";
import { CustomTextInput } from "../../components/InputBox/CustomInput.jsx";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function RestaurantInformation() {
  const { useGetAllCuisine, OnAddPartner, OnEditPartner } = useSuperAdmin();

  const { useGetPartnerInfo, autocomplete, onLoad, location, setLocation } =
    useRestaurant();

  const [addressModel, setAddressModel] = useState(false);
  const [LogoModel, setLogoModel] = useState(false);

  const handleAddress = () => {
    setAddressModel(!addressModel);
  };

  const toggleLogoModel = () => {
    setLogoModel(!LogoModel);
  };

  const { data: partnerInfo } =
    useGetPartnerInfo();

  const { data: cousine } = useGetAllCuisine();

  const { handleSubmit, control, setValue, watch, reset } = useForm({
    mode: "all",
    defaultValues: {
      Timing: [
        {
          day: "Monday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Tuesday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Wednesday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Thursday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Friday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Saturday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
        {
          day: "Sunday",
          schedule: [{ fromTime: "", toTime: "" }],
          isClosed: false,
        },
      ],
    },
  });

  const { fields: timingList, update } = useFieldArray({
    control,
    name: "Timing",
  });


  console.log(timingList);

  const handleAppendSchedule = (dayIndex) => {
    // const currentTiming = watch('Timing')
    // const currentDay = currentTiming[dayIndex];

    // if (!currentDay) {
    //   console.error(`Day at index ${dayIndex} not found`);
    //   return;
    // }

    // const updatedSchedule = [
    //   ...(currentDay.schedule || []),
    //   { fromTime: "", toTime: "" }
    // ];

    // const updatedTiming = currentTiming.map((day, index) =>
    //   index === dayIndex
    //     ? { ...day, schedule: updatedSchedule }
    //     : day
    // );

    // setValue('Timing', updatedTiming);

    const currentSchedule = watch(`Timing.${dayIndex}.schedule`) || [];
    console.log(currentSchedule, 'currentSchedule');

    const newSchedule = [...currentSchedule, { fromTime: "", toTime: "" }];


    // insert(dayIndex, {
    //   ...timingList[dayIndex],  // Keep other fields like `day`, `isClosed` intact
    //   schedule: newSchedule, // Add new slot to the schedule
    // });

    // update(dayIndex, {
    //       ...timingList[dayIndex], // Keep the rest of the day's data (day, isClosed)
    //       schedule: newSchedule,   // Update the schedule array
    //     });

    const updatedTimingList = timingList.map((item, index) =>
      index === dayIndex ? { ...item, schedule: newSchedule } : item
    );

    setValue(`Timing`, updatedTimingList);


    console.log(updatedTimingList, 'updatedTimingList');

    reset({ Timing: updatedTimingList });
  };

  // const handleRemoveSchedule = (dayIndex, slotIndex) => {
  //   // Get the current schedule
  //   const currentSchedule = timingList[dayIndex].schedule;

  //   // Check if there's more than one slot to remove
  //   if (currentSchedule.length > 1) {
  //     // Create a new schedule array without the slot to remove
  //     const newSchedule = currentSchedule.filter(
  //       (_, index) => index !== slotIndex
  //     );

  //     // Update the timingList with the new schedule
  //     const updatedTimingList = timingList.map((item, index) =>
  //       index === dayIndex ? { ...item, schedule: newSchedule } : item
  //     );

  //     // Reset the form with the updated timingList
  //     reset({ Timing: updatedTimingList });
  //   }
  // };

  // const handleAppendSchedule = (dayIndex) => {
  //   const currentSchedule = watch(`Timing.${dayIndex}.schedule`) || [];
  //   const newSchedule = [...currentSchedule, { fromTime: "", toTime: "" }];

  //   update(dayIndex, {
  //     ...timingList[dayIndex],
  //     schedule: newSchedule,
  //   });
  // };

  const handleRemoveSchedule = (dayIndex, slotIndex) => {
    const currentSchedule = timingList[dayIndex].schedule;

    if (currentSchedule.length > 1) {
      const newSchedule = currentSchedule.filter((_, index) => index !== slotIndex);

      update(dayIndex, {
        ...timingList[dayIndex],
        schedule: newSchedule,
      });
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newLocation = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        setLocation(newLocation);
        const location = `https://www.google.com/maps?q=${newLocation?.latitude},${newLocation?.longitude}`;

        const LocationObj = {
          url: location,
          lat: newLocation?.latitude.toString(),
          long: newLocation?.longitude.toString(),
        };
        setValue("Location", LocationObj);
        setValue("Address", place?.formatted_address);
      }
    }
  };

  /**
   * useEffect hook that resets form fields with partner information when partnerInfo is updated.
   * @returns None
   */
  useEffect(() => {
    if (partnerInfo?.result?._id) {
      reset({
        ...partnerInfo?.result,
        dateFrom: dayjs(partnerInfo?.result?.workingHours?.from, "hh:mm a"),
        dateTo: dayjs(partnerInfo?.result?.workingHours?.to, "hh:mm a"),
        Address: partnerInfo?.result?.address,
        Location: partnerInfo?.result?.locationURL,
        Cuisine: partnerInfo?.result?.cuisineType.map((item) => ({
          value: item,
          label: item,
        })),
        Description: partnerInfo?.result?.description,
      });

      try {
        setLocation({
          latitude: parseFloat(partnerInfo?.result?.locationURL?.lat),
          longitude: parseFloat(partnerInfo?.result?.locationURL?.long),
        });
      } catch (err) {
        toast.error("Unknown Error");
      }
    }
  }, [partnerInfo, reset, setLocation]);

  /**
   * Handles form submission by either editing an existing partner if the partner ID exists,
   * or adding a new partner if the partner ID does not exist.
   * @param {{object}} data - The data to be submitted, typically form data.
   * @returns None
   */
  const onSubmit = (data) => {
    const object = { ...data };

    if (partnerInfo?.result?._id) {
      OnEditPartner({ ...object, id: partnerInfo?.result?._id });
    } else {
      OnAddPartner(data);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude && longitude) {
            const locationUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            const locationObj = {
              url: locationUrl,
              lat: latitude.toString(),
              long: longitude.toString(),
            };

            const apiKey = process.env.REACT_APP_MAP_API_KEY;
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

            fetch(geocodeUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.status === "OK") {
                  const address = data.results[0].formatted_address;
                  setValue("Location", locationObj);
                  setValue("Address", address);
                  setLocation({ latitude, longitude, address });
                } else {
                  toast.error("Unable to fetch address.");
                }
              })
              .catch((error) => {
                console.error("Error fetching address:", error);
                toast.error("Error fetching address.");
              });
          }
        },

        // setValue("Location", locationObj);
        // setLocation({ latitude, longitude });

        (error) => {
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "The request to get user location timed out.";
              break;
            default:
              errorMessage = "An unknown error occurred.";
              break;
          }
          toast.error(errorMessage);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };
  const handleDragEvent = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    const location = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const LocationObj = {
      url: location,
      lat: latitude.toString(),
      long: longitude.toString(),
    };
    setValue("Location", LocationObj);
    setLocation({ latitude: latitude, longitude: longitude });
  };

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["places"],
  });



  return (
    <HeaderWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* {partnerInfoIsLoading ? (
          <PageLoader />
        ) : ( */}
        <div className="h-[100dvh] overflow-auto ">
          <Grid container>
            <Grid item xs={12} sx={{ borderBottom: "2px solid #1C1C1C1A" }}>
              <div className="  flex  items-center    justify-between p-4 ">
                <h3 className=" flex gap-3">
                  <span className="text-[#1C1C1C66]"> Dashboard </span>{" "}
                  <span className="text-[#1C1C1C66]">/</span>
                  <span>Restaurant information</span>
                </h3>
                <div className="max-w-[280px]  mr-4 flex gap-4 items-center ">
                  <TextInput
                    variant="outlined"
                    preContent={<SearchIcon />}
                    placeholder="search"
                  //   label="Description(optional)"
                  />
                  <NotificationsNoneOutlinedIcon />
                </div>
              </div>
            </Grid>
            <Grid
              container
            // item
            // gap={2}
            // xs={8}
            // sx={{ padding: "1rem", borderRight: "2px solid #1C1C1C1A" }}
            >
              <Grid
                container
                item
                gap={2}
                xs={8}
                sx={{ padding: "1rem", borderRight: "2px solid #1C1C1C1A" }}
              >
                <Grid item xs={12}>
                  <div className="  flex  items-center    justify-between  ">
                    <h1 className="text-[24px] ">Restaurant profile</h1>
                    <div className="flex gap-2">
                      <BasicButton
                        color="genieRed"
                        variant="outlined"
                        sx={{
                          textTransform: "none",
                          height: "36px",
                        }}
                      // disabled={addPartnerIsLoading}
                      >
                        Cancel
                      </BasicButton>
                      <BasicButton
                        color="genieRed"
                        // type="submit"
                        sx={{
                          textTransform: "none",
                          height: "36px",
                          color: "white",
                        }}
                      // disabled={addPartnerIsLoading}
                      >
                        Save Edit's
                      </BasicButton>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex ">
                    <h1 className="w-1/3 mt-3">
                      Restaurant Name <span className="text-genieRed">*</span>
                    </h1>
                    <div className="w-1/3">
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: "Restaurant Name is Required",
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <TextInput
                              {...field}
                              variant="outlined"
                              // label="Name"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex   ">
                    <h1 className="w-1/3 mt-3">
                      Type of Cuisine{" "}
                      <span className="my-auto text-genieRed">*</span>
                    </h1>
                    <div className="w-2/3">
                      <Controller
                        name="Cuisine"
                        control={control}
                        rules={{
                          required: "Cuisine is Required",
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <MultiSelectDropdown
                              name="cuisines"
                              {...field}
                              control={control}
                              options={cousine?.result.map((cuisine) => ({
                                value: cuisine.cuisine_type,
                                label: cuisine.cuisine_type,
                              }))}
                              placeholder="Select Cuisines"
                            />
                          );
                        }}
                      />
                      {/* {console.log(cousine)} */}
                    </div>
                  </div>
                </Grid>
                {/* <Grid item xs={12}>
                  <div className="flex   ">
                    <h1 className="w-1/3 mt-3">
                      Location
                      <span className="my-auto text-genieRed">*</span>
                    </h1>
                    <div
                      className="w-1/3 flex flex-col  gap-2  "
                      onClick={handleAddress}
                    >
                      <Controller
                        name="Address"
                        control={control}
                        rules={{
                          required: " Address is Required",
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <div className="flex w-full  gap-2">
                              <Autocomplete
                                onLoad={onLoad}
                                onPlaceChanged={onPlaceChanged}
                                className="w-full"
                                restrictions={{
                                  country: "AE",
                                }}
                              >
                                <TextInput
                                  {...field}
                                  variant="outlined"
                                  postContent={
                                    <div
                                      className="flex items-center text-genieRed"
                                      onClick={() => {
                                        getCurrentLocation();
                                      }}
                                    >
                                      <img src={mapPointer} />
                                    </div>
                                  }
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              </Autocomplete>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid> */}
                <Grid item xs={12}>
                  <div className="flex   ">
                    <h1 className="w-1/3 mt-3">
                      Address
                      <span className="my-auto text-genieRed">*</span>
                    </h1>
                    <div
                      className="w-1/3 flex flex-col  gap-2  "
                    // onClick={handleAddress}
                    >
                      <Controller
                        name="Description"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <TextInput
                              {...field}
                              variant="outlined"
                              error={!!error}
                              multiline
                              rows={3}
                              helperText={error ? error.message : null}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex  ">
                    <h1 className="w-1/3 mt-3">
                      Delivery range <span className="text-genieRed">*</span>
                    </h1>
                    <div className="w-1/3">
                      <Controller
                        name="range"
                        control={control}
                        rules={{
                          required: " Range is Required",
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <CustomTextInput
                              {...field}
                              type="number"
                              postContent={
                                <h1 className=" text-sm text-slate-500 bg-white">
                                  kms
                                </h1>
                              }
                              error={!!error}
                              helperText={error ? error.message : null}
                              handleClear={() => {
                                /* Clear input logic */
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex   ">
                    <h1 className="w-1/3 mt-3">
                      Delivery Fee <span className="text-genieRed">*</span>
                    </h1>
                    <div className="w-1/3">
                      <Controller
                        name="fee"
                        control={control}
                        rules={{
                          required: " Fee is Required",
                        }}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <CustomTextInput
                              {...field}
                              type="number"
                              postContent={
                                <h1 className=" text-sm text-slate-500 bg-white">
                                  /km
                                </h1>
                              }
                              error={!!error}
                              helperText={error ? error.message : null}
                              handleClear={() => {
                                /* Clear input logic */
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex   ">
                    <h1 className="w-1/3 mt-6">
                      Working Hours <span className="text-genieRed">*</span>
                    </h1>
                    <div className="w-2/3">
                      <div className="flex flex-col  mt-5">
                        {timingList?.map((days, dayIndex) => {
                          const isClosed = watch(
                            `Timing[${dayIndex}].isClosed`
                          );

                          return (
                            <div key={days?.id} className="flex gap-5">
                              <div className="flex flex-col my-2">
                                {days.schedule.map((timeSlot, slotIndex) => (
                                  <div
                                    key={slotIndex}
                                    className="flex items-center mb-2"
                                  >

                                    <div>
                                      <Controller
                                        name={`Timing[${dayIndex}].schedule[${slotIndex}].fromTime`}
                                        control={control}
                                        render={({
                                          field,
                                          fieldState: { error },
                                        }) => (
                                          <TimePickerComponent
                                            {...field}
                                            label={
                                              <label className="font-normal block text-[14px] text-[#252525]">
                                                {days.day}
                                              </label>
                                            }
                                            error={!!error}
                                            disabled={isClosed}
                                            helperText={
                                              error ? error.message : null
                                            }
                                            onChange={(time) => {
                                              const formattedTime =
                                                dayjs(time).format("hh:mm a");
                                              field.onChange(formattedTime);
                                            }}
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="ml-2">
                                      <Controller
                                        name={`Timing[${dayIndex}].schedule[${slotIndex}].toTime`}
                                        control={control}
                                        render={({
                                          field,
                                          fieldState: { error },
                                        }) => (
                                          <TimePickerComponent
                                            {...field}
                                            label={` .`}
                                            error={!!error}
                                            disabled={isClosed}
                                            helperText={
                                              error ? error.message : null
                                            }
                                            onChange={(time) => {
                                              const formattedTime =
                                                dayjs(time).format("hh:mm a");
                                              field.onChange(formattedTime);
                                            }}
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="ml-2">

                                      {isClosed ||
                                        (days.schedule.length !== 1 && (
                                          <DeleteOutlineOutlinedIcon
                                            className="text-[#7F7F7F] mt-10"
                                            onClick={() =>
                                              handleRemoveSchedule(
                                                dayIndex,
                                                slotIndex
                                              )
                                            }
                                          />
                                        ))}
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <h1 className="flex items-center mt-6">OR</h1>
                              <div className="flex items-center mt-6">
                                <Controller
                                  control={control}
                                  name={`Timing[${dayIndex}].isClosed`}
                                  render={({ field }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          {...field}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                      }
                                      label="closed"
                                    />
                                  )}
                                />
                              </div>
                              <div
                                className={`flex mt-7 ${isClosed ? "invisible" : ""
                                  }`}
                              >
                                <img
                                  src={plus}
                                  className="w-10 cursor-pointer"
                                  onClick={() => handleAppendSchedule(dayIndex)}
                                  alt="Add time slot"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex  items-center ">
                    <h1 className="w-1/3">Description</h1>
                    <div className="w-1/3">
                      <Controller
                        name="Description"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <TextInput
                              {...field}
                              variant="outlined"
                              error={!!error}
                              multiline
                              rows={3}
                              helperText={error ? error.message : null}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex  items-center ">
                    <h1 className="w-1/3">Youtube Link</h1>
                    <div className="w-1/3">
                      <Controller
                        name="youtubeURL"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <TextInput
                              {...field}
                              variant="outlined"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex  items-center ">
                    <h1 className="w-1/3">Instagram Link</h1>
                    <div className="w-1/3">
                      <Controller
                        name="instagramURL"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <TextInput
                              {...field}
                              variant="outlined"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex  items-center ">
                    <h1 className="w-1/3">FaceBook Link</h1>
                    <div className="w-1/3">
                      <Controller
                        name="facebookURL"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <TextInput
                              {...field}
                              variant="outlined"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/*                 

                right side cod
                
                
                
                
                */}

              <Grid
                container
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Grid item>
                  <h1 className="mb-2 p-[1rem] text-[#3E3E3E] font-poppins text-lg font-medium leading-[20px]">
                    Logo
                  </h1>

                  <Controller
                    name="imageOf"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="flex justify-around border-b-2 border-[#1C1C1C1A] ">
                        <div className="p-[1rem]">
                          <DragAndDropLogoPic
                            {...field}
                            close={false}
                            error={!!error}
                            helperText={error ? error.message : null}
                          //   handleRegenerate={handleRegenerate}
                          />
                        </div>
                        {field.value && (
                          <div
                            className="flex items-center ml-6"
                            onClick={toggleLogoModel}
                          >
                            <span className=" flex  items-center gap-2 rounded-lg bg-[#EBEBEB] p-1.5">
                              <img src={penSvg} alt="" /> Replace Logo
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </Grid>

                <Grid item>
                  <h1 className="mb-2 p-[1rem] text-[#3E3E3E] font-poppins text-lg font-medium leading-[20px]">
                    Banner image
                  </h1>

                  <Controller
                    name="CoverImage"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="flex justify-around border-b-2 border-[#1C1C1C1A] ">
                        {/* <DragAndDropLogoPic
                            {...field}
                            close={false}
                            error={!!error}
                            helperText={error ? error.message : null}
                            //   handleRegenerate={handleRegenerate}
                          /> */}

                        {field.value && (
                          <div
                            className="flex items-center ml-6"
                            onClick={toggleLogoModel}
                          >
                            <span className=" flex  items-center gap-2 rounded-lg bg-[#EBEBEB] p-1.5">
                              <img src={penSvg} alt="" /> Replace Logo
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* )} */}
      </form>

      {/* logo model */}
      <BasicModal
        open={LogoModel}
        // close={handleCancel}
        maxWidth="28rem"
      >
        <div className="flex flex-col gap-6 p-3">
          <div className="flex justify-between">
            <p className=" text-sm">Logo Image</p>
            <CloseIcon sx={{ fontSize: "18px" }} onClick={toggleLogoModel} />
          </div>
          <div>
            <div className=" flex  flex-col items-center bg-[#F8F8F8] gap-2">
              <Controller
                name="image"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="flex justify-around ">
                    <div className="p-[1rem]">
                      <DragAndDropLogoPic
                        {...field}
                        close={false}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="flex justify-between">
              <h6 className=" max-w-1/6 font-extralight text-xs">
                .png and .jpeg only, up to 1MB{" "}
              </h6>
              <h1
                className="font-semibold underline"
                onClick={() => {
                  setValue("image", "");
                }}
              >
                Browse Logo Image
              </h1>
            </div>
          </div>

          <div className="flex justify-end items-center gap-2">
            <BasicButton
              onClick={toggleLogoModel}
              color={"genieRed"}
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                color: "white",
              }}
            >
              Update Logo
            </BasicButton>
          </div>
        </div>
      </BasicModal>

      {/* Location Model */}
      <BasicModal
        open={addressModel}
        close={() => {
          setAddressModel(false);
        }}
        maxWidth="600px"
      >
        <div className="flex flex-col gap-6 p-3">
          <div className="flex justify-between">
            <p className=" text-sm">Location</p>
            <CloseIcon sx={{ fontSize: "18px" }} onClick={handleAddress} />
          </div>

          <div className=" flex  flex-col items-center gap-2">
            <Controller
              name="Address"
              control={control}
              rules={{
                required: " Address is Required",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className="flex w-full  gap-2">
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      className="w-full"
                      restrictions={{
                        country: "AE",
                      }}
                    >
                      <TextInput
                        {...field}
                        variant="outlined"
                        postContent={
                          <div
                            className="flex items-center text-genieRed"
                            onClick={() => {
                              getCurrentLocation();
                            }}
                          >
                            <img src={mapPointer} alt="mapPointer" />
                          </div>
                        }
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    </Autocomplete>
                  </div>
                );
              }}
            />
            <Controller
              name="Location"
              control={control}
              rules={{
                required: "Location is Required",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className="flex w-full flex-col gap-2">
                    <MapComponent
                      location={location}
                      handleDragEvent={handleDragEvent}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className="flex justify-end items-center gap-2">
            <BasicButton
              onClick={handleAddress}
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
              }}
            >
              Confirm
            </BasicButton>
          </div>
        </div>
      </BasicModal>
    </HeaderWrapper>
  );
}

export default RestaurantInformation;
