import React, { useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import BasicButton from "../components/Button";

const libraries = ["places"];

const MapComponent = ({ location, handleDragEvent }) => {
  const [map, setMap] = useState(null);

  const centerMapOnMarker = () => {
    if (map) {
      map.panTo({
        lat: location?.latitude || -33.8688,
        lng: location?.longitude || 151.2195,
      });
      map.setZoom(13);
    }
  };
  const onMapLoad = (map) => {
    setMap(map);
  };

  // if (loadError) return "Error loading maps";
  // if (!isLoaded) return "Loading Maps";
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}
      >
        <BasicButton
          variant={"text"}
          color={"black"}
          sx={{ textTransform: "none", width: "136px", height: "36px" }}
          onClick={centerMapOnMarker}
        >
          Show Marker
        </BasicButton>
      </div>

      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={{
          lat: location?.latitude || -33.8688,
          lng: location?.longitude || 151.2195,
        }}
        zoom={13}
        onLoad={onMapLoad}
        options={{ disableDefaultUI: true, zoomControl: true }}
      >
        <Marker
          key={0}
          position={{
            lat: location?.latitude,
            lng: location?.longitude,
          }}
          draggable={true}
          onDragEnd={(event) => handleDragEvent(event)}
        />
      </GoogleMap>
    </div>
  );
};
export default MapComponent;
