import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import LoginWrapper from "../pages/Login";
import Resetwrapper from "../pages/Reset";
import SuperAdminView from "../pages/SuperAdmin/Index";
import AddMenu from "../pages/Restaurant/AddMenu";
import RestaurantInformation from "../pages/AddRestaurant/AddRestaurant";
import PrivateRoute from "./Privateroute";
import MenuManagement from "../pages/Restaurant/MenuManagement";
import Unauthorized from "../pages/unauthorized";
import PublicRoute from "./PublicRoutes";
import Diner from "../pages/Dinner";
import RestaurantCart from "../pages/Dinner/Cart";
import TableManagement from "../pages/Restaurant/TableManagement";
import OrderHistory from "../pages/Restaurant/TableManagement/OrderHistory";
import RestaurantBill from "../pages/Dinner/Bill";
import RestaurantPaymentSuccess from "../pages/Dinner/PaymentSuccess";
import ClosedRestaurant from "../pages/Dinner/ClosedRestaurant";
import NoInternet from "../pages/NoInternet";
import RestaurantPaymentFailed from "../pages/Dinner/PaymentFailed";
import GenieExperience from "../pages/Dinner/GenieExperience";
import Recommendations from "../pages/Dinner/GenieExperience/Recommendations";
import ResetPasswordwrapper from "../pages/ResetPassword";
import Insights from "../pages/Restaurant/Insights";
import SignUpWrapper from "../pages/Login/Signup";
import OnBoarding from "../pages/Restaurant/OnBoarding";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <LoginWrapper />
          </PublicRoute>
        }
      />
      <Route path="/closed" element={<ClosedRestaurant />} />
      <Route path="/signup" element={<SignUpWrapper />} />
      <Route path="/onBoarding" element={<OnBoarding />} />

      <Route path="/noInternet" element={<NoInternet />} />

      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/resetpassword" element={<Resetwrapper />} />
      <Route path="/changepassword/:token" element={<ResetPasswordwrapper />} />
      {/* /**
       * Defines the routes for the super admin section of the application.
       *   - index: Renders the LoginWrapper component within a PublicRoute.
       *   - "/dashboard": Renders the SuperAdminView component within a PrivateRoute that requires "super-admin" role.
       *   - "/add": Renders the RestaurantInformation component within a PrivateRoute that requires "super-admin" role.
       */}
      <Route path="/superadmin" element={<Outlet />}>
        <Route
          index
          element={
            <PublicRoute>
              <LoginWrapper />
            </PublicRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute roles={["super-admin"]}>
              <SuperAdminView />
            </PrivateRoute>
          }
        />
        <Route
          path="add"
          element={
            <PrivateRoute roles={["super-admin"]}>
              <RestaurantInformation />
            </PrivateRoute>
          }
        />
      </Route>
      {/* /**
       * Defines the routes for the restaurant section of the application.
       * - /restaurant
       *   - /dashboard: Displays the restaurant information for authorized restaurant partners.
       *   - /menu
       */}
      <Route path="/restaurant" element={<Outlet />}>
        <Route
          path="dashboard"
          element={
            <PrivateRoute roles={["restaurant-partner"]}>
              <RestaurantInformation />
            </PrivateRoute>
          }
        />

        <Route path="menu" element={<Outlet />}>
          <Route
            index
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <MenuManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="create"
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <AddMenu />
              </PrivateRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <AddMenu />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="insights" element={<Outlet />}>
          <Route
            index
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <Insights />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="table" element={<Outlet />}>
          <Route
            index
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <TableManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="history"
            element={
              <PrivateRoute roles={["restaurant-partner"]}>
                <OrderHistory />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
      {/* /**
       * Defines nested routes for the '/genie' route, each with a specific path and corresponding element component.
       * @returns JSX elements for rendering the nested routes under the '/genie' route.
       */}
      <Route path="/genie" element={<Outlet />}>
        <Route path=":id" element={<Diner />} />
        <Route path=":id/cart" element={<RestaurantCart />} />
        <Route path=":id/experience" element={<GenieExperience />} />
        <Route
          path=":id/experience/Recommendation"
          element={<Recommendations />}
        />
        <Route path=":id/bill" element={<RestaurantBill />} />
        <Route path=":id/bill/success" element={<RestaurantPaymentSuccess />} />
        <Route path=":id/bill/failed" element={<RestaurantPaymentFailed />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
