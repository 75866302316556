import React, { useState } from "react";
import RestaurantInfo from "./RestaurantInfo";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Grid } from "@mui/material";
import { TextInput } from "../../components/InputBox";
import SearchIcon from "@mui/icons-material/Search";
import { SearchableDropDown } from "../../components/SearchableDropdown";
import CustomTabs from "../../components/Tabs";
import CustomTabPanel from "../../components/Tabs/CustomtabPanels";
import MenuCard from "./MenuCard";
import Logo from "../../assets/images/Frametrimed.png";
import DrawerComponent from "../../components/Drawer/Index";
import { useDiner } from "../../utils/Context/Dinner";
import { useNavigate, useParams, useLocation } from "react-router";
import { useSuperAdmin } from "../../utils/hooks";
import useToken from "../../utils/hooks/useToken";
import BasicButton from "../../components/Button";
import getBrowserFingerprint from "get-browser-fingerprint";
import SwipeableEdgeDrawer from "../../components/Drawer/SwipableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import { useDebounce } from "../../useDebaunce";
import ClearIcon from "@mui/icons-material/Clear";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

function Diner() {
  const [searchValue, setSearchValue] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isViewOrder, setViewOrder] = useState(false);
  const [isDrawerOrderOpen, setDrawerOrderOpen] = useState(true);
  const [isDrawerOpenValue, setDrawerOpenValue] = useState(false);
  const [foodTypeValue, setFoodTypeValue] = useState("");
  const [foodSpiceId, setFoodSpiceId] = useState("");

  const restaurantId = useParams();
  const { getCartId } = useToken();

  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");

  const { tabValue } = useSuperAdmin();
  const navigate = useNavigate();
  const {
    useGetDinerDetails,
    useGetRestaurantInfo,
    useGetFoodType,
    useGetSpiceLevel,
    useGetFoodCategory,
    useGetFoodMenuByCategory,
    useGetOrderDetails,
    OrderModel,
    setOrderModel,
    useGetCartCount,
  } = useDiner();

  /**
   * Generates a unique fingerprint for the current browser.
   * @returns A string representing the browser fingerprint.
   */
  const fingerprint = getBrowserFingerprint();

  /**
   * Toggles the state of the drawer and order model between open and closed.
   * @returns None
   */
  const toggleDrawer = () => {
    setOrderModel(!OrderModel);
    setDrawerOpen(!isDrawerOpen);
    setDrawerOpenValue(!isDrawerOpenValue);
  };
  /**
   * Toggles the visibility of the order drawer and view order state.
   * @returns None
   */
  const toggleViewOrderDrawer = () => {
    setOrderModel(!OrderModel);
    setViewOrder(!isViewOrder);
  };
  /**
   * Toggles the state of the order drawer between open and closed.
   * @returns None
   */
  const toggleOrderDrawer = () => {
    setDrawerOrderOpen(!isDrawerOrderOpen);
  };

  // const { data: dinerDetails, isLoading: dinerDetailsIsLoading } =
  useGetDinerDetails({
    dinerId: fingerprint.toString(),
    userAgent: window.navigator?.userAgent,
  });

  const { data: restaurantInfo } = useGetRestaurantInfo({
    restId: restaurantId?.id,
    tableId: tableId,
  });

  const { data: cartCount } = useGetCartCount({
    partnerId: restaurantId?.id,
    cartId: getCartId(),
  });

  const { data: OrderData } = useGetOrderDetails();
  const { data: foodType } = useGetFoodType(restaurantId?.id);
  const { data: spiceLevel } = useGetSpiceLevel({ restId: restaurantId?.id });
  const { data: menuCategory } = useGetFoodCategory(restaurantId?.id);

  const debauchedValue = useDebounce(searchValue, 1000);

  const { data: menuList, isLoading: menuListIsLoading } =
    useGetFoodMenuByCategory({
      restId: restaurantId?.id,
      menuId: menuCategory?.result[tabValue]?._id,
      query: debauchedValue,
      foodTypeId: foodTypeValue,
      spiceLevelId: foodSpiceId,
    });

  return (
    <>
      <Grid container sx={12} direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid>
        <Grid item sx={12}>
          <RestaurantInfo
            data={restaurantInfo}
            toggleDrawer={toggleDrawer}
            isDrawerOpenValue={isDrawerOpenValue}
          />
        </Grid>

        <Grid
          item
          sx={12}
          className="bg-[#eae682] h-[48px] flex items-center  z-[99] px-[16px]"
          // onClick={() => toggleOrderDrawer}
          onClick={() => navigate("experience")}
        >
          <AutoFixHighIcon />
          <h1 className=" pl-2 font-semibold font-600 text-[14px]">
            Try a different experience with Grubgenie
          </h1>
        </Grid>
        <Grid item sx={12}>
          <Grid container sx={12} className=" px-[16px] my-2 ">
            <Grid item xs={12} className="flex" alignItems={"center"}>
              <TextInput
                variant="outlined"
                placeholder={"Search"}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                sx={{
                  innerHeight: "32px",
                  "& .MuiInputBase-root.MuiOutlinedInput-root": {
                    borderRadius: 8,
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 8,
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
                precontent={<SearchIcon sx={{ color: "#7F7F7F" }} />}
                postcontent={<ClearIcon sx={{ color: "#ED1703" }} />}
                handleClear={() => setSearchValue("")}
              />
              {cartCount?.result?.cartItemCount > 0 && (
                <div className="relative" onClick={() => navigate("cart")}>
                  <ShoppingCartIcon sx={{ marginLeft: "16px" }} />
                  <div className=" absolute right-[1px] top-[15px] bg-[#ED1703] rounded-full w-[20px] h-[20px] flex justify-center items-center text-white">
                    {cartCount?.result?.cartItemCount}{" "}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} sx={12} className="px-[16px]">
            <Grid item xs={4} alignItems={"center"}>
              <SearchableDropDown
                variant="outlined"
                placeholder="Type"
                options={foodType?.result.map((item) => ({
                  label: item?.food_type,
                  value: item?._id,
                }))}
                isClearable={true}
                // value={
                //   foodType?.result?.find(
                //     (item) => item.food_type === foodTypeValue
                //   ) || ""
                // }
                onChange={(e) => {
                  setFoodTypeValue(e?.value);
                }}
                borderRadius="50px"
              />
            </Grid>
            <Grid item xs={4} alignItems={"center"}>
              <SearchableDropDown
                variant="outlined"
                placeholder="spicy"
                options={spiceLevel?.result.map((item) => ({
                  label: item?.spice_level,
                  value: item?._id,
                }))}
                isClearable={true}
                // value={
                //   foodType?.result?.find(
                //     (item) => item.food_type === foodSpiceId
                //   ) || ""
                // }
                onChange={(e) => {
                  setFoodSpiceId(e?.value);
                }}
                borderRadius="50px"
                fontSize="12px"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={12}>
          <Grid container direction="column">
            <CustomTabs
              tabs={
                menuCategory?.result.map((item) => ({
                  label: item.food_category,
                  value: item._id,
                })) || []
              }
            >
              {(value) => {
                return (
                  <>
                    <CustomTabPanel value={value} index={value}>
                      <div
                        style={{
                          // height: "auto",
                          height: "calc(100dvh - 400px)",
                        }}
                      >
                        <MenuCard
                          list={menuList?.result}
                          isLoading={menuListIsLoading}
                        />
                      </div>
                    </CustomTabPanel>
                  </>
                );
              }}
            </CustomTabs>
          </Grid>
        </Grid>
      </Grid>

      {OrderData && OrderModel ? (
        <SwipeableEdgeDrawer
          position="bottom"
          open={true}
          activeText="Total order Amount"
          activePrice={`AED ${OrderData?.result?.totalOrderValue}`}
          onClose={toggleOrderDrawer}
          // variant="permanent "
        >
          <Grid
            item
            xs={12}
            direction="column"
            className="p-2 flex justify-around"
            height={"100%"}
          >
            {/* {cartData?.result?.notes && ( */}
            <div className="flex justify-between  items-center  ">
              <div className="flex flex-col">
                <div className="text-quicksand font-[600] text-[14px] flex flex-col">
                  <h1 className="text-[#7F7F7F] font-semibold font-[600] text-[14px]">
                    Note
                  </h1>
                  {OrderData?.result?.notes ? OrderData?.result?.notes : "-"}
                </div>
              </div>
            </div>
            {/* )} */}
            <div className="flex w-full  gap-2 items-evenly my-3 ">
              <BasicButton
                color="primary"
                type="submit"
                variant="outlined"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  height: "36px",
                  padding: "9px 20px",
                }}
                onClick={toggleViewOrderDrawer}
              >
                View order
              </BasicButton>
              <BasicButton
                color="primary"
                type="submit"
                sx={{
                  textTransform: "none",
                  width: "100%",
                  height: "36px",
                  padding: "9px 20px",
                }}
                onClick={() => navigate("bill")}
              >
                Request bill
              </BasicButton>
            </div>
          </Grid>
        </SwipeableEdgeDrawer>
      ) : (
        ""
      )}

      {isDrawerOpen && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={isDrawerOpen}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              height: "150px",
            },
          }}
          onClose={toggleDrawer}
        >
          <RestaurantInfo
            data={restaurantInfo}
            toggleDrawer={toggleDrawer}
            isDrawerOpen={isDrawerOpen}
            isDrawerOpenValue={isDrawerOpenValue}
          />
        </DrawerComponent>
      )}

      {isViewOrder && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={isViewOrder}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              height: "auto",
            },
          }}
          onClose={toggleViewOrderDrawer}
        >
          <Grid container className="p-2 ">
            <Grid item xs={12} className=" p-2 border-b-[2px]">
              <h1>Your order</h1>
            </Grid>
            <Grid item xs={12} className="border-b-[2px] p-2">
              <div className="flex justify-between  items-center  ">
                <div className="flex flex-col">
                  <div className="text-quicksand font-[600] text-[14px] flex flex-col">
                    <h1 className="text-[#7F7F7F] font-semibold font-[600] text-[14px]">
                      Note
                    </h1>
                    {OrderData?.result?.notes ? OrderData?.result?.notes : "-"}
                  </div>
                </div>
              </div>
            </Grid>
            {OrderData?.result?.orderDetails.map((item) => (
              <Grid item xs={12} className="border-b-[2px] p-2 overflow-auto">
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <h1 className="font-quicksand font-[600] max-w-52 text-[14px] text-[#484848]">
                      {item?.item_name ? item?.item_name : "-"}
                    </h1>
                    <div
                      className={` w-fit px-2 font-quicksand font-[600] text-[12px] text-[#3F3F3F] bg-[#33C442] rounded-full px-1`}
                    >
                      {item?.status ? item?.status : "-"}
                    </div>
                  </div>
                  <div className="  absolute right-[10px] font-quicksand font-[700]">
                    <span className="  text-[16px] text-genieRed">
                      {item?.quantity} <CloseIcon className="text-genieRed" />
                    </span>{" "}
                    <span className="text-[16px]">
                      AED {item?.oPrice - item?.dPrice}
                    </span>
                  </div>
                </div>
              </Grid>
            ))}

            <Grid
              container
              item
              xs={12}
              direction="column"
              // justifyContent={"flex-end"}
              // alignItems="flex-end"
              className="px-3 pt-2"
            >
              <div className="flex justify-between ">
                <div>
                  <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                    Total
                  </h1>
                  <h3 className="font-quicksand font-[500] text-[12px] text-[#777777]">
                    ({OrderData?.result?.orderDetails?.length}{" "}
                    {OrderData?.result?.orderDetails?.length > 1
                      ? "items"
                      : "item"}
                    )
                  </h3>
                </div>
                <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                  AED {OrderData?.result?.totalOrderValue}
                </h1>
              </div>
              <div className="flex  pt-2">
                <BasicButton
                  color="primary"
                  type="submit"
                  // width: "236px", height: "36px"
                  sx={{ textTransform: "none", width: "100%" }}
                  onClick={() => navigate("bill")}
                >
                  Request bill
                </BasicButton>
              </div>
            </Grid>
          </Grid>
        </DrawerComponent>
      )}
    </>
  );
}

export default Diner;
