import React, { useContext, createContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AddPatner,
  editPartner,
  editPartnerByAdmin,
  getAllCuisine,
  getAllRestaurantList,
} from "../../controllers/superAdmin";

const superAdminContext = createContext(undefined);

export const useSuperAdmin = () => useContext(superAdminContext);

function useProvideSuperAdmin() {
  const queryClient = useQueryClient();
  const [tabValue, setTabValue] = useState(0);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleTabChange = (_id, newValue) => {
    setTabValue(newValue);
    setDrawerOpen(false);
  };

  /**
   * Custom React query hook to fetch all cuisine data.
   */
  const useGetAllCuisine = () =>
    useQuery(["getAllCuisine"], () => getAllCuisine(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => {
        // TokenExpiry(data?.ResponseCode);
      },
    });
  const useGetAllRestaurantList = () =>
    useQuery(["getAllRestaurantList"], () => getAllRestaurantList(), {
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (data) => {
        // TokenExpiry(data?.ResponseCode);
      },
    });

  /**
   * Adds a partner using the AddPartner mutation and handles success and error cases.
   * @param {{Object}} data - The data of the partner to be added.
   * @returns None
   */
  const { mutate: mutateAddPartner, isLoading: addPartnerIsLoading } =
    useMutation(AddPatner, {
      onSuccess: async (res) => {
        toast.success("Partner added Successfully");
        navigate("/superadmin/dashboard");
        queryClient.refetchQueries("getAllRestaurantList");
      },
      onError: (error) => {
        console.log(error);
      },
    });
  const OnAddPartner = (data) => {
    const transformedData = {
      name: data?.name,
      workingHours: {
        from: dayjs(data.dateFrom).format("hh:mm a"),
        to: dayjs(data.dateTo).format("hh:mm a"),
      },
      address: data?.Address,
      locationURL: data?.Location,
      cuisineType: data?.Cuisine.map((item) => item.value),
      description: data?.Description,
      facebookURL: data?.facebookURL,
      instagramURL: data?.instagramURL,
      youtubeURL: data?.youtubeURL,
    };

    mutateAddPartner(transformedData);
  };

  /**
   * Function to edit a partner using the editPartner mutation.
   * @param {{Object}} data - The data of the partner to be edited.
   * @returns None
   */
  const { mutate: mutateEditPartner } = useMutation(editPartner, {
    onSuccess: async (res) => {
      toast.success("Partner updated Successfully");
      queryClient.refetchQueries("getPartnerInfo");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const { mutate: mutateEditPartnerByAdmin } = useMutation(editPartnerByAdmin, {
    onSuccess: async (res) => {
      toast.success("Partner updated Successfully");
      queryClient.refetchQueries("getAllRestaurantList");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const OnEditPartner = (data) => {
    const transformedData = {
      _id: data?.id,
      name: data?.name,
      workingHours: {
        from: dayjs(data.dateFrom).format("hh:mm a"),
        to: dayjs(data.dateTo).format("hh:mm a"),
      },
      address: data?.Address,
      locationURL: {
        url : data?.Location?.url,
        lat :data?.Location?.lat,
        long :data?.Location?.long,
      },
      cuisineType: data?.Cuisine.map((item) => item.value),
      description: data?.Description,
      facebookURL: data?.facebookURL,
      instagramURL: data?.instagramURL,
      youtubeURL: data?.youtubeURL,
    };

    mutateEditPartner(transformedData);
  };

  const OnEditIsActivePartner = (data) => {
    mutateEditPartnerByAdmin(data);
  };


  return {
    tabValue,
    handleTabChange,
    isDrawerOpen,
    toggleDrawer,
    openLogoutModal,
    setOpenLogoutModal,

    // api calling get
    useGetAllCuisine,
    useGetAllRestaurantList,

    // post partner
    OnAddPartner,
    addPartnerIsLoading,

    // edit Partner
    OnEditPartner,
    OnEditIsActivePartner,
  };
}

export function ProvideSuperAdmin({ children }) {
  const superAdmin = useProvideSuperAdmin();
  return (
    <superAdminContext.Provider value={superAdmin}>
      {children}
    </superAdminContext.Provider>
  );
}
