import React from "react";
import { Grid } from "@mui/material";
import { PageLoader } from "../../../components/loader";
import DrawerComponent from "../../../components/Drawer/Index";
import MenuCardDrawer from "./MenuCardDrawer";
import { useDiner } from "../../../utils/hooks";
import NoDataComponent from "../../../components/NoDataComponent";
import { useLocation, useParams } from "react-router-dom";
import spiceIcon from "../../../assets/Icons/spiceIcon.png";
import broccoli from "../../../assets/Icons/broccoli.png";
import { Star } from "@mui/icons-material";
import Counter from "../../../components/Counter";
import DetailsDropdown from "../../../components/DetailsDropdown";
import customization from "../../../assets/Icons/customization.svg";

function MenuCard({ list, isLoading, cartList }) {
  const { id: restId } = useParams();
  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");
  // const [loadingItem, setLoadingItem] = useState(false)
  const {
    toggleDrawer,
    isDrawerOpenValue,
    setDrawerOpenValue,
    isDrawerOpen,
    // OrderModel,
    PersonalizeEvent,
    OnAddCart,
    // cartResponseLoading
    addCartMenuIsLoading,
    loadingItems
  } = useDiner();

  /**
   * Handles the click event for a specific item.
   * @param {{object}} item - The item that was clicked.
   * @returns None
   */
  const handleClick = (item) => {
    toggleDrawer();
    PersonalizeEvent({ itemIds: [item?._id], evenType: "CLICK" });
    setDrawerOpenValue(item);
  };

  const handleIncrement = (item) => {
    PersonalizeEvent({ itemIds: [item?._id], evenType: "CART" })
    OnAddCart({
      itemId: item?._id,
      count: 1,
      partnerId: restId,
      tableSequence: tableId,
    });
  };

  const handleDecrement = (item) => {
    PersonalizeEvent({ itemIds: [item?._id], evenType: "CART" })
    OnAddCart({
      itemId: item?._id,
      count: -1,
      partnerId: restId,
      tableSequence: tableId,
    });
  };

  return (
    <>
      {isLoading || !list || isLoading ? (
        <div className="h-[50vh] flex justify-center items-center">
          <PageLoader />
        </div>
      ) : (
        <div>
          <Grid
            container
            direction="column"
            sm={12}
            // className={`${OrderModel ? "mb-[58px]" : ""} px-4 mt-2`}
            className={` px-4 mt-2`}
            rowGap={2}
          >
            {list.length === 0 ? (
              <NoDataComponent tittle={"Menu Not Found "} />
            ) : (
              list?.map((item) => (
                <Grid item>
                  <div
                    key={item?._id}
                    className="flex justify-between border-b border-[#f3f0f3] pb-[10px]"
                  >
                    <div className="flex  truncate">
                      <div className=" flex flex-col justify-start  truncate ">
                        {/* TODO: Veg logo */}
                        <div className="flex items-center">
                          <div className="mr-1 mb-[4px]">
                            {[...Array(item?.spicinessLevel)].map((_, index) => (
                              <img
                                key={index}
                                src={spiceIcon}
                                alt="Spice"
                                className="w-3 h-3 inline-block"
                              />
                            ))}
                          </div>
                          <div>
                            {[...Array(1)].map((_, index) => (
                              <img
                                key={index}
                                src={broccoli}
                                alt="Veg"
                                className="w-3 h-3 inline-block"
                              />
                            ))}
                          </div>
                        </div>
                        <h1 className=" font-quickSand text-[18px] text-black font-normal leading-[20px] mb-[6px]">
                          {item?.item_name}
                        </h1>
                        <div className="min-w-[6.5rem] flex items-center justify-between mr-auto mb-[12px]">
                          <h2 className="font-quickSand text-[14px] text-black font-bold">
                            AED {(item?.oPrice - item?.dPrice).toFixed(2)}
                          </h2>

                          <div className="text-[#377931] flex items-center gap-[3px] ml-2">
                            <Star sx={{ height: "15px", width: "15px" }} />
                            <span className="font-normal">4.6</span>
                          </div>
                        </div>

                        <DetailsDropdown headerText="More Details"
                          // content={item?.ingredients?.reduce((acc, ingredient, index, array) => {
                          //   return acc + ingredient + (index === array.length - 1 ? "" : ", ");
                          // }, "")}
                          content={item?.description}
                          className="mb-[8px]"
                        />

                        {/* TODO: Customization */}
                        <div className={`flex items-center gap-[4px] cursor-pointer ${item?.customization.length > 0 ? "" : "hidden"}`}
                          onClick={() => handleClick(item)}
                        >
                          <img src={customization} alt="" />
                          <span className="text-[#b80e0c] underline">Customize</span>
                        </div>
                      </div>
                    </div>
                    <div className="relative h-fit min-w-[115px] ml-4">
                      <img
                        src={item?.image?.url}
                        alt="Menu"
                        className="rounded-[10px] h-[102px] w-[115px] object-cover cursor-pointer"
                        onClick={() => handleClick(item)}
                      />
                      {/* TODO: cart integration */}
                      <div className="w-full flex justify-center items-center absolute -bottom-3">
                        <Counter
                          value={cartList ? cartList.find(cartItem => cartItem.item_id === item?._id)?.quantity ?? 0 : 0}
                          increment={() => {
                            // setLoadingItem(item?._id)
                            handleIncrement(item)
                          }}
                          decrement={() => {
                            // setLoadingItem(item?._id)
                            handleDecrement(item)
                          }}
                          isLoading={addCartMenuIsLoading && loadingItems.includes(item?._id)}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ))
            )}
          </Grid>
        </div>
      )}
      <DrawerComponent
        position="bottom"
        open={isDrawerOpen}
        // visiblePuller={true}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
            maxHeight: "90vh", // Added max height
            boxShadow: "none", // Remove shadow
          },
        }}
        onClose={() => toggleDrawer({ restId })}
      >
        <MenuCardDrawer
          maxHeight="90vh"
          data={isDrawerOpenValue}
          addToChart={toggleDrawer}
          cartList={cartList}
          restId={restId}
          tableId={tableId}
          onClose={() => toggleDrawer({ restId })}
        />
      </DrawerComponent>
    </>
  );
}

export default MenuCard;
