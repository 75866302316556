import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";

function SocialMediaDetails({ control }) {
  return (
    <Grid item container>
      <Grid item xs={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">
          Description & Social media Links
        </h1>
        <h3 className="text-sm font-medium mt-4 mx-5">
          You can Generate the Description
        </h3>
      </Grid>

      <Grid
        item
        sx={{
          width: "100%",
          marginX: "1.25rem",
          overflowY: "scroll",
          marginTop: "10px",
          height: "calc(100vh - 250px)",
        }}
      >
        <Grid container direction={"column"} item spacing={2} xs={11}>
          <Grid item xs={12}>
            <div className="max-w-[472px]">
              <Controller
                name="Description"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      label="Description(optional)"
                      error={!!error}
                      multiline
                      rows={3}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="max-w-[472px]">
              <Controller
                name="facebookURL"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      label="Facebook(optional)"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="max-w-[472px]">
              <Controller
                name="instagramURL"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      label="Instagram(optional)"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="max-w-[472px]">
              <Controller
                name="youtubeURL"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      label="Youtube(optional)"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="max-w-[472px]">
              <Controller
                name="zomatoUrl"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      label="Zomato(optional)"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SocialMediaDetails;
