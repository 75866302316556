import React from "react";

import { Grid } from "@mui/material";
import { useAuth, useSuperAdmin } from "../../../utils/hooks";
import { PageLoader } from "../../../components/loader";
import CheckIcon from "@mui/icons-material/Check";

function CousineDetails() {
  const { useGetAllCuisine } = useSuperAdmin();
  const { data: cousine, isLoading: cousineIsLoading } = useGetAllCuisine();
  const { selectedCousine, setSelectedCousine } = useAuth();

  return (
    <Grid item container direction="column" className="overflow-auto">
      <Grid item>
        <h1 className=" text-3xl mt-[20px]  mx-5">Cuisine Details</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">
          You Can Select multiple cuisine!
        </h3>
      </Grid>

      <Grid
        item
        sx={{
          marginTop: "30px",
          width: "90%",
          marginX: "1.25rem",
          overflowY: "scroll",
          height: "calc(100vh - 250px)",
        }}
      >
        {cousineIsLoading ? (
          <PageLoader />
        ) : (
          <Grid container item spacing={2}>
            {cousine?.result?.map((itemsList, index) => (
              <Grid
                item
                key={index}
                xs={4}
                onClick={() => {
                  setSelectedCousine((prevSelectedCousine) => {
                    if (!prevSelectedCousine.includes(itemsList?._id)) {
                      return [...prevSelectedCousine, itemsList?._id];
                    } else {
                      return prevSelectedCousine.filter(
                        (item) => item !== itemsList?._id
                      );
                    }
                  });
                }}
              >
                <div
                  className={`rounded  border  hover:border-[#ED1703]  hover:border-2  ${
                    selectedCousine.includes(itemsList?._id)
                      ? "bg-red-50 border-[#ED1703] border-2"
                      : "bg-white"
                  } p-10 flex border justify-center gap-2 hover:bg-red-50`}
                >
                  {/* <itemsList.icons sx={{ color: "#ED1703" }} /> */}
                  {selectedCousine?.includes(itemsList?._id) && (
                    <CheckIcon sx={{ color: "#ED1703" }} />
                  )}
                  <h1>{itemsList?.cuisine_type}</h1>
                </div>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default CousineDetails;
