import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";

function CustomLinkDetails({ control }) {
  return (
    <Grid item container>
      <Grid item xs={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">Online Ordering Link</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">Custom link</h3>
      </Grid>

      <Grid
        item
        sx={{
          width: "100%",
          marginX: "1.25rem",
          overflowY: "scroll",
          marginTop: "10px",
          height: "calc(100vh - 250px)",
        }}
      >
        <Grid container direction={"column"} item spacing={2} xs={11}>
          <Grid item xs={12}>
            <div className=" flex mt-10">
              <h1 className=" h-full  text-4xl">Link:Order.grubgenie.ai/</h1>
              <Controller
                name="CustomUrl"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className=" max-w-[546px] flex mt-10">
              <h1 className=" h-full  text-4xl">Delivery:</h1>
              <Controller
                name="DeliveryRadius"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextInput
                      {...field}
                      variant="outlined"
                      type='number'
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  );
                }}
              />
              <h1 className=" h-full  text-3xl">Km</h1>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CustomLinkDetails;
