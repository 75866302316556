// import dayjs  from "dayjs";
import React from "react";
import MultiDatePicker from "react-multi-date-picker";
import CalendarTodayRoundedIcon from '../../assets/Icons/calendar_month.svg';


const DatePicker = ({ value = new Date(), onChange, placeholder }) => {
  //  const disabledAfterToday = (date) => {
  //   return date.isAfter(dayjs(), "day");
  //  };

  return (
    <div className="relative w-fit">
      <MultiDatePicker
        inputClass="h-[32px] w-[300px] border border-1 border-[#E3E3E3] rounded-[4px] pl-2 text-[20px]"
        value={value}
        onChange={onChange}
        format="DD MMM YYYY"
        placeholder={placeholder}
        clearable
        range
        dateSeparator=" - "
        portal
      //   animations={[transition()]}
      // disabled={disabledAfterToday}  
      />
      <img src={CalendarTodayRoundedIcon} alt="datePicker" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={20} />
    </div>
  );
};

export default DatePicker;
