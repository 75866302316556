import { Dialog } from "@mui/material";
import React from "react";

export function BasicModal({ open, close, children, fullScreen, maxWidth }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
      >
        {children}
      </Dialog>
    </div>
  );
}
