import { ProvideAuth, useAuth } from "../Context/auth";
import { useSuperAdmin, ProvideSuperAdmin } from "../Context/SuperAdmin";
import { ProvideRestaurant, useRestaurant } from "../Context/Restaurant";
import { ProvideDiner, useDiner } from "../Context/Dinner";

export {
  ProvideAuth,
  useAuth,
  useSuperAdmin,
  ProvideSuperAdmin,
  ProvideRestaurant,
  useRestaurant,
  ProvideDiner,
  useDiner,
};
