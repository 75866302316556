import { Grid } from "@mui/material";
import React from "react";

import { useDiner } from "../../../utils/hooks";
import { useNavigate } from "react-router";
import { PageLoader } from "../../../components/loader";
import Logo from "../../../assets/images/Frametrimed.png";
import AppleIcon from "@mui/icons-material/Apple";

import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MoneyIcon from "@mui/icons-material/Money";

function RestaurantBill() {
  const { addPayment, useGetOrderDetails, setOrderModel, OrderModel } =
    useDiner();

  const navigate = useNavigate();

  const { data: OrderData, isLoading: orderDataIsLoading } =
    useGetOrderDetails();

  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="border-b-[2px] p-3 border-gray-300  "
        >
          <h1 className="text-quicksand  font-[700] text-[16px]">Your Bill</h1>
          <CloseIcon
            onClick={() => (navigate(-1), setOrderModel(!OrderModel))}
          />
        </Grid>
        <>
          {orderDataIsLoading ? (
            <PageLoader />
          ) : (
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className=" max-h-[60vh] overflow-auto"
            >
              {OrderData?.result?.orderDetails?.map((cartItem) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={cartItem?._id}
                    className="border-b-[2px]   m-5 overflow-auto"
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      justifyContent="space-between"
                      className="p-3"
                    >
                      <h1 className="  font-quicksand font-[600] text-[16px] truncate w-[50%]  ">
                        <span className="font-[700] text-[16px] text-genieRed  ">
                          <span className="mr-1">{cartItem?.quantity}</span>
                          <CloseIcon
                            sx={{ fontSize: "16px", fontWeight: "700" }}
                          />
                        </span>
                        <span className=" font-[600] text-[14px] ml-1  ">
                          {cartItem?.item_name}
                        </span>
                      </h1>
                      <h1 className=" font-quicksand font-[700] text-[16px] flex  justify-end items-center w-[35%] truncate   ">
                        AED {cartItem?.oPrice - cartItem?.dPrice}
                      </h1>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
          <Grid
            container
            item
            xs={12}
            direction="column"
            className="px-5 pt-5 absolute bottom-[20px] border-t-[2px] bg-white  "
          >
            <div className="flex justify-between ">
              <div>
                <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                  Total
                </h1>
                <h3 className="font-quicksand font-[500] text-[12px] text-[#777777]">
                  ({OrderData?.result?.length}{" "}
                  {OrderData?.result?.length > 1 ? "items" : "item"})
                </h3>
              </div>
              <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                AED {OrderData?.result.totalOrderValue}
              </h1>
            </div>
            <div className="flex justify-between  items-center  pt-2">
              <div className="font-quicksand p-3 border-2 rounded border-[#D1D1D1] flex items-center">
                <AppleIcon /> Pay
              </div>
              <div className="font-quicksand p-3 border-2 rounded border-[#D1D1D1] flex items-center">
                <CreditCardIcon />
                Card
              </div>
              <div
                className="font-quicksand p-3 border-2 rounded border-[#D1D1D1] flex items-center"
                onClick={() => (navigate("success"), addPayment())}
              >
                <MoneyIcon /> Cash
              </div>
            </div>
          </Grid>
        </>
      </Grid>
    </>
  );
}

export default RestaurantBill;
