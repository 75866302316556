import React, { useContext, createContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useToken from "../../hooks/useToken";
import {
  getAllDinerDetails,
  getAllRestaurantInfo,
  getAllFoodType,
  getAllSpiceLevel,
  getAllFoodCategory,
  getAllFoodMenuByCategory,
  AddCartMenu,
  getAllCartItem,
  noteAdd,
  PlaceOrder,
  getAllOrderItem,
  Payment,
  Rating,
  getAllCartCount,
  getPersonalizeEvent,
  getAllGenieExp,
  getItemByQuery,
  getCartItemDetails
} from "../../controllers/diner";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DinerContext = createContext(undefined);

export const useDiner = () => useContext(DinerContext);

function useProvideDiner() {
  const queryClient = useQueryClient();

  const [isDrawerOpenValue, setDrawerOpenValue] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [cartList, setCartList] = useState([]);
  const [cartListDiff, setCartListDiff] = useState([]);
  const [count, setCount] = useState(0);
  const [OrderModel, setOrderModel] = useState(true);
  const [ratingId, setRatingId] = useState("");
  const [isDrawerNoteOpen, setDrawerNoteOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [orderResult, setOrderResult] = useState({});
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [hover2, setHover2] = useState(0);
  const [suggestion, setSuggestion] = useState("");
  const [OrderId, setOrderId] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [genieCheck, setGenieCheck] = useState(false);
  const [genieRecommendation, setGenieRecommendation] = useState([]);
  const [countDiner, setCountDiner] = useState({
    kidsCount: 0,
    adultCount: 1,
  });
  const [dietaryPreferences, setDietaryPreferences] = useState({
    id: "",
    value: "",
  });
  const [allergicPreferences, setAllergicPreferences] = useState("");
  const [spicyPreferences, setSpicyPreferences] = useState({
    id: "",
    value: "",
  });
  const [cartResponseLoading, setCartResponseLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState([])
  // const [dietaryPreferencesText, setDietaryPreferencesText] = useState("");

  const [allergicData, setAllergicData] = useState([
    { id: 1, label: "Dairy", checked: false },
    { id: 2, label: "Gluten", checked: false },
    { id: 3, label: "Shellfish", checked: false },
    { id: 4, label: "Soy", checked: false },
    { id: 5, label: "Nuts", checked: false },
    { id: 6, label: "Eggs", checked: false },
  ]);

  const {
    setToken,
    saveUser,
    saveCartID,
    getCartId,
    getUserDetails,
    token,
    saveOrderID,
    getOrderId,
    RemoveCartID,
    RemoveOrderID,
  } = useToken();

  const navigate = useNavigate();
  /**
   * Increments the adult count in the diner state by 1.
   */
  const incrementAdultCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      adultCount: prevCount.adultCount + 1,
    }));
  };

  /**
   * Decrements the adult count in the diner state by 1.
   * @returns None
   */
  const decrementAdultCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      adultCount: Math.max(0, prevCount.adultCount - 1),
    }));
  };

  /**
   * Increments the count of kids in the diner state by 1.
   * @returns None
   */
  const incrementKidsCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      kidsCount: prevCount.kidsCount + 1,
    }));
  };

  /**
   * Decrements the count of kids in the diner by 1.
   * @returns None
   */
  const decrementKidsCount = () => {
    setCountDiner((prevCount) => ({
      ...prevCount,
      kidsCount: Math.max(0, prevCount.kidsCount - 1),
    }));
  };

  /**
   * Handles the change event for a checkbox with the given id.
   * Toggles the 'checked' property of the item with the matching id in the data array.
   * @param {{number}} id - The id of the checkbox item to toggle.
   * @returns None
   */
  const handleCheckboxChange = (id) => {
    setAllergicData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const increment = () => {
    setCount(prev => prev + 1);
  };

  const decrement = () => {
    setCount(prev => prev - 1);
  };

  const toggleDrawer = (restData, itemCount) => {
    setOrderModel(!OrderModel);
    setDrawerOpen(!isDrawerOpen);

    if (itemCount || count > 0 || restData?.customizationArray) {
      OnAddCart({
        itemId: isDrawerOpenValue._id,
        count: itemCount ? itemCount : count,
        restId: restData?.restId,
        tableSequence: restData?.tableId,
        customization: restData?.customizationArray ? restData?.customizationArray : [],
      });
    }
    setCount(0);
  };

  const toggleNoteDrawer = () => {
    setDrawerNoteOpen(!isDrawerNoteOpen);
  };

  const handleNoteChange = (e) => {
    setNoteValue(e.target.value);
  };

  const useGetDinerDetails = () =>
    useQuery(["getAllDinerDetails"], () => getAllDinerDetails(), {
      // enabled: !!!getUserDetails(),
      enabled: true,
      select: (data) => data?.data,
      onSuccess: (res) => {
        if (getUserDetails() == null) {
          if (res?.result) {
            setToken({
              access_token: res?.result?.accessToken,
              refresh_token: res?.result?.refreshToken,
            });
            saveUser(res?.result);
            axios.defaults.headers.common.Authorization = `Bearer ${res?.result?.accessToken}`;
          }
        }
      },
    });

  const useGetRestaurantInfo = ({ restId, tableId }) =>
    useQuery(
      ["getAllRestaurantInfo", restId, tableId],
      () => getAllRestaurantInfo({ restId, tableId }),
      {
        enabled: !!token && !!restId && !!tableId,
        select: (data) => data?.data,
        onSuccess: (res) => {
          if (!res?.result?.isActive) {
            navigate("closed", { state: res?.result });
          }
        },
      }
    );

  const useGetFoodType = (id) =>
    useQuery(["getAllFoodType", id], () => getAllFoodType(id), {
      enabled: !!token && !!id,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetCartCount = ({ partnerId, cartId }) =>
    useQuery(
      ["getAllCartCount", partnerId, cartId],
      () => getAllCartCount({ partnerId, cartId }),
      {
        enabled: !!token && !!partnerId && cartId !== null && cartId !== " ",
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );

  const useGetSpiceLevel = ({ restId }) =>
    useQuery(["getAllSpiceLevel", restId], () => getAllSpiceLevel({ restId }), {
      enabled: !!token && !!restId,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetFoodCategory = (id) =>
    useQuery(["getAllFoodCategory", id], () => getAllFoodCategory(id), {
      enabled: !!token && !!id,
      select: (data) => data?.data,
      onSuccess: (res) => { },
    });

  const useGetFoodMenuByCategory = ({
    restId,
    menuId,
    query,
    foodTypeId,
    spiceLevelId,
  }) =>
    useQuery(
      [
        "getAllFoodMenuByCategory",
        restId,
        menuId,
        query,
        foodTypeId,
        spiceLevelId,
      ],
      () =>
        getAllFoodMenuByCategory({
          restId,
          menuId,
          query,
          foodTypeId,
          spiceLevelId,
        }),
      {
        enabled: !!restId && !!menuId,
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );

  const useGetFoodItemsByQuery = ({
    restId,
    menuId,
    query,
    foodTypeId,
    spiceLevelId,
  }) =>
    useQuery(
      [
        "getItemByQuery",
        restId,
        query,
        menuId,
        foodTypeId,
        spiceLevelId,
      ],
      () =>
        getItemByQuery({
          restId,
          menuId,
          query,
          foodTypeId,
          spiceLevelId,
        }),
      {
        enabled: !!restId,
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );
  const useGetCartItem = ({ cartID, partnerId }) =>
    useQuery(
      ["getAllCartByID", cartID, partnerId],
      () => getAllCartItem(cartID, partnerId),
      {
        enabled: !!cartID && !!partnerId,
        select: (data) => data?.data,
        onSuccess: (res) => {
          setCartList(res?.result?.cartItems);
          setCartListDiff(res?.result?.cartItems);
        },
        cacheTime: 0,
      }
    );
  const useGetOrderDetails = () =>
    useQuery(
      ["getAllOrderByID", getOrderId()],
      () => getAllOrderItem(getOrderId()),
      {
        enabled: !!getOrderId(),
        select: (data) => data?.data,
        onSuccess: (res) => { },
      }
    );

  const useGetGenieExp = ({ restId }) =>
    useQuery(
      ["getAllGenieExp", genieCheck],
      () =>
        getAllGenieExp({
          restId: restId,
          allergiesIds: allergicData,
          // allergiesIds: allergicPreferences,
          foodTypeId: dietaryPreferences?.id,
          spiceLevelId: spicyPreferences?.id,
        }),
      {
        enabled: true,
        cacheTime: 0,
        select: (data) => data?.data,
        onSuccess: (res) => {
          setGenieRecommendation(res?.result);
          setGenieCheck(false);
        },
      }
    );

  // post
  const { mutate: mutateAddCartMenu, isLoading: addCartMenuIsLoading } =
    useMutation(AddCartMenu, {
      onSuccess: async (res) => {
        // toast.success("Menu added to cart Successfully");
        if (getCartId() == null) {
          saveCartID(res?.data?.result?._id);
        } else {
          queryClient.refetchQueries("getAllCartByID");
          queryClient.refetchQueries("getAllCartCount");
        }
        // setDrawerOpen(false);
        // setCount(0);

        setLoadingItems(prev => prev.filter(itemId => 
          !res?.data?.result?.cartItems.some(cartItem => cartItem?.itemId === itemId)
        ))

        setOrderModel(!OrderModel);
        setCartResponseLoading(false)
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const OnAddCart = (data) => {
    setCartResponseLoading(true)
    let transformedData = {
      partnerId: data?.restId || data?.partnerId,
      itemId: data?.itemId,
      quantity: data?.count,
      tableSequence: data?.tableSequence,
      customization: data?.customization ? data.customization : [],
    };

    if (getCartId()) {
      transformedData.cartId = getCartId();
    }

    setLoadingItems(prevItems => [...prevItems, data?.itemId])
    mutateAddCartMenu(transformedData);
  };

  const { mutate: mutateCartItemDetails } = useMutation(getCartItemDetails, {
    onSuccess: async (res) => { },
    onError: (error) => {
      console.log(error);
    },
  });

  const onCartItemDetails = (data) => {
    mutateCartItemDetails(data)
  }

  const { mutate: mutateAddNotes, isLoading: addNotesIsLoading } = useMutation(
    noteAdd,
    {
      onSuccess: async (res) => {
        setDrawerNoteOpen(false);
        queryClient.refetchQueries("getAllCartByID");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const OnNoteAdd = (data) => {
    let transformedData = {
      partnerId: data?.restId,
      cartId: getCartId(),
      notes: noteValue,
    };

    mutateAddNotes(transformedData);
  };

  const { mutate: mutatePersonalizeEvent } = useMutation(getPersonalizeEvent, {
    onSuccess: async (res) => { },
    onError: (error) => {
      console.log(error);
    },
  });

  const PersonalizeEvent = (data) => {
    mutatePersonalizeEvent(data);
  };

  const { mutate: mutatePlaceOrder, isLoading: placeOrderIsLoading } =
    useMutation(PlaceOrder, {
      onSuccess: async (res) => {
        queryClient.refetchQueries("getAllCartByID");
        saveOrderID(res?.data?.result?._id);
        setOrderId(res?.data?.result?._id);

        setOrderModel(true);
        RemoveCartID();
        PersonalizeEvent({
          itemIds: res.data.result.orderDetails?.map((item) => {
            return item?.itemId;
          }),
          evenType: "PURCHASE",
        });

        navigate(-1);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const placeOrder = (id) => {
    let transformedData = {
      partnerId: id,
      cartId: getCartId(),
    };

    mutatePlaceOrder(transformedData);
  };

  const { mutate: mutatePayment } = useMutation(Payment, {
    onSuccess: async (res) => {
      setOrderResult(res?.data.result);
      RemoveOrderID();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const addPayment = () => {
    let transformedData = {
      paymentMode: "cash",
      orderId: getOrderId(),
    };

    mutatePayment(transformedData);
  };

  const { mutate: mutateRating } = useMutation(Rating, {
    onSuccess: async (res) => {
      setRatingId(res?.data?.result?._id);
      // toast.success("Order Placed Successfully");
      // queryClient.refetchQueries("getAllCartByID");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const addRating = (data) => {
    let transformedData = {
      ...data,
      orderId: OrderId,
    };

    if (ratingId) {
      transformedData.ratingId = ratingId;
    }

    mutateRating(transformedData);
  };

  return {
    // DinnerDetails
    useGetDinerDetails,
    // Restaurant Info
    useGetRestaurantInfo,
    //food type
    useGetFoodType,
    // Spice Level
    useGetSpiceLevel,
    //Menu Category
    useGetFoodCategory,
    // menu Category with id
    useGetFoodMenuByCategory,
    // add menu to cart
    OnAddCart,
    // get Cart Item
    decrement,
    increment,
    count,
    setCount,
    useGetCartItem,
    toggleDrawer,
    isDrawerOpenValue,
    setDrawerOpenValue,
    isDrawerOpen,
    setDrawerOpen,
    isDrawerNoteOpen,
    toggleNoteDrawer,
    noteValue,
    setNoteValue,
    setDrawerNoteOpen,
    useGetCartCount,
    handleNoteChange,
    cartListDiff,

    // add notes
    OnNoteAdd,
    cartList,
    setCartList,
    addCartMenuIsLoading,
    addNotesIsLoading,

    // place order
    placeOrder,
    placeOrderIsLoading,
    OrderModel,
    setOrderModel,

    useGetOrderDetails,

    // addPayment
    addPayment,
    orderResult,
    // rating
    addRating,
    hover2,
    setHover2,
    rating2,
    setRating2,
    hover,
    setHover,
    rating,
    setRating,
    suggestion,
    setSuggestion,

    // personalize

    PersonalizeEvent,

    // genie experience
    pageCount,
    setPageCount,
    countDiner,
    setCountDiner,
    dietaryPreferences,
    setDietaryPreferences,
    allergicPreferences,
    setAllergicPreferences,
    spicyPreferences,
    setSpicyPreferences,
    // dietaryPreferencesText,
    // setDietaryPreferencesText,
    allergicData,
    setAllergicData,
    useGetGenieExp,
    genieCheck,
    setGenieCheck,
    decrementKidsCount,
    incrementKidsCount,
    decrementAdultCount,
    incrementAdultCount,
    handleCheckboxChange,
    genieRecommendation,
    setGenieRecommendation,
    loadingItems,

    cartResponseLoading,
    setCartResponseLoading,
    useGetFoodItemsByQuery,
    onCartItemDetails
  };
}

export function ProvideDiner({ children }) {
  const diner = useProvideDiner();
  return (
    <DinerContext.Provider value={diner}>{children}</DinerContext.Provider>
  );
}
