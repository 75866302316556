import dayjs  from "dayjs";
import React from "react";
import MultiDatePicker from "react-multi-date-picker";


const DatePicker = ({ value, onChange, placeholder }) => {
   const disabledAfterToday = (date) => {
    return date.isAfter(dayjs(), "day");
  };
  return (
    <MultiDatePicker
      inputClass="h-[40px] w-[236px] border-2 rounded pl-5"
      value={value}
      onChange={onChange}
      format="YYYY-MM-DD"
      placeholder={placeholder}
      clearable
      range
      dateSeparator=" - "
      portal
      //   animations={[transition()]}
      // disabled={disabledAfterToday}  
    />
  );
};

export default DatePicker;
