import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const StyledButton = styled(Button)(() => ({
  borderRadius: "5px",
  padding: "14px, 25px, 14px, 25px",
  fontWeight: "400",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#ED1703",
    },
    secondary: {
      main: "#ffffff",
    },
    grey: {
      main: "#777777",
    },
    black: {
      main:"#000000"
    }
   
  },
});

function BasicButton({
  children,
  variant = "contained",
  color,
  type,
  onClick,
  sx,
  form,
  disabled,
  ...rest
}) {
  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        {...rest}
        type={type}
        variant={variant}
        color={color}
        onClick={onClick}
        sx={sx}
        form={form}
        disabled={disabled}
      >
        {children}
      </StyledButton>
    </ThemeProvider>
  );
}
export default BasicButton;
