import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";
import TimePickerComponent from "../../../components/TimePicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

function TimingsDetails({ control, handleAppendSchedule, timingList, watch }) {
  return (
    <Grid item container>
      <Grid item sx={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">Timings Details</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">Choose your Timings</h3>
      </Grid>

      <Grid
        item
        sx={{
          width: "100%",
          marginX: "1.25rem",
          overflowY: "scroll",
          marginTop: "10px",
          height: "calc(100vh - 250px)",
        }}
      >
        <Grid container direction={"column"} item spacing={2} xs={11}>
          <div
            className="flex flex-col  gap- mx-5 mt-5 
          "
          >
            {timingList?.map((days, dayIndex) => {
              const isClosed = watch(`Timing[${dayIndex}].isClosed`);
              return (
                <div className="flex gap-5">
                  <div className="mt-3">
                    <label className="font-quicksand font-[700] block mb-2 text-[14px] text-[#252525]">
                      {days.day}
                    </label>
                    <Controller
                      control={control}
                      name={`Timing[${dayIndex}].isClosed`}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              inputProps={{
                                "aria-label": "controlled",
                              }}
                            />
                          }
                          label="closed"
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col  my-2 ">
                    {days.schedule.map((timeSlot, slotIndex) => {
                      console.log(days?.isClosed);
                      return (
                        <div key={slotIndex} className="flex ml-auto  ">
                          <div>
                            <Controller
                              name={`Timing[${dayIndex}].schedule[${slotIndex}].fromTime`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TimePickerComponent
                                  {...field}
                                  label={`Opens at`}
                                  error={!!error}
                                  disabled={isClosed}
                                  helperText={error ? error.message : null}
                                  onChange={(time) => {
                                    const formattedTime =
                                      dayjs(time).format("hh:mm a");
                                    field.onChange(formattedTime);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="ml-2">
                            <Controller
                              name={`Timing[${dayIndex}].schedule[${slotIndex}].toTime`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TimePickerComponent
                                  {...field}
                                  label={`Closes at`}
                                  error={!!error}
                                  disabled={isClosed}
                                  helperText={error ? error.message : null}
                                  onChange={(time) => {
                                    const formattedTime =
                                      dayjs(time).format("hh:mm a");
                                    field.onChange(formattedTime);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className={`flex  mt-7 mx-5 ${isClosed ? "invisible" : ""}`}
                  >
                    <AddCircleOutlineIcon
                      sx={{ marginTop: "25px" }}
                      onClick={() => handleAppendSchedule(dayIndex)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TimingsDetails;
