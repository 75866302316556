import { hooks } from "./utils/index";
function ContextContainer({ children }) {
  return (
    <hooks.ProvideAuth>
      <hooks.ProvideSuperAdmin>
        <hooks.ProvideRestaurant>
          <hooks.ProvideDiner>{children}</hooks.ProvideDiner>
        </hooks.ProvideRestaurant>
      </hooks.ProvideSuperAdmin>
    </hooks.ProvideAuth>
  );
}

export default ContextContainer;
