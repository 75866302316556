import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import Logo from "../../../assets/images/FrameTrimmed2.png";
import BasicButton from "../../../components/Button";
import PeopleCount from "./PeopleCount";
import DietaryPreferences from "./DietaryPreferences";
import Allergic from "./Allergic";
import SpicePreferences from "./SpicePreferences";
import { useDiner } from "../../../utils/hooks";
import { useNavigate, useParams } from "react-router";

function GenieExperience() {
  const navigate = useNavigate();

  const restaurantId = useParams();
  const {
    pageCount,
    setPageCount,
    countDiner,
    dietaryPreferences,
    setDietaryPreferences,
    allergicPreferences,
    setAllergicPreferences,
    spicyPreferences,
    setSpicyPreferences,
    dietaryPreferencesText,
    setDietaryPreferencesText,
    useGetSpiceLevel,
    useGetGenieExp,
    genieCheck,
    setGenieCheck,
    decrementKidsCount,
    incrementKidsCount,
    decrementAdultCount,
    incrementAdultCount,
  } = useDiner();

  const { data: spiceLevel } = useGetSpiceLevel({ restId: restaurantId?.id });

  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className=" p-3   "
        >
          <h1 className="text-quicksand  font-[700] text-[16px]">
            The Genie experience
          </h1>
          <CloseIcon onClick={() => navigate(-1)} />
        </Grid>

        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className=" p-3   "
        >
          <h1 className="text-quicksand  font-[500] text-[17px] mb-3">
            Question {pageCount}/4
          </h1>
          <progress id="file" value={pageCount} max={4} className="w-full" />
          {/* progress with icon  */}
          {/* <Box
            position="relative"
            display="flex"
            alignItems="center"
            className="w-full"
          >
            <progress id="file" value={pageCount} max={4} className="w-full" />
            {/* {pageCount < 4 && (
              <Box
                position="absolute"
                left={`${progressPercentage}%`}
                top={-8}
                transform="translateX(-50%)"
                sx={{
                  transition: "1s ease-in-out ",
                  bgcolor: "white",
                  borderRadius: "50%",
                  boxShadow: 4,
                }}
              >
                <CheckCircleIcon sx={{ color: "white" }} />
              </Box>
            )} */}
          {/* </Box> */}
        </Grid>

        {pageCount === 1 && (
          <PeopleCount
            decrementAdultCount={decrementAdultCount}
            countDiner={countDiner}
            incrementAdultCount={incrementAdultCount}
            decrementKidsCount={decrementKidsCount}
            incrementKidsCount={incrementKidsCount}
          />
        )}
        {pageCount === 2 && (
          <DietaryPreferences
            dietaryPreferences={dietaryPreferences}
            setDietaryPreferences={setDietaryPreferences}
            dietaryPreferencesText={dietaryPreferencesText}
            setDietaryPreferencesText={setDietaryPreferencesText}
          />
        )}
        {pageCount === 3 && (
          <Allergic
            setAllergicPreferences={setAllergicPreferences}
            allergicPreferences={allergicPreferences}
          />
        )}
        {pageCount === 4 && (
          <SpicePreferences
            setSpicyPreferences={setSpicyPreferences}
            spicyPreferences={spicyPreferences}
            spiceLevel={spiceLevel}
          />
        )}

        <Grid
          container
          item
          xs={12}
          spacing={1}
          className="px-5 absolute bottom-[20px]  bg-white   "
        >
          {pageCount === 1 && (
            <Grid item xs={12}>
              <BasicButton
                color="primary"
                sx={{ textTransform: "none", width: "100%" }}
                onClick={() => setPageCount(pageCount + 1)}
              >
                Next
              </BasicButton>
            </Grid>
          )}
          {pageCount > 1 && pageCount < 4 && (
            <>
              <Grid item xs={6}>
                <BasicButton
                  color="primary"
                  variant="outlined"
                  sx={{ textTransform: "none", width: "100%" }}
                  onClick={() => setPageCount(pageCount - 1)}
                >
                  Back
                </BasicButton>
              </Grid>
              <Grid item xs={6}>
                <BasicButton
                  color="primary"
                  sx={{ textTransform: "none", width: "100%" }}
                  onClick={() => setPageCount(pageCount + 1)}
                >
                  Next
                </BasicButton>
              </Grid>
            </>
          )}
          {pageCount === 4 && (
            <>
              <Grid item xs={6}>
                <BasicButton
                  color="primary"
                  variant="outlined"
                  sx={{ textTransform: "none", width: "100%" }}
                  onClick={() => setPageCount(pageCount - 1)}
                >
                  Back
                </BasicButton>
              </Grid>
              <Grid item xs={6}>
                <BasicButton
                  color="primary"
                  sx={{ textTransform: "none", width: "100%" }}
                  onClick={() => (
                    setGenieCheck(true), navigate("Recommendation")
                  )}
                >
                  Finish{" "}
                </BasicButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default GenieExperience;
