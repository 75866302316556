import { Box, Modal } from "@mui/material";
import React from "react";

export function BasicModal({ open, close, children, fullScreen, maxWidth = "46rem" }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            backdropFilter: 'blur(3px)',
          },
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: maxWidth,
          bgcolor: '#ffffff',
          borderRadius: '8px',
          boxShadow: 24,
          p: "1.25rem 1.5rem"
        }}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
