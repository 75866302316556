import { Grid } from '@mui/material'
import React from 'react'
import ButtonWithIcon from '../../../../components/ButtonWithIcon'
import BasicButton from '../../../../components/Button'
import ReusableIOSSwitch from "../../../../components/switch/IOSSwitch";
import plus from '../../../../assets/Icons/plus.svg'
import cancel from "../../../../assets/Icons/cancel.svg";
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import closeIcon from "../../../../assets/Icons/close.svg"
import { CustomTextInput } from '../../../../components/InputBox/CustomInput'

const CustomizationModal = ({ close, handleSubmit, data, index }) => {
    const { control, getValues, setError, clearErrors } = useForm({
        defaultValues: data || {
            title: "",
            isRequired: false,
            items: [{ itemName: "", price: "" }],
        }
    })

    const { fields: itemsList, remove, append } = useFieldArray({
        control,
        name: "items",
    });

    const validateField = (value, fieldName, index = null) => {
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        if (trimmedValue === "" || (fieldName === 'price' && (isNaN(trimmedValue) || Number(trimmedValue) < 0))) {
            setError(index !== null ? `items.${index}.${fieldName}` : fieldName, {
                type: "manual",
                message: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} cannot be empty`
            });
            return false;
        }
        clearErrors(index !== null ? `items.${index}.${fieldName}` : fieldName);
        return true;
    };

    const handleSave = () => {
        const { title, isRequired, items } = getValues();
        
        let isValid = validateField(title, "title");
        
        items.forEach((item, index) => {
            isValid = validateField(item.itemName, "itemName", index) && isValid;
            isValid = validateField(item.price, "price", index) && isValid;
        });

        if (isValid) {
            if (data && index >= 0) {
                handleSubmit({ title, isRequired, items }, index);
            } else {
                handleSubmit({ title, isRequired, items });
            }
        }
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <p className="font-semibold">{data ? "Edit" : "Add"} Customization</p>
                <img className="cursor-pointer" onClick={close} src={closeIcon} alt="" />
            </div>
            <div className="w-100 flex flex-col gap-[18px] my-[18px]">
                <div className="flex gap-[10px]">
                    <Controller
                        name="isRequired"
                        control={control}
                        render={({ field }) => <ReusableIOSSwitch checked={field.value} onChange={field.onChange} />}
                    />
                    Required
                </div>

                <div className="flex flex-col gap-[18px]">
                    <Grid container xs={12} sx={{ display: "flex" }}>
                        <Grid xs={4}>
                            <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">CUSTOM LABEL TITLE<span className="text-[#b80e0c]">*</span></p>
                        </Grid>
                        <Grid xs={4.65}>
                            <Controller
                                name="title"
                                control={control}
                                rules={{
                                    required: "Label is required",
                                    validate: (value) => validateField(value, "title")
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <CustomTextInput
                                        {...field}
                                        variant="outlined"
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        onChange={(e) => {
                                            field.onChange(e.target.value);
                                            validateField(e.target.value, "title");
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid xs={12} sx={{ display: "flex" }}>
                        <Grid xs={4}>
                            <p className="text-[#757373] basis-40 font-semibold mt-[8.8px]">ADD ITEM<span className="text-[#b80e0c]">*</span></p>
                        </Grid>
                        <Grid xs={8} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                            {itemsList.map((field, index) => (
                                <Grid xs={12} container key={field.id}>
                                    <Grid xs={7}>
                                        <Controller
                                            name={`items.${index}.itemName`}
                                            control={control}
                                            rules={{
                                                required: "Item name is required",
                                                validate: (value) => validateField(value, "itemName", index)
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <CustomTextInput
                                                    {...field}
                                                    variant="outlined"
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                        validateField(e.target.value, "itemName", index);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={5} sx={{ paddingLeft: "10px" }}>
                                        <div className="flex gap-1">
                                            <Controller
                                                name={`items.${index}.price`}
                                                control={control}
                                                rules={{
                                                    required: "Price is required",
                                                    validate: (value) => validateField(value, "price", index)
                                                }}
                                                render={({ field, fieldState: { error } }) => (
                                                    <CustomTextInput
                                                        {...field}
                                                        type="number"
                                                        variant="outlined"
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                        preContent={
                                                            <h1 className=" text-sm text-[#515151] bg-white">
                                                                AED
                                                            </h1>
                                                        }
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value);
                                                            validateField(e.target.value, "price", index);
                                                        }}
                                                        sx={{ padding: "0 20px" }}
                                                    />
                                                )}
                                            />
                                            <img
                                                className="cursor-pointer self-start mt-[9.3px]"
                                                src={cancel}
                                                alt="delete"
                                                onClick={() => {
                                                    if (itemsList.length > 1)
                                                        remove(index)
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </div>

                <div>
                    <Grid container xs={12} >
                        <Grid xs={8} marginLeft="auto">
                            <ButtonWithIcon icon={plus} text="Add new Item" onClick={() => append({ itemName: '', price: '' })} />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Grid container xs={12}>
                <Grid xs={6} sx={{ paddingRight: "10px" }}>
                    <BasicButton
                        variant="outlined"
                        color="genieRed"
                        sx={{
                            textTransform: "none",
                            display: "flex",
                            width: "100%"
                        }}
                        onClick={close}
                    >
                        Cancel
                    </BasicButton>
                </Grid>
                <Grid xs={6} sx={{ paddingLeft: "10px" }}>
                    <BasicButton
                        color="genieRed"
                        type="submit"
                        sx={{
                            textTransform: "none",
                            display: "flex",
                            color: "white",
                            width: "100%"
                        }}
                        onClick={handleSave}
                    >
                        Save Item
                    </BasicButton>
                </Grid>
            </Grid>
        </>
    )
}

export default CustomizationModal