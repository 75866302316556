import React from "react";
import { TextInput } from "../../../components/InputBox";
import { useDiner } from "../../../utils/hooks";
import { useParams } from "react-router";

function AddNote() {
  const {
    handleNoteChange,
    noteValue,
    OnNoteAdd,
    addNotesIsLoading,
    setDrawerNoteOpen,
  } = useDiner();

  /**
   * Destructure the id parameter from the URL using the useParams hook from React Router.
   * @returns The id parameter extracted from the URL used For Restaurant ID.
   */
  const { id } = useParams();

  return (
    <div className="flex flex-col p-5">
      <button
        className="flex justify-end text-genieRed font quicksand font-[600] text-[14px] disabled:opacity-60"
        onClick={() => (setDrawerNoteOpen(false), OnNoteAdd({ restId: id }))}
        disabled={addNotesIsLoading}
      >
        Done
      </button>
      <TextInput
        onChange={handleNoteChange}
        value={noteValue}
        variant="outlined"
        label="Add a note"
        multiline
        rows={3}
      />
    </div>
  );
}

export default AddNote;
