import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDiner } from "../../../utils/hooks";
import Logo from "../../../assets/images/Frametrimed.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarIcon from "@mui/icons-material/Star";
import { TextInput } from "../../../components/InputBox";
import plate from "../../../assets/images/plate.jpg";
import { useDebounce } from "../../../useDebaunce";
import ThumbDownAltRoundedIcon from "@mui/icons-material/ThumbDownAltRounded";
import ThumbUpOffAltRoundedIcon from "@mui/icons-material/ThumbUpOffAltRounded";

function RestaurantPaymentSuccess() {
  const [likedItems, setLikedItems] = useState([]);
  const [dislikedItems, setDislikedItems] = useState([]);
  const [animateThumbUp, setAnimateThumbUp] = useState(null);
  const [animateThumbDown, setAnimateThumbDown] = useState(null);

  /**
   * Handles the action of liking an item by adding it to the liked items list,
   * updating the ratings, and setting animation for the thumbs up icon.
   * @param {{string}} itemId - The unique identifier of the item being liked.
   * @param {{string}} itemName - The name of the item being liked.
   */
  const handleLike = (itemId, itemName) => {
    if (!likedItems.includes(itemId)) {
      addRating({
        itemsLiked: [{ itemId, item_name: itemName, liked: true }],
      });
      setLikedItems([...likedItems, itemId]);
      if (dislikedItems.includes(itemId)) {
        setDislikedItems(dislikedItems.filter((id) => id !== itemId));
      }
    }

    // Trigger animation
    setAnimateThumbUp(itemId);
    setTimeout(() => {
      setAnimateThumbUp(null);
    }, 300); // Match this duration with your CSS animation duration
  };

  /**
   * Handles the dislike action for an item by updating the disliked items list, liked items list,
   * and triggering an animation.
   * @param {{string}} itemId - The unique identifier of the item being disliked.
   * @param {{string}} itemName - The name of the item being disliked.
   */

  const handleDislike = (itemId, itemName) => {
    if (!dislikedItems.includes(itemId)) {
      addRating({
        itemsLiked: [{ itemId, item_name: itemName, disLiked: true }],
      });
      setDislikedItems([...dislikedItems, itemId]);
      if (likedItems.includes(itemId)) {
        setLikedItems(likedItems.filter((id) => id !== itemId));
      }
    }

    // Trigger animation
    setAnimateThumbDown(itemId);
    setTimeout(() => {
      setAnimateThumbDown(null);
    }, 300); // Match this duration with your CSS animation duration
  };

  const {
    orderResult,
    addRating,
    hover2,
    setHover2,
    rating2,
    setRating2,
    hover,
    setHover,
    rating,
    setRating,
    suggestion,
    setSuggestion,
  } = useDiner();

  /**
   * Debounces the given value using a delay of 1500 milliseconds.
   * @param {{any}} suggestion - The value to debounce.
   * @returns The debounced value after the delay.
   */
  var debauchedValue = useDebounce(suggestion, 1500);

  /**
   * useEffect hook that triggers when the debauchedValue changes.
   * If the suggestion is not an empty string, it adds a rating with the debauchedValue as feedback.
   * @returns None
   */
  useEffect(() => {
    if (suggestion !== "") {
      addRating({ feedback: debauchedValue });
    }
  }, [debauchedValue]);

  return (
    <>
      <Grid container direction="column" spacing={1} gap={1}>
        <Grid
          item
          sx={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="flex-end "
          alignItems="center"
          className=" p-3  "
        >
          {/* <CloseIcon onClick={() => navigate(-2 )} /> */}
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"124px"}
          direction={"column"}
          justifyContent="center "
          alignItems="center"
          gap={2}
        >
          <CheckCircleIcon
            sx={{ width: "40px", height: "40px", color: "#4BB543" }}
          />
          <h1 className="font-[quicksand] font-[700] text-[16px] text-[#252525]">
            Payment Successful
          </h1>
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="center "
          alignItems="center"
          className=" p-3  bg-[#F5F5F5] "
        >
          <h1 className="font-[600] text-[14px] ">
            You paid
            <span className="font-[800] px-1">
              AED {orderResult?.totalOrderValue}
            </span>
            for {orderResult?.orderDetails?.length}{" "}
            {orderResult?.orderDetails?.length === 1 ? "item" : "items"}
          </h1>
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          alignItems="center"
          className=" p-3  "
          spacing={1}
        >
          <Grid item xs={12}>
            <h1 className="font-[600] text-[14px] ml-[5%] ">
              How was the food?
            </h1>
          </Grid>

          <Grid item xs={12}>
            <div className="ml-[5%]">
              {[...Array(5)].map((star, index) => {
                const currentRating = index + 1;
                return (
                  <label key={index}>
                    <input
                      type="radio"
                      name="rating"
                      value={currentRating}
                      className="hidden"
                      onClick={() => (
                        setRating(currentRating),
                        addRating({ food: currentRating })
                      )}
                    />
                    <StarIcon
                      sx={{ fontSize: "36px", borderRadius: "40px" }}
                      className={` ${
                        currentRating <= (hover || rating)
                          ? "text-[#E0E001]"
                          : "text-[grey]"
                      }`}
                      onMouseEnter={() => setHover(currentRating)}
                      onMouseLeave={() => setHover(null)}
                    />
                  </label>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          alignItems="center"
          className=" p-3  "
          spacing={1}
        >
          <Grid item xs={12}>
            <h1 className="font-[600] text-[14px] ml-[5%] ">
              How was the overall experience?
            </h1>
          </Grid>

          <Grid item xs={12}>
            <div className="ml-[5%]">
              {[...Array(5)].map((star, index) => {
                const currentRating = index + 1;
                return (
                  <label key={index}>
                    <input
                      type="radio"
                      name="rating2"
                      value={currentRating}
                      className="hidden"
                      onClick={() => (
                        setRating2(currentRating),
                        addRating({ experience: currentRating })
                      )}
                    />
                    <StarIcon
                      sx={{ fontSize: "36px", borderRadius: "40px" }}
                      className={` ${
                        currentRating <= (hover2 || rating2)
                          ? "text-[#E0E001]"
                          : "text-[grey]"
                      }`}
                      onMouseEnter={() => setHover2(currentRating)}
                      onMouseLeave={() => setHover2(null)}
                    />
                  </label>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sx={{ marginLeft: "5%", marginRight: "5%" }}>
            <TextInput
              variant="outlined"
              label="Suggestion(optional)"
              multiline
              rows={2}
              onChange={(e) => setSuggestion(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent={"space-around"}
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <h1 className="font-[600] text-[14px] ml-[5%] ">
              Which plates did you like the least?
            </h1>
          </Grid>
          <Grid
            container
            item
            sx={{ marginLeft: "5%", marginRight: "5%" }}
            gap={1}
          >
            {orderResult?.orderDetails?.map((item) => (
              <Grid items xs={5.5} key={item?.itemId}>
                <div className="bg-white rounded border p-3">
                  <img src={plate} alt="Menu" className="rounded" />
                  <div className="">
                    <h1 className="p-2 truncate font-quickSand font-[700] text-[14px]">
                      {item?.item_name}
                    </h1>
                    <p className="flex h-10 justify-around items-center">
                      <ThumbUpOffAltRoundedIcon
                        className={`${
                          likedItems.includes(item?.itemId)
                            ? "text-genieRed hover:text-genieRed"
                            : "hover:text-genieRed"
                        } ${
                          animateThumbUp === item?.itemId
                            ? "thumbUpAnimation"
                            : ""
                        }`}
                        onClick={() =>
                          handleLike(item?.itemId, item?.item_name)
                        }
                      />
                      <ThumbDownAltRoundedIcon
                        className={`${
                          dislikedItems.includes(item?.itemId)
                            ? "text-genieRed hover:text-genieRed"
                            : "hover:text-genieRed"
                        } ${
                          animateThumbDown === item?.itemId
                            ? "thumbDownAnimation"
                            : ""
                        }`}
                        onClick={() =>
                          handleDislike(item?.itemId, item?.item_name)
                        }
                      />
                    </p>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default RestaurantPaymentSuccess;
