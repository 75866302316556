import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { TextInput } from "../../../components/InputBox";
import { SearchableDropDown } from "../../../components/SearchableDropdown";
import BasicButton from "../../../components/Button";
import DragAndDropCoverPic from "../../../components/Dropzone";
import HeaderWrapper from "../../../components/headerwrapper";
import { useRestaurant } from "../../../utils/hooks";
import { useNavigate, useParams } from "react-router";
import AddSearchableDropdown from "./AddSearchableDropdown";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import InlineLoader from "../../../components/loader/commonLoader";
import WatermarkImage from "./Watermark";

function AddMenu() {
  const navigate = useNavigate();
  const menuId = useParams();

  const {
    useGetTabList,
    OnAddMenu,
    useGetFoodType,
    useGetSpiceLevel,
    headerText,
    setHeaderText,
    listArray,
    setListArray,
    openModal,
    setOpenModal,
    modelText,
    setModelText,
    AddCategory,
    AddType,
    HandleDeleteCategoryList,
    HandleDeleteType,
    AddSpice,
    HandleDeleteSpice,
    useGetMenuById,
    setAddList,
    OnEditMenu,
    useAiMenuDetails,
    OnEditMenuIngredients,
    setGptCheck,
    mealTimingOptions,
    AiPhoto,
    setAiPhoto,
    aiPhotoGenerationIsLoading,
    onAIPhotoGeneration,
  } = useRestaurant();

  const { data: categoryList, isLoading: categoryListIsLoading } =
    useGetTabList();
  const { data: menuById } = useGetMenuById(menuId?.id);
  const { data: foodType, isLoading: foodTypeLoading } = useGetFoodType();
  const { data: spiceLevel, isLoading: spiceLevelLoading } = useGetSpiceLevel();
  // const { data: allergies, isLoading: allergiesLoading } = useGetAllergies();

  /**
   * Initializes form control functions for handling form submission, control over form fields,
   * resetting form values, watching form field values, and setting form field values.
   */
  const { handleSubmit, control, reset, watch, setValue } = useForm({
    mode: "all",
    defaultValues: {
      category: {
        label: "",
        value: "",
      },
      image: {
        url: "",
        AiGenerated: false,
      },
      item_name: "",
      description: "",
      oPrice: "",
      mealTiming: [],
      ingredients: [],
      FPrice: "",
      dPrice: "",
      min: "",
      max: "",
      type: {
        label: "",
        value: "",
      },
      Cuisine: {
        label: "",
        value: "",
      },
    },
  });

  /**
   * Retrieves the value of the "OPrice" and "Discount" fields from the form data.
   * @returns The original price and discount price values from the form data.
   */
  const originalPrice = watch("oPrice");
  const discountPrice = watch("dPrice");
  const menuNameWatch = watch("item_name");

  const {
    data: dishIngredientsListGpt,
    isLoading: dishIngredientsListGptLoading,
  } = useAiMenuDetails(menuNameWatch);

  /**
   * useEffect hook that resets form values based on menuById data when menuId.id is truthy.
   * @param {Function} reset - Function to reset form values.
   * @param {Object} menuById - The menu data used to populate form values.
   * @returns None
   */
  useEffect(() => {
    if (menuId?.id) {
      reset({
        _id: menuById?.result?._id,
        image: {
          url: menuById?.result?.items?.image?.url,
          AiGenerated: menuById?.result?.items?.image?.AiGenerated,
        },
        item_name: menuById?.result?.items?.item_name,
        description: menuById?.result?.items?.description,
        mealTiming: menuById?.result?.mealTiming?.map((item) => ({
          value: item,
          label: item,
        })),
        oPrice: menuById?.result?.items?.oPrice,
        dPrice: menuById?.result?.items?.dPrice,
        FPrice:
          menuById?.result?.items?.oPrice - menuById?.result?.items?.dPrice,
        min: menuById?.result?.items?.portion?.min,
        max: menuById?.result?.items?.portion?.max,

        category: {
          value: menuById?.result?.foodCategory?._id,
          label: menuById?.result?.foodCategory?.food_category,
        },
        type: {
          value: menuById?.result?.items?.foodType._id,
          label: menuById?.result?.items?.foodType.food_type,
        },
        spiceLevelId: {
          value: menuById?.result?.items?.spiceLevel._id,
          label: menuById?.result?.items?.spiceLevel.spice_level,
        },
        ingredients: menuById?.result?.items?.ingredients,
      });
    }
  }, [menuById]);

  /**
   * Handles form submission by checking if a menu ID exists. If it does, it calls the OnEditMenu function
   * with the form data, otherwise it calls the OnAddMenu function with the form data.
   */
  const onSubmit = (data) => {
    if (menuId?.id) {
      OnEditMenu(data);
    } else {
      OnAddMenu(data, dishIngredientsListGpt?.result);
    }
    navigate("/restaurant/menu");
  };

  /**
   * Handles the category list by setting the open modal state to true and updating the list array,
   * header text, and model text based on the category list result.
   * If the category list is not loading, it opens the modal and updates the list array with
   * the mapped values of food categories and their corresponding IDs.
   */
  const handleCategory = () => {
    if (!categoryListIsLoading) {
      setOpenModal(true);
      setAiPhoto([]);
      setListArray(
        categoryList?.result?.map((item) => ({
          label: item?.food_category,
          value: item?._id,
        }))
      );
      setHeaderText("category");
      setModelText("category");
    }
  };
  /**
   * Handles the spiciness level by setting the open modal flag to true,
   * populating the list array with spice level data, and setting the header and model text.
   * @returns None
   */
  const handleSpicinessLevel = () => {
    if (!spiceLevelLoading) {
      setOpenModal(true);
      setListArray(
        spiceLevel?.result?.map((item) => ({
          label: item?.spice_level,
          value: item?._id,
        }))
      );
      setHeaderText("spice level");
      setModelText("spice");
    }
  };
  /**
   * Handles the display of allergies by setting the necessary states to show a modal
   * with a list of allergies.
   * If allergies are not loading, it sets the open modal state to true, sets the list
   * array with the mapped values of allergies, sets the header text to "allergy ingredient",
   * and sets the model text to "allergy".
   */
  const handleAllergies = () => {
    setOpenModal(true);
    {
      dishIngredientsListGpt?.result && dishIngredientsListGpt
        ? setListArray(
            dishIngredientsListGpt?.result?.ingredients?.map((item) => ({
              label: item,
              value: item,
            }))
          )
        : setListArray(
            menuById?.result?.items?.ingredients?.map((item, index) => ({
              label: item,
              value: index,
            }))
          );
    }

    setHeaderText("Ingredients used");
    setModelText("allergy");
  };
  /**
   * Handles the type of food by setting the open modal flag to true,
   * populating the list array with food types, setting the header text
   * to "type of food", and setting the model text to "type".
   * @returns None
   */
  const handleType = () => {
    if (!foodTypeLoading) {
      setOpenModal(true);
      setListArray(
        foodType?.result?.map((item) => ({
          label: item?.food_type,
          value: item?._id,
        }))
      );
      setHeaderText("type of food");
      setModelText("type");
    }
  };
  /**
   * Handles the form submission based on the value of modelText.
   * If modelText is "category", calls AddCategory function.
   * If modelText is "type", calls AddType function.
   * If modelText is "spice", calls AddSpice function.
   * If modelText is "allergy", calls addAllergy function.
   * @returns The result of the corresponding function based on the value of modelText.
   */
  const HandleSubmit = () => {
    if (modelText === "category") {
      return AddCategory();
    }
    if (modelText === "type") {
      return AddType();
    }
    if (modelText === "spice") {
      return AddSpice();
    }
    if (modelText === "allergy") {
      // return addAllergy();
      setOpenModal(false);

      return OnEditMenuIngredients(
        menuById?.result,
        dishIngredientsListGpt?.result && dishIngredientsListGpt
          ? dishIngredientsListGpt?.result
          : menuById?.result?.items
      );
    }
  };
  /**
   * Handles the deletion of data based on the modelText value.
   * @param {{any}} data - The data to be deleted.
   * @returns The result of the corresponding delete function based on the modelText value.
   */
  const handleDelete = (data) => {
    if (modelText === "category") {
      return HandleDeleteCategoryList(data);
    }
    if (modelText === "type") {
      return HandleDeleteType(data);
    }
    if (modelText === "spice") {
      return HandleDeleteSpice(data);
    }
    if (modelText === "allergy") {
      if (dishIngredientsListGpt?.result && dishIngredientsListGpt) {
        dishIngredientsListGpt?.result.ingredients.splice(data, 1);
        setListArray(
          dishIngredientsListGpt?.result.ingredients?.map((item, index) => ({
            label: item,
            value: index,
          }))
        );
      }
      if (menuById?.result?.items?.ingredients) {
        menuById.result.items.ingredients.splice(data, 1);
        setListArray(
          menuById.result.items.ingredients?.map((item, index) => ({
            label: item,
            value: index,
          }))
        );
      }

      // return HandleDeleteAllergy(data);
    }
  };

  useEffect(() => {
    setValue("description", dishIngredientsListGpt?.result.description);
    setListArray(
      dishIngredientsListGpt?.result?.ingredients?.map((item) => ({
        label: item,
        value: item,
      }))
    );
  }, [dishIngredientsListGpt?.result, setValue]);

  /**
   * useEffect hook that calculates the final price based on the original price and discount price,
   * and updates the "FPrice" value using the setValue function.
   * @param {Function} setValue - The function to update the value in the state.
   * @param {number} originalPrice - The original price of the item.
   * @param {number} discountPrice - The discount price of the item.
   * @returns None
   */
  useEffect(() => {
    const finalPrice = originalPrice - discountPrice;
    setValue("FPrice", finalPrice);
  }, [originalPrice, discountPrice]);

  useEffect(() => {
    if (AiPhoto[0]?.url && menuNameWatch != "") {
      setValue("image", {
        url: AiPhoto[0]?.url,
        AiGenerated: true,
      });
    }
  }, [aiPhotoGenerationIsLoading]);

  const handleRegenerate = () => {
    setValue("image", {
      url: "",
      AiGenerated: false,
    });
    const dish_name = watch("item_name");
    const description = watch("description");
    onAIPhotoGeneration({
      dish_name,
      description,
    });
  };

  return (
    <HeaderWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="overflow-auto"
          style={{
            height: "calc(100vh - 106px)",
          }}
        >
          <Grid container spacing={2} className="p-[24px] ">
            <Grid item xs={10}>
              <h1 className=" text-[18px]  font-[700] font-quicksand">
                {menuId?.id ? "Edit menu item" : " Add new menu item"}
              </h1>
            </Grid>
            <Grid item xs={12}>
              {aiPhotoGenerationIsLoading ? (
                <InlineLoader />
              ) : (
                <div className="max-w-[472px]  ">
                  <Controller
                    name="image"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return !field?.value?.AiGenerated ? (
                        <DragAndDropCoverPic
                          {...field}
                          displayPicture={field.value?.url}
                          error={!!error}
                          helperText={error ? error.message : null}
                          handleRegenerate={handleRegenerate}
                        />
                      ) : (
                        <WatermarkImage
                          src={field.value?.url}
                          watermarkText="AI"
                          setValue={setValue}
                          handleRegenerate={handleRegenerate}
                        />
                      );
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid container item xs={12} alignItems={"flex-end"}>
              <Grid item className="w-[88%] sm:w-[472px]  mr-5">
                <Controller
                  name="item_name"
                  control={control}
                  rules={{
                    required: "Menu Name is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextInput
                        {...field}
                        variant="outlined"
                        label="Name"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item className="max-w-[472px] m-5">
                {dishIngredientsListGptLoading ? (
                  <div className="First absolute top-0 bottom-0 left-0 right-0 bg-[#d3cdcd89] flex justify-center items-center z-[100]">
                    <div className="border-grey-300 h-14 w-14 animate-spin rounded-full border-8 border-t-[#ED1703]" />
                  </div>
                ) : (
                  <ContentPasteSearchIcon
                    sx={{
                      marginBottom: "10px",
                      color: "#ED1703",
                      marginLeft: "15px",
                    }}
                    onClick={() => setGptCheck(true)}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="max-w-[472px]">
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: "Description is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextInput
                        {...field}
                        variant="outlined"
                        label="Description"
                        error={!!error}
                        multiline
                        rows={3}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="max-w-[472px]">
                <Controller
                  name="category"
                  control={control}
                  rules={{
                    required: "Category is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <SearchableDropDown
                        {...field}
                        variant="outlined"
                        label="Category"
                        placeholder="Select Category"
                        options={categoryList?.result?.map((item) => ({
                          value: item?._id,
                          label: item?.food_category,
                        }))}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </div>
              <p
                className="text-genieRed font-[700] text-[14px] mt-1 font-quicksand w-fit"
                onClick={handleCategory}
              >
                Create new category
              </p>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={0.5} className="max-w-[472px]">
                <Grid item xs={4}>
                  <Controller
                    name="oPrice"
                    control={control}
                    rules={{
                      required: "Price is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          type="number"
                          variant="outlined"
                          label="Original Price(AED)"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="dPrice"
                    control={control}
                    rules={{
                      required: "Price is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          type="number"
                          variant="outlined"
                          label="Discount(AED)"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="FPrice"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          //   variant="outlined"
                          disabled={true}
                          label="Final price (AED)"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={0.5} className="max-w-[472px]">
                <Grid item xs={4}>
                  <Controller
                    name="min"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          variant="outlined"
                          type="number"
                          label="Portion"
                          placeholder="Min"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="max"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          variant="outlined"
                          type="number"
                          label={" - "}
                          placeholder="Max"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="max-w-[472px]">
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: "Type is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <SearchableDropDown
                        {...field}
                        variant="outlined"
                        label="Type"
                        placeholder="Select type"
                        options={foodType?.result?.map((item) => ({
                          value: item?._id,
                          label: item?.food_type,
                        }))}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
                <p
                  className="text-genieRed font-[700] text-[14px] mt-1 font-quicksand"
                  onClick={handleType}
                >
                  Create new option
                </p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="max-w-[472px]">
                <Controller
                  name="mealTiming"
                  control={control}
                  rules={{
                    required: "Meal Timing is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <SearchableDropDown
                        {...field}
                        variant="outlined"
                        label="Meal Timing"
                        options={mealTimingOptions}
                        isMulti
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="max-w-[472px]">
                <Controller
                  name="spiceLevelId"
                  control={control}
                  rules={{
                    required: "Spice level is Required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <SearchableDropDown
                        {...field}
                        variant="outlined"
                        label="Spiciness level"
                        options={spiceLevel?.result?.map((item) => ({
                          value: item?._id,
                          label: item?.spice_level,
                        }))}
                        placeholder="Select level"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
                <p
                  className="text-genieRed font-[700] text-[14px] mt-1 font-quicksand"
                  onClick={handleSpicinessLevel}
                >
                  Create new option
                </p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="max-w-[472px]">
                <p className="text-[#252525] font-[700] text-[14px] mt-1 font-quicksand">
                  Ingredients Used
                </p>
                <p
                  className="text-genieRed font-[700] text-[14px] mt-1 font-quicksand"
                  onClick={handleAllergies}
                >
                  Create new option
                </p>
              </div>
            </Grid>
          </Grid>
          {openModal && (
            <AddSearchableDropdown
              open={openModal}
              handleCancel={() => (setOpenModal(false), setAddList(false))}
              handleConfirmClick={HandleSubmit}
              handleDelete={handleDelete}
              list={listArray}
              headerText={headerText}
            />
          )}
        </div>
        <div className=" bg-white sticky bottom-0  border  p-2 ">
          <div className="  flex justify-end    gap-2  ">
            <BasicButton
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
                width: "236px",
                height: "36px",
              }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </BasicButton>
            <BasicButton
              color="primary"
              type="submit"
              sx={{
                textTransform: "none",
                width: "236px",
                height: "36px",
              }}
            >
              {menuId?.id ? "Update" : "Save"}
            </BasicButton>
          </div>
        </div>
      </form>
    </HeaderWrapper>
  );
}

export default AddMenu;
