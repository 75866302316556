import { Box, Grid } from "@mui/material";
import BasicButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWrapper from "../../../components/headerwrapper";
import React, { useState, useEffect } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAuth, useRestaurant } from "../../../utils/hooks";
import DrawerComponent from "../../../components/Drawer/Index";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import QrCodeModal from "./QrCodeDisplay";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import io from 'socket.io-client';
// const socket = io('http://localhost:4000');

function TableManagement() {
  const navigate = useNavigate();
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [tableLink, setTableLink] = useState("");
  const { socket, userData } = useAuth();
  const {
    useGetTable,
    responseTableData,
    setResponseTableData,
    qrScanned,
    setQrSCanned,
    isTableDrawer,
    setIsTableDrawer,
    OnAddTable,
    addTableIsLoading,
  } = useRestaurant();

  /**
   * Toggles the state of the table drawer between open and closed.
   * @returns None
   */
  const toggleTableDrawer = () => {
    setIsTableDrawer(!isTableDrawer);
  };
  /**
   * useEffect hook that sets up socket event listeners and emits requests to the server.
   * @returns None
   */
  useEffect(() => {
    socket.emit("request", { en: "PL", data: { email: userData?.email } });

    socket.emit("request", {
      en: "TS",
      data: { partnerId: `${userData?._id}` },
    });

    socket.on("QS", (data) => {
      setQrSCanned([...qrScanned, data]);
    });

    socket.on("TS", (data) => {
      setResponseTableData(data?.data);
    });
    return () => (
      socket.off("QS", (data) => {
        console.log(data);
      }),
      socket.off("Ts", (data) => {
        console.log(data);
      })
    );
  }, []);
  const { data: tableData } = useGetTable();

  return (
    <>
      <HeaderWrapper>
        <div
          className="overflow-auto"
          style={{
            // height:"100vh"
            height: "calc(100vh - 55px)",
          }}
        >
          <Grid container sx={12} spacing={2} className="p-[24px]">
            <Grid container xs={12} justifyContent="space-between">
              <Grid item>
                <h1 className=" ml-5 text-[24px] font-[600] font-semibold font-quicksand">
                  Table Management
                </h1>
              </Grid>
              <Grid item>
                <BasicButton
                  color="primary"
                  sx={{ textTransform: "none", mr: 2 }}
                  variant="outlined"
                  onClick={() => navigate("history")}
                >
                  View order history
                </BasicButton>
                <BasicButton
                  color="primary"
                  sx={{ textTransform: "none" }}
                  onClick={toggleTableDrawer}
                >
                  Manage tables
                </BasicButton>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="space-around">
              <Grid item width={"24%"} height={"120px"}>
                <div className="   p-1 flex flex-col items-center border-2 rounded-lg">
                  <h3 className=" font-quicksand font-[600] text-[14px]">
                    Total Order
                  </h3>
                  <h4 className="font-quicksand font-[700] text-[48px]">
                    {responseTableData?.total_orders || 0}
                  </h4>
                </div>
              </Grid>
              <Grid item width={"24%"} height={"120px"}>
                <div className="   p-1 flex flex-col items-center border-2 rounded-lg">
                  <h3 className=" font-quicksand font-[600] text-[14px]">
                    Total table
                  </h3>
                  <h4 className="font-quicksand font-[700] text-[48px]">
                    {responseTableData?.total_tables || 0}
                  </h4>
                </div>
              </Grid>
              <Grid item width={"24%"} height={"120px"}>
                <div className="   p-1 flex flex-col items-center border-2 rounded-lg">
                  <h3 className=" font-quicksand font-[600] text-[14px]">
                    Total occupied
                  </h3>
                  <h4 className="font-quicksand font-[700] text-[48px]">
                    {responseTableData?.total_occupied || 0}
                  </h4>
                </div>
              </Grid>
              <Grid item width={"24%"} height={"120px"}>
                <div className="   p-1 flex flex-col items-center border-2 rounded-lg">
                  <h3 className=" font-quicksand font-[600] text-[14px]">
                    Total available
                  </h3>
                  <h4 className="font-quicksand font-[700] text-[48px]">
                    {responseTableData?.total_available || 0}
                  </h4>
                </div>
              </Grid>
            </Grid>
            <Grid container item sx={12} justifyContent="space-evenly">
              <Grid item className="bg-gray-100 min-h-[65vh] w-[18vw] rounded">
                <h1 className="border-b-2 p-2 font-quicksand font-[700] text-[14px]">
                  QR Scanned ({qrScanned?.length})
                </h1>
                {qrScanned.map((item) => (
                  <div className="p-2 bg-white rounded-lg m-2 border-2">
                    <h1 className="font-quicksand pb-2 font-[700] text-[14px]">
                      Table {item?.data?.tableSequence}
                    </h1>
                    <div className="flex justify-between">
                      <h2 className="text-[#7F7F7F] font-[700] font-quicksand text-[13px] truncate">
                        {item?.data?.tableUrl}
                      </h2>
                      <ContentCopyIcon
                        sx={{ width: "20px", height: "20px", color: "#7F7F7F" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.data?.tableUrl);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </Grid>
              <Grid item className="bg-gray-100 min-h-[65vh] w-[18vw] rounded">
                <h1 className="border-b-2 p-2 font-quicksand font-[700] text-[14px]">
                  Order in cart (0)
                </h1>

                {/* <div className="p-2 bg-white rounded-lg m-2 border-2">
                  <h1 className="font-quicksand pb-2 font-[700] text-[14px]">
                    Table 1
                  </h1>
                  <div className="flex justify-between">
                    <h2 className="text-[#7F7F7F] font-[700] font-quicksand text-[13px] truncate">
                      https://www.bubble.io/database/All/Tables/156et7365e3.html
                    </h2>
                    <ContentCopyIcon
                      sx={{ width: "20px", height: "20px", color: "#7F7F7F" }}
                    />
                  </div>
                </div> */}
              </Grid>
              <Grid item className="bg-gray-100 min-h-[65vh] w-[18vw] rounded">
                <h1 className="border-b-2 p-2 font-quicksand font-[700] text-[14px]">
                  Order placed (0)
                </h1>

                {/* <div className="p-2 bg-white rounded-lg m-2 border-2">
                  <h1 className="font-quicksand pb-2 font-[700] text-[14px]">
                    Table 1
                  </h1>
                  <div className="flex justify-between">
                    <h2 className="text-[#7F7F7F] font-[700] font-quicksand text-[13px] truncate">
                      https://www.bubble.io/database/All/Tables/156et7365e3.html
                    </h2>
                    <ContentCopyIcon
                      sx={{ width: "20px", height: "20px", color: "#7F7F7F" }}
                    />
                  </div>
                </div> */}
              </Grid>
              <Grid item className="bg-gray-100 min-h-[65vh] w-[18vw] rounded">
                <h1 className="border-b-2 p-2 font-quicksand font-[700] text-[14px]">
                  Order delivered (0)
                </h1>

                {/* <div className="p-2 bg-white rounded-lg m-2 border-2">
                  <h1 className="font-quicksand pb-2 font-[700] text-[14px]">
                    Table 1 (0)
                  </h1>
                  <div className="flex justify-between">
                    <h2 className="text-[#7F7F7F] font-[700] font-quicksand text-[13px] truncate">
                      https://www.bubble.io/database/All/Tables/156et7365e3.html
                    </h2>
                    <ContentCopyIcon
                      sx={{ width: "20px", height: "20px", color: "#7F7F7F" }}
                    />
                  </div>
                </div> */}
              </Grid>
              <Grid item className="bg-gray-100 min-h-[65vh] w-[18vw] rounded">
                <h1 className="border-b-2 p-2 font-quicksand font-[700] text-[14px]">
                  Payment in progress (0)
                </h1>
                {/* 
                <div className="p-2 bg-white rounded-lg m-2 border-2">
                  <h1 className="font-quicksand pb-2 font-[700] text-[14px]">
                    Table 1
                  </h1>
                  <div className="flex justify-between">
                    <h2 className="text-[#7F7F7F] font-[700] font-quicksand text-[13px] truncate">
                      https://www.bubble.io/database/All/Tables/156et7365e3.html
                    </h2>
                    <ContentCopyIcon
                      sx={{ width: "20px", height: "20px", color: "#7F7F7F" }}
                    />
                  </div>
                </div> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </HeaderWrapper>

      <DrawerComponent
        position="right"
        open={isTableDrawer}
        onClose={toggleTableDrawer}
        visiblePuller={false}
      >
        <Box
          sx={{
            width: 300,
            display: "flex",
            flexDirection: "column ",
          }}
        >
          <div
            className="flex  w-full  justify-between mt-8   pb-3  text-red-900 border-b-2 "
            onClick={toggleTableDrawer}
          >
            <h1 className="font-quicksand font-[700] text-[20px] ">
              Manage tables
            </h1>
            <CloseIcon className="  m-1    " />
          </div>
          <div className="mt-2 font-quicksand font-[600] text-[14px]">
            Total: {tableData?.result.length}{" "}
            {tableData?.result.length === 1 ? "table" : "tables"}
          </div>

          <div
            className="overflow-auto"
            style={{
              height: " calc(100vh - 162px)",
            }}
          >
            {tableData?.result.map((item) => (
              <div className="p-2 bg-white rounded-lg my-2 border-2">
                <h1 className="font-quicksand pb-2 font-[700] text-[14px]">
                  {item?.tableSequence}
                </h1>
                <div className="flex justify-between">
                  <h2 className="text-[#7F7F7F] font-[700] font-quicksand text-[13px] truncate">
                    {item?.tableUrl}
                  </h2>
                  <ContentCopyIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#7F7F7F",
                      "&:hover": {
                        color: "#555",
                      },
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(item?.tableUrl);
                    }}
                  />
                  <OpenInNewIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#7F7F7F",
                      "&:hover": {
                        color: "#555",
                      },
                    }}
                    onClick={() => {
                      window.open(item?.tableUrl);
                      // navigator.clipboard.writeText(item?.tableUrl);
                    }}
                  />
                  <DownloadForOfflineIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      color: "#7F7F7F",
                      "&:hover": {
                        color: "#555",
                      },
                    }}
                    onClick={() => {
                      setTableLink(item?.tableUrl);
                      setQrCodeOpen(true);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <BasicButton
            className="mt-3 p-2 bg-white rounded-lg my-2 border-2 border-dashed flex justify-center items-center   font-quicksand  font-[700] text-[14px] text-genieRed"
            variant="outlined"
            onClick={() => OnAddTable()}
            disabled={addTableIsLoading}
          >
            {addTableIsLoading ? "Loading ..." : <AddIcon />}
            {addTableIsLoading ? null : "Add new table"}
          </BasicButton>
        </Box>
      </DrawerComponent>

      {qrCodeOpen && (
        //TODO:Add Table Number in model and jpg
        <QrCodeModal
          open={qrCodeOpen}
          handleCancel={() => setQrCodeOpen(false)}
          link={tableLink}
          // handleConfirmClick={() => ( navigate(-1))}
        />
      )}
    </>
  );
}

export default TableManagement;
