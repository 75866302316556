import { Box, Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderWrapper from "../../../components/headerwrapper";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import CustomTable from "../../../components/CustomTable";
import { useRestaurant } from "../../../utils/hooks";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import StarIcon from "@mui/icons-material/Star";
import DrawerComponent from "../../../components/Drawer/Index";
import BasicButton from "../../../components/Button";
import { PageLoader } from "../../../components/loader";

function OrderHistory() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedRange, setSelectedRange] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [detailViewOrder, setDetailViewOrder] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const { useOrderHistory, useGetOrderDetails } = useRestaurant();

  const { data: orderHistory, isLoading: orderHistoryLoading } =
    useOrderHistory({
      pageSize: pageSize,
      page: page,
      fromDate: dayjs(selectedRange[0]).format("YYYY-MM-DD"),
      toDate: dayjs(selectedRange[1]).format("YYYY-MM-DD"),
    });

  const { data: orderDetailsPage, isLoading: orderDetailIsLoading } =
    useGetOrderDetails({ id: orderDetails?.orderId });

  const columns = [
    { name: "orderId", label: "Order number" },
    {
      name: "createdAt",
      label: "Order date",
      options: {
        customBodyRender: (value) => {
          return (
            <h1 className=" font-quicksand font-[500] text-[16px] truncate">
              {dayjs(value).format("DD MMM YYYY")}
            </h1>
          );
        },
      },
    },
    { name: "tableNumber", label: "Table number" },
    {
      name: "totalOrderValue",
      label: "Order total",
      options: {
        customBodyRender: (value) => {
          return (
            <h1 className=" font-quicksand font-[500] text-[16px] truncate">
              AED {value}
            </h1>
          );
        },
      },
    },
    { name: "dinerCount", label: "Diners" },
    {
      name: "orderExp",
      label: "Order experience",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="font-quicksand font-[500] text-[16px]  ">
              {value.map((exp, index) => (
                <span
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#8DCBFF]" : "bg-[#D7CF07]"
                  } px-1.5 m-1 rounded-full text-black `}
                >
                  {exp}
                </span>
              ))}
            </div>
          );
        },
      },
    },
    { name: "paymentMethod", label: "Payment method" },
    {
      name: "ratings",
      label: "Rating",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex ">
              <h1 className=" font-quicksand font-[500] text-[16px] truncate">
                {value}
              </h1>
              <StarIcon sx={{ color: "#E0E001" }} />
            </div>
          );
        },
      },
    },
    {
      name: "comments",
      label: "Customer Comments",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="flex justify-around">
              <h1 className=" font-quicksand font-[500] text-[16px] truncate">
                {value}
              </h1>
              <Tooltip title={value} arrow>
                <OpenInFullIcon sx={{ color: "#ED1703" }} />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowData =
            orderHistory?.result?.OrderHistory[tableMeta.rowIndex];
          return (
            <>
              <h1
                className="text-genieRed font-quicksand font-[600] text-[16px]"
                onClick={() => {
                  setOrderDetails(rowData);
                  setDetailViewOrder(true);
                }}
              >
                View item
              </h1>
            </>
          );
        },
      },
    },
  ];

  /**
   * Updates the current page to the specified page.
   * @param {{number}} _page - The page number to change to.
   * @returns None
   */
  const handleChangePage = (_page) => {
    setPage(_page);
  };
  /**
   * Update the page size based on the given page count.
   * @param {_pageCount} - The new page count to set as the page size.
   * @returns None
   */
  const handleChangePageCount = (_pageCount) => {
    setPageSize(_pageCount);
  };

  /**
   * Updates the selected date range based on the provided value.
   * @param {{any}} value - The new date range value to set.
   * @returns None
   */
  const handleDateRangeChange = (value) => {
    setSelectedRange(value);
  };
  /**
   * Toggles the visibility of the order detail drawer.
   * If the drawer is currently visible, it will be hidden, and vice versa.
   * @returns None
   */
  const toggleViewOrderDetailDrawer = () => {
    setDetailViewOrder(!detailViewOrder);
  };

  const categoryCounts = {};

  /**
   * Iterates through the list of items on the order details page and counts the occurrences of each item
   * based on the combination of food item category and item name.
   * @param {{Array}} orderDetailsPage - The list of items on the order details page.
   * @returns None
   */
  orderDetailsPage?.result?.regularExpItems.forEach((item) => {
    const key = item.foodItemCategory + "-" + item.itemName;
    categoryCounts[key] = (categoryCounts[key] || 0) + 1;
  });

  /**
   * Creates a list of objects from the keys of the categoryCounts object.
   * Each object in the list contains the category, itemName, and count.
   * @returns An array of objects with category, itemName, and count properties.
   */
  const categoryList = Object.keys(categoryCounts).map((key) => {
    const [category, itemName] = key.split("-");
    return { category, itemName, count: categoryCounts[key] };
  });
  const genieCategoryCounts = {};

  /**
   * Iterates through the list of items in the order details page and counts the occurrences
   * of each item based on the combination of food item category and item name.
   * @param {{Object}} orderDetailsPage - The order details page object containing the list of items.
   * @param {{Object}} genieCategoryCounts - An object to store the count of each item category.
   * @returns None
   */
  orderDetailsPage?.result?.genieExpItems.forEach((item) => {
    const key = item.foodItemCategory + "-" + item.itemName;
    genieCategoryCounts[key] = (genieCategoryCounts[key] || 0) + 1;
  });

  /**
   * Generates a list of objects containing category, itemName, and count based on the keys and values
   * in the genieCategoryCounts object.
   * @returns An array of objects with category, itemName, and count properties.
   */
  const genieCategoryList = Object.keys(genieCategoryCounts).map((key) => {
    const [category, itemName] = key.split("-");
    return { category, itemName, count: genieCategoryCounts[key] };
  });

  return (
    <>
      <HeaderWrapper>
        <div
          className="overflow-auto"
          style={{
            // height:"100vh"
            height: "calc(100vh - 55px)",
          }}
        >
          <Grid
            container
            sx={12}
            spacing={2}
            direction={"column"}
            className="p-[24px]"
          >
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>
                <h1 className="  text-[24px] font-[600] font-semibold font-quicksand">
                  Order History
                </h1>
              </Grid>
              <Grid item>
                <CloseIcon onClick={() => navigate(-1)} />
              </Grid>
            </Grid>
            <Grid item>
              <DatePicker
                // value={dayjs().format("YYYY-MM-DD")}
                value={selectedRange}
                onChange={handleDateRangeChange}
                placeholder="Select Date"
              />
            </Grid>
            <Grid item xs={5}>
              <div className="w-[339px] h-[110px] rounded border-[2px] p-10 flex  flex-col items-center justify-center">
                <h1 className=" mt-4 font-[quicksand] font-[600] text-[14px] text-[#3F3F3F]">
                  Number of orders
                </h1>
                <h4 className="font-quicksand font-[700] text-[48px] text-[#3F3F3F]">
                  {orderHistory?.result?.totalCount || 0}
                </h4>
              </div>
            </Grid>
            <Grid item>
              <CustomTable
                data={orderHistory?.result?.OrderHistory || []}
                columns={columns}
                isLoading={orderHistoryLoading}
                maxHeight="46vh"
                noDataText={
                  // selectedValue || debouncedSearchValue
                  //   ? "No Clients Found" :
                  "No Data Found"
                }
                pagination
                totalCount={orderHistory?.result?.totalCount}
                paginationServer
                page={page}
                countPerPage={pageSize}
                onChangePage={handleChangePage}
                onChangeCountPerPage={handleChangePageCount}
              />
            </Grid>
          </Grid>
        </div>
      </HeaderWrapper>

      <DrawerComponent
        position="right"
        
        open={detailViewOrder}
        onClose={toggleViewOrderDetailDrawer}
      >
        {orderDetailIsLoading ? (
          <div className="w-[450px] h-[100vh]">

          <PageLoader  />
          </div> 
        ) : (
          <Box
            sx={{
              width: 450,
              // textAlign: "center",
              // paddingTop: "52px",
              display: "flex",
              flexDirection: "column ",
              // alignItems: "center",
            }}
          >
            <div
              className="flex  w-full  justify-between mt-8   "
              // onClick={toggleDrawer}
            >
              <h1 className="font-quicksand font-[700] text-[20px] truncate ">
                OrderId {orderDetailsPage?.result?.orderId}
              </h1>
              <CloseIcon
                className=" rounded-[50%] border text-[#9b1a1a] m-1    "
                onClick={toggleViewOrderDetailDrawer}
              />
            </div>
            <div className="font-quicksand font-[500] text-[16px]   ">
              <h1> Order experience </h1>
              {orderDetailsPage?.result?.orderExp?.map((exp, index) => (
                <span
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#8DCBFF]" : "bg-[#D7CF07]"
                  } px-1.5 m-1 rounded-full text-black `}
                >
                  {exp}
                </span>
              ))}
            </div>
            <br />
            <div className="font-quicksand font-[500] text-[16px]  ">
              <h1 className="font-quicksand font-[600] text-[16px] text-[#7F7F7F]">
                Dietary preference
              </h1>
              <span className="font-quicksand  text-[16px] text-[#3F3F3F]">
                {orderDetailsPage?.result?.dietaryPreference?.map(
                  (exp, index) => (
                    <span key={index} className={` px-1.5 m-1  `}>
                      {exp}
                      <br />
                    </span>
                  )
                )}
              </span>
            </div>
            <br />

            <div className="font-quicksand font-[500] text-[16px]">
              <h1 className="font-quicksand font-[600] text-[16px] text-[#7F7F7F]">
                Type of food
              </h1>
              <span className="font-quicksand  text-[16px] text-[#3F3F3F]">
                <span className="font-quicksand  text-[16px] text-[#3F3F3F]">
                  {orderDetailsPage?.result?.spiceLevel?.map((exp, index) => (
                    <span key={index} className={` px-1.5 m-1  `}>
                      {exp}
                      <br />
                    </span>
                  ))}
                </span>
              </span>
            </div>
            <br />

            <div className="font-quicksand font-[500] text-[16px]">
              <h1 className="font-quicksand font-[600] text-[16px] text-[#7F7F7F]">
                Allergies
              </h1>
              <span className="font-quicksand  text-[16px] text-[#3F3F3F]">
                {orderDetailsPage?.result?.allergies?.map((exp, index) => (
                  <span key={index} className={` px-1.5 m-1  `}>
                    {exp}
                    <br />
                  </span>
                ))}
              </span>
            </div>
            <br />

            <div className="font-quicksand font-[500] text-[16px]">
              <h1 className="font-quicksand font-[600] text-[16px] text-[#7F7F7F]">
                Regular experience items
              </h1>
              <span className="font-quicksand  text-[16px] text-[#3F3F3F]">
                {categoryList.map((category, index) => (
                  <li key={index}>
                    {category.category}: {category.itemName}({category.count})
                  </li>
                ))}
              </span>
            </div>
            <br />
            <div className="font-quicksand font-[500] text-[16px]">
              <h1 className="font-quicksand font-[600] text-[16px] text-[#7F7F7F]">
                Genie experience items
              </h1>
              <span className="font-quicksand  text-[16px] text-[#3F3F3F]">
                {genieCategoryList.map((category, index) => (
                  <li key={index}>
                    {category.category}: {category.itemName}({category.count})
                  </li>
                ))}
              </span>
            </div>
            <br />

            <div className="flex absolute bottom-[5px]  w-[90%]">
              <BasicButton
                className={`text-white  opacity-[80%] px-3 py-2 font-quicksand font-[700] text-[16px]  hover:border-b-2 hover:ease-out `}
                sx={{ width: "100%" }}
                onClick={toggleViewOrderDetailDrawer}
              >
                Done
              </BasicButton>
            </div>
          </Box>
        )}
      </DrawerComponent>
    </>
  );
}

export default OrderHistory;
