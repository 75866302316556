import { Grid } from "@mui/material";
import React from "react";
import Logo from "../../../assets/images/FrameTrimmed2.png";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

function RestaurantPaymentFailed() {
  return (
    <>
      <Grid container spacing={1} gap={1}>
        <Grid
          item
          xs={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={5}
          sx={{ marginTop: "50px" }}
        >
          <Grid item xs={12} className="flex justify-center">
            <WifiOffIcon
              sx={{ color: "#777777", fontWeight: 400, fontSize: "48px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <h1 className=" font-[400] text-[16px] text-center">
              It looks like you are offline, check your internet connection and
              try again
            </h1>
          </Grid>
          <Grid
            item
            xs={12}
            className="flex justify-center text-genieRed"
            // OnClick={()=>window.location.reload()}
          >
            <RemoveShoppingCartIcon /> Refresh page
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default RestaurantPaymentFailed;
