import { Grid } from "@mui/material";
import React, { useEffect, useState,useRef } from "react";
import BasicButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWrapper from "../../../components/headerwrapper";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import DatePicker from "../../../components/DatePicker";
import { Dataset } from "@mui/icons-material";
import { useRestaurant } from "../../../utils/hooks";
import dayjs from "dayjs";
import { getAllInsights } from "../../../utils/controllers/Restaurant";
import { useQuery } from "react-query";

ChartJS.register(Tooltip, Legend, BarElement, CategoryScale, LinearScale);
function Insights() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dates,setDates] = useState({from:"",to:""});
  const { useGetAllergies,useGetInsights } = useRestaurant();
  const getInsights = useGetInsights();
  
  const { data: allergies } = useGetAllergies();


  const data = {
    labels: ["mon", "tue", "wed", "mon", "tue", "wed"],
    datasets: [
      {
        label: "sales number",
        data: [3, 6, 9, 12, 15],
        borderColor: "#9e1b32",
        backgroundColor: "#9e1b32",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: false,
    scales: {
      x: {
        barThickness: 10, // Adjust this value to decrease the bar width
      },
    },
  };


  const handleDate = (value) => {
    dayjs(value[0]).format("YYYY-MM-DD")
  }

  const {data:insights} = useQuery(["getInsights",dates],() => getAllInsights(dates),{
    enabled:true,
    select:(data) => data?.data,
    onSuccess: (data) => {},
  });


  useEffect(() => {
    const from = dayjs(dateRange[0]).format("YYYY-MM-DD");
    const to = dayjs(dateRange[0]).format("YYYY-MM-DD");
    setDates({from:"2024-03-01",to:"2024-05-25"})
  },[dateRange]);

  return (
    <>
      <HeaderWrapper>
        <div
          className="overflow-auto"
          style={{
            // height:"100vh"
            height: "calc(100vh - 51px)",
          }}
        >
          <Grid container sx={12} spacing={2} className="p-[24px]">
            <Grid container xs={12} justifyContent="space-between">
              <Grid item>
                <h1 className=" ml-5 text-[24px] font-[600] font-semibold font-quicksand">
                  Insights
                </h1>
              </Grid>
              <Grid item>
                <BasicButton
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: "126px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                  //   onClick={() => navigate("create")}
                >
                  Export report
                </BasicButton>
              </Grid>
            </Grid>
            <Grid item xs={8} md={3}>
         
             <DatePicker
               value={dayjs().format("YYYY-MM-DD")}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => 
                  setDateRange(update)}x
                isClearable={true}
                placeholderText="Select date range"
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} columns={12} paddingInline={"24px"}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div
                style={{ background: "white" }}
                className="h-[100px] rounded-md border border-solid border-gray-300 flex flex-col justify-center items-center p-[10px]"
              >
                <p className="text-gray-500">Average number of table size</p>
                <p className="text-[35px] font-[600] font-semibold font-quicksand">
                {insights?.result?.avgTableSize}
               
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {" "}
              <div
                style={{ background: "white" }}
                className="h-[100px] rounded-md border border-solid border-gray-300 flex flex-col justify-center items-center p-[10px]"
              >
                <p className="text-gray-500">Top Dietory Preferences</p>
                <p className="text-[35px] font-[600] font-semibold font-quicksand">
                {insights?.result?.topDietaryPreference?.name}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div
                style={{ background: "white" }}
                className="h-[100px] rounded-md border border-solid border-gray-300 flex flex-col justify-center items-center p-[10px]"
              >
                <p className="text-gray-500">Most Popular spice level</p>
                <p className="text-[35px] font-[600] font-semibold font-quicksand">
                  {insights?.result?.topSpicePreference?.name}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {" "}
              <div className="h-[150px] rounded-md border border-solid border-gray-300 p-[10px] overflow-y-scroll">
                <p>List of allergies (from frequently designated)</p>
                <ol className="list-decimal ml-4">
                  {allergies?.result?.map((item) => {
                    return <li>{item?.allergen}</li>;
                  })}
                </ol>
              </div>
            </Grid>
          </Grid>
          <Grid container padding={"24px"} columns={12}>
            <Grid item xs={12}>
              <div className="p-[24px] rounded-md border border-solid border-gray-300 flex justify-center items-center">
                <Bar data={data} options={options} height={"500px"} width={"500px"}></Bar>
              </div>
            </Grid>
          </Grid>
        </div>
      </HeaderWrapper>
    </>
  );
}

export default Insights;
