import React, { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FormHelperText, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useAuth } from "../../utils/hooks";

function DragAndDropLogoPic({
  onChange,
  value,
  error,
  helperText,
  close = true,
  className
}) {
  const { file, setFile } = useAuth();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".png, .jpg, .jpeg",
    maxSize: 5242880,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      onChange(null); // Clear previous value when new file is uploaded
    },
    onDropRejected: (err) => {
      if (err[0].errors[0].code === "file-invalid-type") {
        alert(
          "Could not read the selected file. Please upload only JPG or PNG format file!"
        );
      }
      if (err[0].errors[0].code === "file-too-large") {
        alert("File should not exceed more than 5mb!");
      }
    },
  });

  const handleDelete = () => {
    setFile(null);
    onChange(null);
  };

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (100 / width) * Math.min(width, height);
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: cropWidthInPercent,
        },
        1,
        width,
        height
      ),
      width,
      height
    );
    setCrop(crop);
  }

  const onCropComplete = useCallback((crop) => {
    setCompletedCrop(crop);
  }, []);

  const getCroppedImg = useCallback((image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  }, []);

  const handleCropSave = useCallback(async () => {
    if (imgRef.current && completedCrop) {
      try {
        const croppedImageBlob = await getCroppedImg(
          imgRef.current,
          completedCrop
        );
        const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
        onChange({
          file: croppedImageBlob,
          url: croppedImageUrl,
          name: file.name,
          type: file.type,
          width: completedCrop.width,
          height: completedCrop.height,
        });
      } catch (e) {
        console.error("Error in crop save:", e);
      }
    }
  }, [completedCrop, getCroppedImg, onChange, file]);

  return (
    <div className="flex flex-col">
      {!file && !value && (
        <>
          <div
            {...getRootProps({
              className:
                "dropzone  h-47 rounded-2.5 bg-secondary-light p-18 cursor-pointer",
            })}
            className={`border ${error ? "border-error-main" : "border-darkGray"
              } p-10 border-dashed border-2  w-full border-grey-500 rounded-lg ${className}`}
          >
            <p className="text-center font-normal">
              Drag & drop your logo<br /> OR <br />
              <span className="text-[#B80E0C]">Browse your files</span>
            </p>
            <input {...getInputProps()} />
          </div>
          {!error &&
            <p className="font-poppins font-normal text-[#4D4D4D] text-xs mt-[4px]">.png and .jpeg only, up to 1MB </p>
          }
        </>
      )}
      {(file || value) && (
        <div
          className={`relative flex flex-col items-center ${error ? "border-error-main" : "border-darkGray"
            } p-2   w-full  rounded-lg`}
        >
          {value ? (
            <img
              src={value.url}
              alt="Cropped logo"
              className="rounded-full w-36 h-36 object-cover"
            />
          ) : (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={onCropComplete}
              aspect={1}
              circularCrop
            >
              <img
                ref={imgRef}
                alt="logo"
                className="rounded-10 max-w-full max-h-[300px]"
                src={file?.preview}
                style={{ objectFit: "contain" }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}

          {file && !value && (
            <div className="flex mt-2">
              <Button onClick={handleDelete} sx={{ color: "red" }}>
                Cancel
              </Button>
              <div>
                <Button onClick={handleCropSave}>Confirm</Button>
              </div>
            </div>
          )}
          {close && (
            <div className="absolute top-2 right-2">
              <CloseIcon sx={{ color: "#ED1703" }} onClick={handleDelete} />
            </div>
          )}
        </div>
      )}
      {error && (
        <FormHelperText className="text-xs leading-5 text-[#ED1703]">
          <Typography variant="body2" sx={{ textWrap: "wrap", color: "Red" }}>
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </div>
  );
}

export default DragAndDropLogoPic;
