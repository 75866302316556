import { Grid } from "@mui/material";
import React from "react";
import Logo from "../../../assets/images/Frametrimed.png";
import logo from "../../../assets/images/plate.jpg";
import ShareIcon from "@mui/icons-material/Share";
import DirectionsIcon from "@mui/icons-material/Directions";
import DomainDisabledIcon from "@mui/icons-material/DomainDisabled";

function ClosedRestaurant(state, { data }) {
  // TODO:Closed Restaurant working navigation
  return (
    <>
      <Grid container spacing={1} gap={1}>
        <Grid
          item
          xs={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid>
        <Grid container item xs={12} spacing={5} gap={25}>
          <Grid item xs={12} className=" flex justify-center items-around">
            <div className="flex  flex-col items-center ">
              <img
                src={logo}
                alt="Logo"
                className="  rounded-full  w-[72px] h-[72px]"
              />
              <div className="flex flex-col justify-center  text-center gap-0.8 ml-2">
                <h1 className="font-QuickSand font-700 text-[18px]  text-[#484848]">
                  {data?.result?.name || "Sarfaraz"}
                </h1>
                <h3 className="font-QuickSand font-400 text-[14px] text-[#777777]">
                  {data?.result?.address || "hola shaik"}
                </h3>
                <h3 className="font-QuickSand font-400 text-[14px] text-[#777777]">
                  international Cuisine
                </h3>
                <h3 className="font-QuickSand font-400 text-[14px] text-[#777777]">
                  {data?.result?.workingHours?.from || "12:22"}-{" "}
                  {data?.result?.workingHours?.to || "12:22"}
                </h3>
              </div>
            </div>
          </Grid>

          <Grid item container spacing={3}>
            <Grid item xs={12} className=" flex  justify-center items-end    ">
              <DomainDisabledIcon
                sx={{ color: "#777777", fontWeight: 400, fontSize: "48px" }}
              />
            </Grid>
            <Grid item xs={12} className=" flex  justify-center items-end    ">
              <h1 className="">Sorry but we are currently closed</h1>
            </Grid>
            <Grid
              item
              xs={12}
              className=" flex text-genieRed  justify-center items-center    "
            >
              <DirectionsIcon sx={{ color: "red", marginRight: "6px" }} />
              Get directions
            </Grid>
            <Grid
              item
              xs={12}
              className=" flex text-genieRed justify-center items-center    "
            >
              <ShareIcon sx={{ color: "red", marginRight: "6px" }} /> share
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ClosedRestaurant;
