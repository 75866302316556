import { Grid } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function PeopleCount({
  decrementAdultCount,
  countDiner,
  incrementAdultCount,
  decrementKidsCount,
  incrementKidsCount,
}) {
  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      spacing={2}
      className=" p-3   "
    >
      <Grid item>
        <h1 className="font-quicksand font-[700] text-[16px]">
          How many adults are dining?
        </h1>
      </Grid>
      <Grid item>
        <div className="flex justify-between ">
          <button
            onClick={decrementAdultCount}
            className="p-3 bg-[#ED17031A] rounded-full text-black disabled:bg-[#ED170303]"
          >
            <RemoveIcon sx={{ color: "red" }} />
          </button>
          <span className="flex items-center font-quicksand font-[600] text-[28px]">
            {countDiner?.adultCount}
          </span>
          <button
            onClick={incrementAdultCount}
            className="p-3 bg-[#ED1703] rounded-full text-white disabled:bg-[#ED17031A]"
          >
            <AddIcon />
          </button>
        </div>
      </Grid>
      <Grid item>
        <h1 className="font-quicksand font-[700] text-[16px]">
          How many kids are dining?
        </h1>
      </Grid>
      <Grid item>
        <div className="flex justify-between ">
          <button
            onClick={decrementKidsCount}
            className="p-3 bg-[#ED17031A] rounded-full text-black disabled:bg-[#ED170303]"
            // disabled={cartItem?.quantity === 0 || addCartMenuIsLoading}
          >
            <RemoveIcon sx={{ color: "red" }} />
          </button>
          <span className="flex items-center font-quicksand font-[600] text-[28px]">
            {countDiner?.kidsCount}
          </span>
          <button
            onClick={incrementKidsCount}
            className="p-3 bg-[#ED1703] rounded-full text-white disabled:bg-[#ED17031A]"
            // disabled={addCartMenuIsLoading}
          >
            <AddIcon
            //   sx={{
            //     color: addCartMenuIsLoading ? "red" : "inherit",
            //   }}
            />
          </button>
        </div>
      </Grid>
    </Grid>
  );
}

export default PeopleCount;
