import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSuperAdmin } from "../../utils/hooks";
import { titleCase } from "../../utils/helper";
import fire from "../../assets/Icons/fire.png";

function CustomTabs({ tabs, children }) {
  const { tabValue, handleTabChange } = useSuperAdmin();

  return (
    <>
      {/* <div className=" flex justify-between md:justify-normal  w-[100%] h-[52px] sticky top-0 z-[999] max-w-[90vw] "> */}
      <Box sx={{ maxWidth: "100vw", bgcolor: "background.paper", position: "relative" }}
        className="relative after:w-12 after:h-full after:absolute after:right-0 after:top-0 after:bg-gradient-to-r after:from-transparent after:to-[#fff]">
        <Box sx={{
          '&::after': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '4px',
            backgroundColor: '#f3f0f3',
            position: 'absolute',
            bottom: 0,
            zIndex: 0,
          }
        }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="inherit"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable auto tabs example"
            className="text-black font-poppins"
            TabIndicatorProps={{ sx: { backgroundColor: "#63f099", height: "4px", zIndex: 1 } }}
            sx={{
              '& .MuiTab-root': {
                opacity: 1,
              },
              '& .MuiTab-root:first-of-type': {
                marginLeft: '16px',
              },
              '& .MuiTab-root:last-of-type': {
                marginRight: '16px',
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={
                  <>
                    <img className="h-[33px]" src={fire} alt="" />
                    <span className="font-medium">
                      {titleCase(tab.label)}
                    </span>
                  </>
                }
                {...a11yProps(index)}
                sx={{
                  textTransform: "none",
                  lineHeight: "21px",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      {children(tabValue)}
    </>
  );
}

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultTab: PropTypes.number,
  children: PropTypes.func.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default CustomTabs;
