import React, { useEffect } from "react";
import logo from "../../../assets/images/plate.jpg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDiner } from "../../../utils/hooks";
import BasicButton from "../../../components/Button";
function MenuCardDrawer({ data, addToChart }) {
  const { decrement, increment, count, PersonalizeEvent, isDrawerOpen } =
    useDiner();

  /**
   * useEffect hook that triggers a personalize event when the drawer is open.
   * @param {Function} PersonalizeEvent - The function to trigger the personalize event.
   * @param {boolean} isDrawerOpen - A boolean indicating whether the drawer is open.
   * @param {Object} data - The data object containing the _id.
   */
  useEffect(() => {
    if (isDrawerOpen)
      PersonalizeEvent({ itemIds: [data?._id], evenType: "VIEW" });
  }, [isDrawerOpen]);

  return (
    <div className="flex flex-col justify-around ">
      <div className=" mt-6 flex   items-start px-[16px]">
        <img
          src={logo}
          alt="Logo"
          className="rounded-full   w-[80px] h-[80px]"
        />
        <div className="flex flex-col ml-5 gap-0.8">
          <h1 className="font-QuickSand font-700 text-[14px]  text-[#484848]">
            {data?.item_name}
          </h1>
          <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
            AED {data?.oPrice - data?.dPrice}
          </h3>

          <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
            {data?.description}
          </h3>
          <div className="flex  items-center">
            <WarningAmberIcon
              sx={{ color: "red", fontSize: "14px", marginRight: "1px" }}
            />{" "}
            <span className="text-[14px] font-[600] text-[#3F3F3F] mr-1">
              Contains:
            </span>
            <span>
              {data?.contains?.map((item, index) => (
                <span key={index}>
                  {item.allergen}
                  {index !== data.contains.length - 1 && ", "}
                </span>
              ))}
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-between px-[16px] mb-4 mt-[16px]">
        <button
          onClick={decrement}
          className="p-5 bg-[#D1D1D14D] rounded-full text-black"
          disabled={count <= 0}
        >
          <RemoveIcon />
        </button>
        <span className="flex items-center font-quicksand font-[600] text-[28px]">
          {count}
        </span>
        <button
          className="p-5 bg-[#ED1703] rounded-full text-white"
          onClick={() => (
            increment(),
            PersonalizeEvent({ itemIds: [data?._id], evenType: "CART" })
          )}
        >
          <AddIcon />
        </button>
      </div>
      <div className="flex w-full  gap-2 items-evenly mb-3  justify-center">
        <BasicButton
          color="primary"
          sx={{
            textTransform: "none",
            width: "90%",
            height: "36px",
            padding: "9px 20px",
          }}
          onClick={addToChart}
        >
          Add item
        </BasicButton>
      </div>
    </div>
  );
}

export default MenuCardDrawer;
