import { API_SERVER_URL, API_VERSION } from "../../Constants/services";
import axios from "axios";
import LocalStorageService from "../../Constants/localStorage";

const LocalStorage = LocalStorageService.getService();

axios.defaults.headers.common.Authorization = `Bearer ${LocalStorage.getAccessToken()}`;

/**
 * Makes a GET request to the specified API endpoint with the provided configuration.
 * @param {string} url - The endpoint URL to make the GET request to.
 * @param {object} config - The configuration object for the request.
 * @returns A Promise that resolves with the response data from the API.
 */
export const getApi = (url, config) =>
  axios.get(API_SERVER_URL + API_VERSION + url, config);


/**
 * Send a PATCH request to the specified API endpoint with the provided data.
 * @param {string} url - The URL or endpoint to send the PATCH request to.
 * @param {any} data - The data to be sent in the PATCH request.
 * @param {boolean} [shortUrl=true] - Whether to use the short URL format or not.
 * @returns A Promise that resolves to the result of the PATCH request.
 */
export const patchApi = (url, data, shortUrl = true) =>
  axios.patch((shortUrl ? API_SERVER_URL : "") + url, data);

/**
 * Makes a POST request to the specified API endpoint with the provided data.
 * @param {string} url - The endpoint URL to which the POST request is made.
 * @param {object} data - The data to be sent as the body of the POST request.
 * @param {boolean} [shortUrl=true] - A flag indicating whether to use the short URL or not.
 * @returns A Promise that resolves to the response data from the POST request.
 */
export const postApi = (url, data, shortUrl = true) =>
  axios.post((shortUrl ? API_SERVER_URL : "") + API_VERSION + url, data);

/**
 * Makes a PUT request to the specified API endpoint with the provided data.
 * @param {string} url - The endpoint URL to send the PUT request to.
 * @param {any} data - The data to be sent in the request body.
 * @param {boolean} [shortUrl=true] - Whether to use the short URL format or not.
 * @returns A Promise that resolves to the response data from the PUT request.
 */
export const putApi = (url, data, shortUrl = true) =>
  axios.put((shortUrl ? API_SERVER_URL : "") + API_VERSION+ url, data);

/**
 * Sends a DELETE request to the specified API endpoint using Axios.
 * @param {string} url - The endpoint URL to send the DELETE request to.
 * @returns A Promise that resolves to the response data from the server.
 */
export const deleteApi = (url) => axios.delete(API_SERVER_URL +API_VERSION+ url);
