import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSuperAdmin } from "../../utils/hooks";

function CustomTabs({ tabs, children }) {
  const { tabValue, handleTabChange } = useSuperAdmin();

  return (
    <>
      {/* <div className=" flex justify-between md:justify-normal  w-[100%] h-[52px] sticky top-0 z-[999] max-w-[90vw] "> */}
      <Box sx={{ maxWidth: "100vw", bgcolor: "background.paper" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="inherit"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            className="md:px-10  text-genieRed"
            TabIndicatorProps={{ sx: { backgroundColor: "#ED1703" } }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                {...a11yProps(index)}
                sx={{ textTransform: "none" }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      {/* </div> */}
      {children(tabValue)}
    </>
  );
}

CustomTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultTab: PropTypes.number,
  children: PropTypes.func.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default CustomTabs;
