import React from "react";
import logo from "../../../assets/images/plate.jpg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShareIcon from "@mui/icons-material/Share";
import DirectionsIcon from "@mui/icons-material/Directions";

function RestaurantInfo({
  data,
  toggleDrawer,
  isDrawerOpenValue,
  isDrawerOpen,
}) {
  return (
    <>
      <div
        className={` ${
          !isDrawerOpen ? "my-5" : "mt-10"
        } flex h-[56px] justify-between items-center px-[16px]`}
      >
        <div className="flex ">
        <img
          src={logo}
          alt="Logo"
          className="rounded-full  w-[72px] h-[72px]"
        />
        <div className="flex flex-col justify-center gap-0.8 ml-2">
          <h1 className="font-QuickSand font-700 text-[14px]  text-[#484848]">
            {data?.result?.name}
          </h1>
          <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
            {data?.result?.address}
          </h3>
          {isDrawerOpenValue ? (
            <h3 className="font-QuickSand font-500 text-[12px] text-[#484848]">
              international Cuisine
            </h3>
          ) : (
            ""
          )}
          <h3 className="font-QuickSand font-500 text-[12px] text-[#777777]">
            {data?.result?.workingHours?.from}- {data?.result?.workingHours?.to}
          </h3>
          </div>
          </div>
        <div className="flex flex-col w-auto gap-2">
          <div className="flex justify-around gap-2">
            <a href={data?.result?.facebookURL} target="_blank">
              <FacebookIcon  />
            </a>
            <a href={data?.result?.instagramURL} target="_blank">
              <InstagramIcon  />
            </a>
            <a href={data?.result?.youtubeURL} target="_blank">
              <YouTubeIcon sx={{height:"25px"}}/>
            </a>
          </div>
          {!isDrawerOpenValue ? (
            <div className=" flex justify-end" onClick={toggleDrawer}>
              <InfoOutlinedIcon />
            </div>
          ) : (
            <div className=" flex justify-end">
              <a href={data?.result?.name} target="_blank">
                <DirectionsIcon sx={{ color: "red", marginRight: "6px" }} />
              </a>
              <ShareIcon sx={{ color: "red" }} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RestaurantInfo;
