import { Checkbox, Grid } from "@mui/material";
import React, { useState } from "react";
import BasicButton from "../../../components/Button";
import { useDiner } from "../../../utils/hooks";
// import { TextInput } from "../../../components/InputBox";

function Allergic({ allergicPreferences, setAllergicPreferences }) {
  const { allergicData, handleCheckboxChange } = useDiner();

  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      spacing={2}
      className=" p-3   "
    >
      <Grid item>
        <h1 className="font-quicksand font-[700] text-[16px]">
          Are you allergic to some types of food?
        </h1>
      </Grid>
      <Grid container item xs={12} direction="column">
        <BasicButton
          color={allergicPreferences === "Yes" ? "primary" : "grey"}
          variant="outlined"
          sx={{
            textTransform: "none",
            color: `${allergicPreferences === "Yes" ? "" : "#000"}`,
            fontWeight: "600",
          }}
          onClick={() => setAllergicPreferences("Yes")}
        >
          Yes
        </BasicButton>
      </Grid>
      <Grid container item xs={12} direction="column">
        <BasicButton
          color={allergicPreferences === "No" ? "primary" : "grey"}
          variant="outlined"
          sx={{
            textTransform: "none",
            color: `${allergicPreferences === "No" ? "" : "#000"}`,
            fontWeight: "600",
          }}
          onClick={() => setAllergicPreferences("No")}
        >
          No
        </BasicButton>
      </Grid>
      {allergicPreferences === "Yes" && (
        <>
          <Grid item>
            <h1 className="font-quicksand font-[700] text-[16px]">
              Select food types
            </h1>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {allergicData?.map((item) => (
              <Grid
                container
                item
                xs={12}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ borderBottom: "2px solid #D1D1D1" }}
              >
                <Grid item xs={10}>
                  <h1 className="font-quicksand font-[600] text-[14px]">
                    {item.label}
                  </h1>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Checkbox
                    color="default"
                    // sx={{
                    //     color: "#ED1703",
                    //     '&.Mui-checked': {
                    //       color: "#ED1703",
                    //     },
                    //   }}
                    checked={item.checked}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Allergic;
