import React, { useEffect } from "react";
import Login from "./Login";
import { useForm } from "react-hook-form";
import { useAuth } from "../../utils/hooks";
import { useLocation } from "react-router";

export default function LoginWrapper() {
  const {
    toggleIcon,
    togglePasswordIcon,
    onAdminSignIn,
    isSignInLoading,
    onPartnerSignIn,
    socket,
  } = useAuth();
  const location = useLocation();

  const { handleSubmit, control } = useForm({
    mode: "all",
  });

  /**
   * useEffect hook that listens for a "connect" event on the socket and logs a message
   * when the connection is established.
   * @returns None
   */
  useEffect(() => {
    socket.on("connect", () => {
      console.log(`Connected to server ${socket.id}`);
    });
    //  socket.on("disconnect", () => {
    //   console.log(`${socket.id} disconnected`);
    //   // socket.removeAllListeners();
    // });
  }, []);

  /**
   * Handles form submission based on the current location pathname.
   * If the pathname is "/superadmin", calls the onAdminSignIn function with the form data.
   * Otherwise, calls the onPartnerSignIn function with the form data.
   */
  const onSubmit = (data) => {
    // socket.emit("request", { en: "PL", data: { email: data?.username } });

    if (location?.pathname === "/superadmin") {
      onAdminSignIn(data);
    } else {
      onPartnerSignIn(data);
    }
  };

  return (
    <Login
      togglePasswordIcon={togglePasswordIcon}
      toggleIcon={toggleIcon}
      onSubmit={onSubmit}
      loading={isSignInLoading}
      control={control}
      handleSubmit={handleSubmit}
    />
  );
}
