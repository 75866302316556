import React from "react";
import RestaurantList from "./RestaurantList";
import HeaderWrapper from "../../components/headerwrapper";
import { useSuperAdmin } from "../../utils/Context/SuperAdmin";

function SuperAdminView() {
  const { useGetAllRestaurantList } = useSuperAdmin();
  const { data: restaurantList, isLoading: restaurantListIsLoading } =
    useGetAllRestaurantList();
  return (
    <HeaderWrapper>
      <RestaurantList
        list={restaurantList?.result}
        isLoading={restaurantListIsLoading}
      />
     </HeaderWrapper>
  );
}

export default SuperAdminView;
