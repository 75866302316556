import React from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

var drawerBleeding = 56;

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        className="activeDrawer"
        sx={{
          "& >.MuiPaper-root": {
            height: `calc(25% - ${drawerBleeding}px)`,

            overflow: "visible",
          },
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
            boxShadow:
              " 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
          },
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            height: "58px",
            border: 0,
          }}
        >
          <Puller />
          <div className="flex justify-between  items-center pt-2 px-1 h-full">
            <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
              {props.activeText}
            </h1>

            <h2 className="font-quicksand font-[700] text-[16px] text-[#252525] mr-2">
              {props?.activePrice}
            </h2>
          </div>
        </StyledBox>
        <StyledBox
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          {props?.children}
        </StyledBox>
      </SwipeableDrawer>
    </div>
  );
}

export default SwipeableEdgeDrawer;
