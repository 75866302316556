import React, { useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import Switch from "@mui/material/Switch";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import { PageLoader } from "../../../components/loader";
import plate from "../../../assets/images/plate.jpg";
import { useNavigate } from "react-router";
import { useRestaurant } from "../../../utils/hooks";
import ConfirmDeleteModal from "./ConfirmationDelete";

function MenuComponent({ list, isLoading }) {
  const [itemId, setItemId] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const { HandleDeleteMenu, OnEditMenuIsActive, editMenuIsLoading } =
    useRestaurant();

  /**
   * Handles the click event when the user confirms an action.
   * Calls HandleDeleteMenu function with the itemId and closes the confirmation modal.
   * @returns None
   */
  const handleConfirmClick = () => {
    HandleDeleteMenu(itemId);
    setOpenConfirmationModal(false);
  };

  return (
    <>
      {isLoading || !list ? (
        <PageLoader />
      ) : (
        <div className="p-5">
          <Grid container spacing={4} sm={12}>
            {list?.map((item) => (
              <Grid item>
                <div className="block  min-w-[252px]   px-5 pt-5 pb-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 ">
                  <div className="flex justify-between">
                    <img
                      src={plate}
                      alt="Menu"
                      className="w-[72px]  h-[72px] rounded-[50%]"
                    />

                    <div className="flex  items-center  gap-2 pb-1 h-5 ">
                      <EditCalendarOutlinedIcon
                        onClick={() => navigate(`edit/${item?._id}`)}
                        style={{ fontSize: 20, color: "#3F3F3F", opacity: 0.9 }}
                      />
                      <DeleteOutlineOutlinedIcon
                        onClick={() => {
                          setOpenConfirmationModal(true);
                          setItemId(item?._id);
                        }}
                        style={{ fontSize: 20, color: "#3F3F3F", opacity: 0.9 }}
                      />
                    </div>
                  </div>
                  <h5 className="my-1   text-gray-900 w-56 truncate ">
                    <Tooltip title={item?.item_name} arrow>
                      {item?.item_name}
                    </Tooltip>
                  </h5>
                  <p className="font-normal text-gray-700 ">
                    AED {item?.oPrice - item?.dPrice}
                  </p>
                  <Switch
                    className="ml-[-10px]"
                    checked={item?.isActive}
                    disabled={editMenuIsLoading}
                    onChange={() => {
                      OnEditMenuIsActive({
                        ...item,
                        isActive: !item?.isActive,
                      });
                    }}
                  />
                  {item?.isActive ? "Available" : "Not available"}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {openConfirmationModal && (
        <ConfirmDeleteModal
          open={openConfirmationModal}
          handleCancel={() => setOpenConfirmationModal(false)}
          handleConfirmClick={handleConfirmClick}
        />
      )}
    </>
  );
}

export default MenuComponent;
