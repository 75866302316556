import React, { forwardRef } from "react";
import {
  TextField,
  InputAdornment,
  FormHelperText,
  Typography,
  IconButton,
} from "@mui/material";

export const TextInput = forwardRef((props, ref) => {
  const {
    type,
    size,
    label,
    placeholder,
    name,
    value,
    defaultValue,
    helpertext,
    error,
    variant,
    disabled,
    required,
    multiline,
    rows,
    minRows,
    maxRows,
    precontent,
    postcontent,
    onChange,
    autoComplete,
    sx,
    labelsx,
    preContentSx,
    postContentSx,
    onFocus,
    onBlur,
    inputRef,
    autoFocus,
    view,
    handleClear,
  } = props;
  return (
    <div className="w-full truncate" data-testid="text-input">
      {label && (
        <label
          className=" font-quicksand font-[700]  block mb-2 text-[14px] text-[#252525]"
          htmlFor={`text-input-${name || "box"}`}
          required={required}
          style={labelsx}
        >
          {label}
        </label>
      )}
      <TextField
        className="w-full"
        {...props}
        ref={ref}
        autoComplete={autoComplete}
        sx={sx}
        fullWidth
        hiddenLabel
        label=""
        id={`text-input-${name || "box"}`}
        type={type || "text"}
        size={size || "small"}
        variant={variant || "filled"}
        placeholder={placeholder}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        InputProps={{
          // disableUnderline: true,
          readOnly: false,
          startAdornment: precontent ? (
            <InputAdornment position="start" style={preContentSx}>
              {precontent}
            </InputAdornment>
          ) : null,
          endAdornment:
            postcontent && value ? (
              <InputAdornment position="end" style={postContentSx}>
                <IconButton onClick={handleClear} edge="end">
                  {postcontent}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        inputRef={inputRef}
      />
      {error && helpertext && (
        <FormHelperText className="text-xs   leading-5 text-red-600" >
          <Typography variant="body2" sx={{textWrap:"wrap"}}>
            {helpertext}
          </Typography>
        </FormHelperText>
      )}
    </div>
  );
});
