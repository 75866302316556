import React from "react";

const Table = ({ headers, children }) => {
  return (
    <div className="w-full  h-full relative overflow-auto shadow-card rounded-xl border  ">
      <table className="table w-full border-collapse">
        <thead className="rounded-3xl sticky top-0 bg-white">
          <tr className="border">
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="text-left text-xs font-normal border-b border-r p-4"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mt-[50px] overflow-auto">{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
