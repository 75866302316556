import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../../utils/hooks";
import { CustomTextInput } from "../../../components/InputBox/CustomInput"
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import { PhoneNumberUtil } from 'google-libphonenumber';

function RestaurantLoginDetails({ control, watch }) {
  const { toggleIcon, togglePasswordIcon } = useAuth();
  const password = watch("password");

  return (
    <>
      <Grid item container xs={12} sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        {/* <Grid item xs={12}>
          <h1 className=" text-3xl mt-[20px]">Restaurant Details</h1>
          <h3 className="text-sm font-medium mt-4 ">
            Details about your Restaurant login Credentials
          </h3>
        </Grid> */}
        <Grid item xs={12} sm={8} md={6.5}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <CustomTextInput
                  {...field}
                  type="email"
                  required
                  variant="outlined"
                  label="EMAIL"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6.5}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone number is required",
              // pattern: {
              //   value: /^((\+971|00971|0)?)(5[024568]\d{7})$/,
              //   message: "Invalid Dubai phone number"
              // }
              validate: (value) => {
                const phoneUtil = PhoneNumberUtil.getInstance();
                try {
                  return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value)) || "Invalid phone number";
                } catch (error) {
                  return "Invalid phone number";
                }
              }
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                // <CustomTextInput
                //   {...field}
                //   type="number"
                //   required
                //   variant="outlined"
                //   label="PHONE NUMBER"
                //   error={!!error}
                //   helperText={error ? error.message : null}
                // />
                // <>
                //   <PhoneInput
                //     defaultCountry="ae"
                //     {...field}
                //     countrySelectorStyleProps={{
                //       buttonStyle: {
                //         height: "42px",
                //         width: "60px",
                //         borderRadius: "4px",
                //         marginRight: "20px",
                //         background: "#F7F7F7",
                //         border: "1px solid #E2E8F0"
                //       }
                //     }}
                //     inputStyle={{
                //       borderRadius: "4px",
                //       height: "42px",
                //       flex: 1,
                //       background: "#F7F7F7",
                //       border: "1px solid #E2E8F0"
                //     }}
                //   />
                // </>
                <PhoneNumberInput
                  {...field}
                  label="PHONE NUMBER"
                  required
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6.5}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is Required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long",
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#!])[A-Za-z\d@$!%*?&#!]{8,}$/,
                message:
                  "Must contain at least one uppercase,lowercase,number and  special character",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <div className="relative">
                  <CustomTextInput
                    {...field}
                    type={toggleIcon ? "Password" : "text"}
                    variant="outlined"
                    required
                    placeholder=""
                    label="PASSWORD"
                    // postContent={
                    //   toggleIcon ? (
                    //     <RemoveRedEyeIcon
                    //       sx={{ color: "#171A1FFF" }}
                    //       onClick={togglePasswordIcon}
                    //     />
                    //   ) : (
                    //     <VisibilityOffIcon
                    //       sx={{ color: "#171A1FFF" }}
                    //       onClick={togglePasswordIcon}
                    //     />
                    //   )
                    // }
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                  {toggleIcon ? (
                    <RemoveRedEyeIcon
                      // sx={{ color: "#757373", position: "absolute", left: "0px" }}
                      className="text-[#757373] absolute right-[12px] top-[35px] cursor-pointer"
                      onClick={togglePasswordIcon}
                    />
                  ) : (
                    <VisibilityOffIcon
                      // sx={{ color: "#757373" }}
                      className="text-[#757373] absolute right-[12px] top-[35px] cursor-pointer"
                      onClick={togglePasswordIcon}
                    />
                  )}
                </div>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6.5}>
          <Controller
            name="cpassword"
            control={control}
            rules={{
              required: "Confirm Password is Required",
              validate: (value) =>
                value === password || "Passwords do not match",
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <div className="relative">
                  <CustomTextInput
                    {...field}
                    type={toggleIcon ? "Password" : "text"}
                    variant="outlined"
                    placeholder=""
                    required
                    label="CONFIRM PASSWORD"
                    // postContent={
                    //   toggleIcon ? (
                    //     <RemoveRedEyeIcon
                    //       sx={{ color: "#757373" }}
                    //       onClick={togglePasswordIcon}
                    //     />
                    //   ) : (
                    //     <VisibilityOffIcon
                    //       sx={{ color: "#757373" }}
                    //       onClick={togglePasswordIcon}
                    //     />
                    //   )
                    // }
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                  {toggleIcon ? (
                    <RemoveRedEyeIcon
                      // sx={{ color: "#757373", position: "absolute", left: "0px" }}
                      className="text-[#757373] absolute right-[12px] top-[35px] cursor-pointer"
                      onClick={togglePasswordIcon}
                    />
                  ) : (
                    <VisibilityOffIcon
                      // sx={{ color: "#757373" }}
                      className="text-[#757373] absolute right-[12px] top-[35px] cursor-pointer"
                      onClick={togglePasswordIcon}
                    />
                  )}
                </div>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default RestaurantLoginDetails;
