import { Box } from "@mui/material";
import MUIDataTable from "mui-datatables";
import {PageLoader} from "../loader/index"



function CustomTable({
  title,
  columns,
  data,
  customOption,
  components,
  paginationServer,
  page,
  totalCount,
  countPerPage,
  onChangePage,
  onChangeCountPerPage,
  pagination = false,
  sort = false,
  isLoading,
  noDataText,
  maxHeight = "auto",
  onTableChange,
  sortOrder,
}) {
  const options = {
    selectableRows: "none", // Remove checkboxes
    download: false, // Remove download option from toolbar
    print: false, // Remove print option from toolbar
    search: false, // Remove search bar
    filter: false, // Remove column filters
    viewColumns: false, // Remove "View Columns" option from toolbar
    serverSide: paginationServer,
    page: page,
    count: totalCount,
    rowsPerPage: countPerPage,
    onChangePage: onChangePage,
    onChangeRowsPerPage: onChangeCountPerPage,
    pagination: pagination,
    rowsPerPageOptions: [10, 15, 20, 25, 30],
    sort: sort,
    tableBodyMaxHeight: maxHeight,
    sortOrder,
    onTableChange,
    responsive: 'standard',
    textLabels: {
      body: {
        noMatch: noDataText,
      },
    },
  };

  return (
    <Box className="">
      {isLoading ? (
        <PageLoader />
      ) : (
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={{ ...options, ...customOption }}
            components={components}
            className={"text-quicksand font-[500] text-[16px]"}
        />
      )}
    </Box>
  );
}



export default CustomTable;
