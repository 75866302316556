/* eslint-disable func-names */
const LocalStorageService = (function () {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  function setToken(tokenObj) {
    localStorage.setItem("access_token", tokenObj.access_token);
    localStorage.setItem("refresh_token", tokenObj.refresh_token);
  }

  function getAccessToken() {
    return localStorage.getItem("access_token");
  }
  function getCartID() {
    return localStorage.getItem("cartId");
  }
  function clearCartID() {
    return localStorage.removeItem("cartId");
  }
  function getOrderID() {
    return localStorage.getItem("orderID");
  }
  function clearOrderID() {
    return localStorage.removeItem("orderID");
  }

  function getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  function clearToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("cartId");
    localStorage.removeItem("orderID");
    localStorage.removeItem("user");
  }

  //  use access token and refresh token instead of this
  function setUser(data) {
    localStorage.setItem("user", JSON.stringify(data));
  }
  function setOrderID(data) {
    localStorage.setItem("orderID", JSON.stringify(data));
  }
  function setCartId(data) {
    localStorage.setItem("cartId", JSON.stringify(data));
  }

  //  use access token and refresh token instead of this
  function getUser() {
    const userObjectString = localStorage.getItem("user");
    if (userObjectString) {
      return JSON.parse(userObjectString);
    }

    return null;
  }

  return {
    getService,
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken,
    getUser,
    setUser,
    setCartId,
    getCartID,
    setOrderID,
    getOrderID,
    clearCartID,
    clearOrderID
  };
})();

export default LocalStorageService;
