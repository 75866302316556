import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "../../components/InputBox";
import TimePickerComponent from "../../components/TimePicker";
import { SearchableDropDown } from "../../components/SearchableDropdown";
import BasicButton from "../../components/Button";
import HeaderWrapper from "../../components/headerwrapper";
import dayjs from "dayjs";
import { useRestaurant, useSuperAdmin } from "../../utils/hooks";
import { PageLoader } from "../../components/loader";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { toast } from "react-toastify";
import MapComponent from "../../Map";
import { Grid } from "@mui/material";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

function RestaurantInformation() {
  const { useGetAllCuisine, OnAddPartner, addPartnerIsLoading, OnEditPartner } =
    useSuperAdmin();
  const { useGetPartnerInfo, autocomplete, onLoad, location, setLocation } =
    useRestaurant();

  const { data: cousine, isLoading: cousineIsLoading } = useGetAllCuisine();
  const { data: partnerInfo, isLoading: partnerInfoIsLoading } =
    useGetPartnerInfo();

  const { handleSubmit, control, reset, setValue } = useForm({
    mode: "all",
  });

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newLocation = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        setLocation(newLocation);
        const location = `https://www.google.com/maps?q=${newLocation?.latitude},${newLocation?.longitude}`;

        const LocationObj = {
          url: location,
          lat: newLocation?.latitude.toString(),
          long: newLocation?.longitude.toString(),
        };
        setValue("Location", LocationObj);
        setValue("Address", place?.formatted_address);
      }
    }
  };

  /**
   * useEffect hook that resets form fields with partner information when partnerInfo is updated.
   * @returns None
   */
  useEffect(() => {
    if (partnerInfo?.result?._id) {
      reset({
        ...partnerInfo?.result,
        dateFrom: dayjs(partnerInfo?.result?.workingHours?.from, "hh:mm a"),
        dateTo: dayjs(partnerInfo?.result?.workingHours?.to, "hh:mm a"),
        Address: partnerInfo?.result?.address,
        Location: partnerInfo?.result?.locationURL,
        Cuisine: partnerInfo?.result?.cuisineType.map((item) => ({
          value: item,
          label: item,
        })),
        Description: partnerInfo?.result?.description,
      });

      try {
        setLocation({
          latitude: parseFloat(partnerInfo?.result?.locationURL?.lat),
          longitude: parseFloat(partnerInfo?.result?.locationURL?.long),
        });
      } catch (err) {
        toast.error("Unknown Error");
      }
    }
  }, [partnerInfo]);

  /**
   * Handles form submission by either editing an existing partner if the partner ID exists,
   * or adding a new partner if the partner ID does not exist.
   * @param {{object}} data - The data to be submitted, typically form data.
   * @returns None
   */
  const onSubmit = (data) => {
    const object = { ...data };

    if (partnerInfo?.result?._id) {
      OnEditPartner({ ...object, id: partnerInfo?.result?._id });
    } else {
      OnAddPartner(data);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude && longitude) {
            const locationUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            const locationObj = {
              url: locationUrl,
              lat: latitude.toString(),
              long: longitude.toString(),
            };

            const apiKey = process.env.REACT_APP_MAP_API_KEY;
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

            fetch(geocodeUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.status === "OK") {
                  const address = data.results[0].formatted_address;
                  setValue("Location", locationObj);
                  setValue("Address", address);
                  setLocation({ latitude, longitude, address });
                } else {
                  toast.error("Unable to fetch address.");
                }
              })
              .catch((error) => {
                console.error("Error fetching address:", error);
                toast.error("Error fetching address.");
              });
          }
        },

        // setValue("Location", locationObj);
        // setLocation({ latitude, longitude });

        (error) => {
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "The request to get user location timed out.";
              break;
          }
          toast.error(errorMessage);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };
  const handleDragEvent = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    const location = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const LocationObj = {
      url: location,
      lat: latitude.toString(),
      long: longitude.toString(),
    };
    setValue("Location", LocationObj);
    setLocation({ latitude: latitude, longitude: longitude });
  };

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["places"],
  });

  return (
    <HeaderWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        {partnerInfoIsLoading ? (
          <PageLoader />
        ) : (
          <div
            className="overflow-auto"
            style={{
              height: "calc(100vh - 106px)",
            }}
          >
            <Grid container spacing={2} className="p-[24px]">
              <Grid item xs={10}>
                <h1 className="text-[24px] font-[600] font-semibold font-quicksand">
                  Restaurant information
                </h1>
              </Grid>
              <Grid item xs={12}>
                <div className="max-w-[472px]">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: "Restaurant Name is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          variant="outlined"
                          label="Name"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0.5} className="max-w-[472px]">
                  <Grid item xs={6}>
                    <Controller
                      name="dateFrom"
                      control={control}
                      rules={{
                        required: "From Working hours is Required",
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <TimePickerComponent
                            {...field}
                            label="Working Hours - From"
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="dateTo"
                      control={control}
                      rules={{
                        required: "To Working Hours is Required",
                      }}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <TimePickerComponent
                            {...field}
                            label="To"
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="max-w-[472px]">
                  <Controller
                    name="Address"
                    control={control}
                    rules={{
                      required: " Address is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <div className="flex w-full  gap-2">
                          <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            className="w-full"
                            restrictions={{
                              country: "AE",
                            }}
                          >
                            <TextInput
                              {...field}
                              variant="outlined"
                              label="Address"
                              // multiline
                              // rows={2}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          </Autocomplete>
                          <div
                            className="mt-8 text-genieRed"
                            onClick={() => {
                              getCurrentLocation();
                            }}
                          >
                            <FmdGoodIcon />
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="max-w-[472px] flex items-center gap-2">
                  <Controller
                    name="Location"
                    control={control}
                    rules={{
                      required: "Location is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <div className="flex w-full flex-col gap-2">
                          <MapComponent
                            location={location}
                            handleDragEvent={handleDragEvent}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="max-w-[472px]">
                  <Controller
                    name="Cuisine"
                    control={control}
                    rules={{
                      required: "Cuisine is Required",
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <SearchableDropDown
                          {...field}
                          variant="outlined"
                          label="Type of Cuisine"
                          isLoading={cousineIsLoading}
                          options={cousine?.result.map((cuisine) => ({
                            value: cuisine.cuisine_type,
                            label: cuisine.cuisine_type,
                          }))}
                          isMulti
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="max-w-[472px]">
                  <Controller
                    name="Description"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          variant="outlined"
                          label="Description(optional)"
                          error={!!error}
                          multiline
                          rows={3}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="max-w-[472px]">
                  <Controller
                    name="facebookURL"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          variant="outlined"
                          label="Facebook(optional)"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="max-w-[472px]">
                  <Controller
                    name="instagramURL"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          variant="outlined"
                          label="Instagram(optional)"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="max-w-[472px]">
                  <Controller
                    name="youtubeURL"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextInput
                          {...field}
                          variant="outlined"
                          label="Youtube(optional)"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        {/* TODO:Loader instead of disabled */}
        <div className=" bg-white  w-full absolute border bottom-0 p-2 ">
          <div className="  flex justify-end    gap-2  ">
            <BasicButton
              color="primary"
              type="submit"
              sx={{ textTransform: "none", width: "236px", height: "36px" }}
              disabled={addPartnerIsLoading}
            >
              {partnerInfo?.result?._id ? "Update" : "Publish"}
            </BasicButton>
          </div>
        </div>
      </form>
    </HeaderWrapper>
  );
}

export default RestaurantInformation;
