import React from "react";
import { Navigate } from "react-router-dom";
import LocalStorageService from "../Constants/localStorage";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ children, roles }) => {
  const token = LocalStorageService.getAccessToken();

  // Check if token exists
  if (!token) {
    // Token is missing, redirect to login page
    return <Navigate to="/" />;
  }

  try {
    // Decode the token
    const decoded = jwtDecode(token);

    // Check if user's role is allowed
    if (!roles.includes(decoded?.userType)) {
      // Navigate to unauthorized route
      return <Navigate to="/unauthorized" />;
    }
  } catch (error) {
    console.error("Error decoding token:", error.message);
    // Handle token decoding errors gracefully
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
