import { Grid } from "@mui/material";
import React from "react";
import { useAuth } from "../../../utils/hooks";
import { PageLoader } from "../../../components/loader";
import RestaurantIcon from "@mui/icons-material/Restaurant";

function ServiceType() {
  const { useGetServiceType, selectedServiceType, setSelectedServiceType } =
    useAuth();

  const { data: serviceType, isLoading: serviceTypeIsLoading } =
    useGetServiceType();

  return (
    <Grid item container>
      <Grid item xs={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">Service Type</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">
          Select All Service type
        </h3>
      </Grid>

      <Grid
        item
        sx={{
          width: "100%",
          marginX: "1.25rem",

          overflowY: "scroll",
          marginTop: "15px",
          height: "calc(100vh - 250px)",
        }}
      >
        <Grid container direction={"column"} item spacing={2} xs={11}>
          <Grid container item spacing={2}>
            {serviceTypeIsLoading ? (
              <div>
                <PageLoader />
              </div>
            ) : (
              serviceType?.result?.map((itemsList, index) => (
                <Grid
                  item
                  key={index}
                  xs={4}
                  onClick={() => {
                    setSelectedServiceType((prevSelectedType) => {
                      if (!prevSelectedType.includes(itemsList?.service_type)) {
                        return [...prevSelectedType, itemsList?.service_type];
                      } else {
                        return prevSelectedType.filter(
                          (item) => item !== itemsList?.service_type
                        );
                      }
                    });
                  }}
                >
                  <div
                    className={`rounded ${
                      selectedServiceType?.includes(itemsList?.service_type)
                        ? "bg-red-50"
                        : "bg-white"
                    } border p-10 flex justify-center gap-2 hover:bg-red-50`}
                  >
                    <RestaurantIcon sx={{ color: "#ED1703" }} />
                    <h1>{itemsList?.service_type}</h1>
                  </div>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ServiceType;
