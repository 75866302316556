import React from 'react'
import Carousel from 'react-material-ui-carousel'

const CustomCarousel = ({ children, size = "large", autoPlay = true, animation = "slide" }) => {
    return (
        <Carousel
            autoPlay={autoPlay}
            animation={animation}
            navButtonsAlwaysInvisible
            IndicatorIcon={<div />}
            indicatorIconButtonProps={{
                style: {
                    backgroundColor: "#e8e8e8",
                    height: size === "small" ? "1.94px" : "4px",
                    width: size === "small" ? "5.82px" : "12px",
                    borderRadius: size === "small" ? "4.85px" : "10px",
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    backgroundColor: "#373737"
                }
            }}
            indicatorContainerProps={{
                style: {
                    padding: size === "small" ? "3.88px 5.82px" : "8px 12px",
                    background: "#fff",
                    width: "fit-content",
                    margin: "auto",
                    display: "flex",
                    gap: size === "small" ? "1.94px" : "4px",
                    borderRadius: size === "small" ? "19.41px" : "40px",
                    position: "absolute",
                    bottom: size === "small" ? "10px" : "20px",
                    left: size === "small" ? "calc(50% - 16.47px)" : "calc(50% - 34px)",
                    zIndex: 10
                }
            }}
        >
            {children}
        </Carousel>
    )
}

export default CustomCarousel