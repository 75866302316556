import React from "react";
import { BasicModal } from "../../../components/Modal/Modal";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useRestaurant } from "../../../utils/hooks";
import { TextInput } from "../../../components/InputBox";
import BasicButton from "../../../components/Button";

const AddSearchableDropdown = ({
  open,
  handleCancel,
  handleConfirmClick,
  list,
  headerText,
  handleDelete,
}) => {
  const { addListFormValue, setAddListFormValue, addList, setAddList } =
    useRestaurant();

  return (
    <BasicModal open={open} close={handleCancel}>
      <div className="flex flex-col gap-6 p-6 h-full">
        <p className="font-bold text-lg font-Quicksand">
          {`Add new ${headerText}`}
        </p>

        {list?.map((item) => (
          <div className="flex justify-between  border p-2 rounded">
            <div>{item?.label}</div>
            <div>
              <DeleteOutlineOutlinedIcon
                className="text-[#7F7F7F]"
                onClick={() => handleDelete(item?.value)}
              />
            </div>
          </div>
        ))}

        {!addList ? (
          <p
            className=" flex justify-end text-genieRed font-[700] text-[14px] mt-1 font-quicksand"
            onClick={() => setAddList(true)}
          >
            Create new category
          </p>
        ) : (
          <TextInput
            variant="outlined"
            label={"Type"}
            value={addListFormValue}
            onChange={(e) => setAddListFormValue(e.target.value)}
          />
        )}

        <div className="flex  items-center gap-2">
          <BasicButton
            onClick={handleCancel}
            variant="outlined"
            sx={{
              textTransform: "none",
              width: "236px",
              height: "36px",
            }}
          >
            Cancel
          </BasicButton>
          <BasicButton
            onClick={handleConfirmClick}
            sx={{
              textTransform: "none",
              width: "236px",
              height: "36px",
            }}
          >
            Confirm
          </BasicButton>
        </div>
      </div>
    </BasicModal>
  );
};

export default AddSearchableDropdown;
