import { Grid } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import BasicButton from "../../../components/Button";
import { useDiner } from "../../../utils/hooks";
import useToken from "../../../utils/hooks/useToken";
import { useNavigate, useParams } from "react-router";
import { PageLoader } from "../../../components/loader";
import DrawerComponent from "../../../components/Drawer/Index";
import AddNote from "./addnote";

import CreateIcon from "@mui/icons-material/Create";
let cartListChanges = [];
function RestaurantCart() {
  const {
    useGetCartItem,
    isDrawerNoteOpen,
    toggleNoteDrawer,
    cartList,
    setCartList,
    OnAddCart,
    addCartMenuIsLoading,
    placeOrder,
    cartListDiff,
    } = useDiner();

  const { getCartId } = useToken();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: cartData, isLoading: cartDataIsLoading } = useGetCartItem({
    cartID: getCartId(),
    partnerId: id,
  });

  /**
   * Calculates the total price of items in the shopping cart by reducing the cartList array.
   * @param {{Array<{quantity: number, oPrice: number, dPrice: number}>}} cartList - The list of items in the shopping cart.
   * @returns The total price of all items in the shopping cart.
   */
  const totalCartPrice = cartList?.reduce(
    (a, b) => a + b?.quantity * (b.oPrice - b.dPrice),
    0
  );

  const handleDiff = () => {
    for (let i = 0; i < cartListChanges?.length; i++) {
      if (cartListDiff[i]?.item_id === cartList[i]?.item_id) {
        if (cartListChanges[i]?.quantity - cartListDiff[i]?.quantity !== 0) {
          OnAddCart({
            itemId: cartList[i]?.item_id,
            count: cartListChanges[i]?.quantity - cartListDiff[i]?.quantity,
            partnerId: id,
          });
        }
      }
    }
  };

  /**
   * Increment the quantity of a specific item in the cart list by 1.
   * @param {{string}} itemId - The ID of the item to increment the quantity for.
   * @returns None
   */
  const increment = (itemId) => {
    const updatedApi = cartList.map((item) => {
      if (item.item_id === itemId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });

    setCartList(updatedApi);
    cartListChanges = updatedApi;
  };

  /**
   * Decrements the quantity of a specific item in the cart list by 1.
   * @param {{string}} itemId - The unique identifier of the item to decrement.
   * @returns None
   */
  const decrement = (itemId) => {
    const updatedApi = cartList?.map((item) => {
      if (item.item_id === itemId) {
        return { ...item, quantity: item.quantity - 1 };
      }

      return item;
    });
    cartListChanges = updatedApi;
    setCartList(updatedApi);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="border-b-[2px] p-3 border-gray-300  "
        >
          <h1 className="text-quicksand  font-[700] text-[16px]">
            Your basket
          </h1>

          <CloseIcon onClick={() => (handleDiff(), navigate(-1))} />
        </Grid>
        {/* {(cartList?.length >= 1) ? ( */}
          <>
            {cartDataIsLoading ? (
              <PageLoader />
            ) : (
              <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className=" overflow-auto"
                style={{
                  maxHeight: "calc(100dvh - 198px)",
                }}
              >
                {cartList?.map((cartItem) => {
                  return (
                    <Grid
                      container
                      gap={2}
                      item
                      xs={12}
                      key={cartItem?._id}
                      className="border-b-[2px]   overflow-auto"
                      sx={{ paddingX: "16px" }}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        justifyContent="space-between"
                        className="pt-3"
                      >
                        <h1 className="font-quicksand font-[600] text-[16px]  ">
                          {cartItem?.item_name}
                        </h1>
                        <h1 className=" font-quicksand font-[700] text-[16px]">
                          AED {cartItem?.oPrice - cartItem?.dPrice}
                        </h1>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="flex justify-between mb-5">
                          <button
                            onClick={() => decrement(cartItem?.item_id)}
                            className="p-3 bg-[#ED17031A] rounded-full text-black disabled:bg-[#ED170303]"
                            disabled={cartItem?.quantity === 0}
                          >
                            <RemoveIcon sx={{ color: "red" }} />
                          </button>
                          <span className="flex items-center font-quicksand font-[600] text-[28px]">
                            {cartItem?.quantity}
                          </span>
                          <button
                            onClick={() => increment(cartItem?.item_id)}
                            className="p-3 bg-[#ED1703] rounded-full text-white disabled:bg-[#ED17031A]"
                            // disabled={addCartMenuIsLoading}
                          >
                            <AddIcon
                              sx={{
                                color: addCartMenuIsLoading ? "red" : "inherit",
                              }}
                            />
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              direction="column"
              className="px-5 pt-5 absolute bottom-[20px] border-t-[2px] bg-white  "
            >
              <div className="flex justify-between ">
                <div>
                  <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                    Total
                  </h1>
                  <h3 className="font-quicksand font-[500] text-[12px] text-[#777777]">
                    ({cartList?.length}{" "}
                    {cartList?.length > 1 ? "items" : "item"})
                  </h3>
                </div>
                <h1 className="font-quicksand font-[700] text-[16px] text-[#252525]">
                  AED {parseFloat(totalCartPrice)?.toFixed(2)}
                </h1>
              </div>
              <div className="flex justify-between  items-center  pt-2">
                <div className="flex flex-col">
                  <div className="text-quicksand font-[600] text-[14px] max-w-[30vw] overflow-hidden ">
                    {cartData?.result?.notes}
                  </div>
                  <div
                    className="text-genieRed flex items-center "
                    onClick={toggleNoteDrawer}
                  >
                    {cartData?.result?.notes ? (
                      <CreateIcon sx={{ color: "#ED1703" }} />
                    ) : (
                      <NoteOutlinedIcon sx={{ color: "#ED1703" }} />
                    )}
                    <span className="ml-2 font-quicksand font-[600] text-[14px] text-genieRed">
                      {cartData?.result?.notes ? "Edit a note" : " Add a note"}
                    </span>
                  </div>
                </div>

                <BasicButton
                  color="primary"
                  type="submit"
                  // width: "236px", height: "36px"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    handleDiff();
                    placeOrder(id);
                  }}
                >
                  Place your order
                </BasicButton>
              </div>
            </Grid>
          </>
        {/* ) : ( */}
          {/* <EmptyBasket navigate={navigate} /> */}
        {/* )} */}
      </Grid>

      <DrawerComponent
        position="bottom"
        open={isDrawerNoteOpen}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
          },
        }}
        onClose={toggleNoteDrawer}
      >
        <AddNote />
      </DrawerComponent>
    </>
  );
}

export default RestaurantCart;
