import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";
import TimePickerComponent from "../../../components/TimePicker";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { useAuth, useRestaurant } from "../../../utils/hooks";
import { TextInput } from "../../../components/InputBox";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import MapComponent from "../../../Map";
import { toast } from "react-toastify";

function LocationDetails({ control, setValue }) {
  const { onLoad, autocomplete } = useRestaurant();
  const { location, setLocation } = useAuth();

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newLocation = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        setLocation(newLocation);
        const location = `https://www.google.com/maps?q=${newLocation?.latitude},${newLocation?.longitude}`;

        const LocationObj = {
          url: location,
          lat: newLocation?.latitude.toString(),
          long: newLocation?.longitude.toString(),
        };
        setValue("Location", LocationObj);
        setValue("Address", place?.formatted_address);
      }
    }
  };
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude && longitude) {
            const locationUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            const locationObj = {
              url: locationUrl,
              lat: latitude.toString(),
              long: longitude.toString(),
            };

            const apiKey = process.env.REACT_APP_MAP_API_KEY;
            const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

            fetch(geocodeUrl)
              .then((response) => response.json())
              .then((data) => {
                if (data.status === "OK") {
                  const address = data.results[0].formatted_address;
                  setValue("Location", locationObj);
                  setValue("Address", address);
                  setLocation({ latitude, longitude, address });
                } else {
                  toast.error("Unable to fetch address.");
                }
              })
              .catch((error) => {
                console.error("Error fetching address:", error);
                toast.error("Error fetching address.");
              });
          }
        },

        (error) => {
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "The request to get user location timed out.";
              break;
          }
          toast.error(errorMessage);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };
  const handleDragEvent = (event) => {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();
    const location = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const LocationObj = {
      url: location,
      lat: latitude.toString(),
      long: longitude.toString(),
    };
    setValue("Location", LocationObj);
    setLocation({ latitude: latitude, longitude: longitude });
  };

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["places"],
  });

  return (
    <Grid item container>
      <Grid item sx={12}>
        <h1 className=" text-3xl mt-[20px]  mx-5">Location Details</h1>
        <h3 className="text-sm font-medium mt-4 mx-5">Select your location</h3>
      </Grid>

      <Grid
        item
        sx={{
          width: "100%",
          marginX: "1.25rem",
          overflowY: "scroll",
          marginTop: "10px",
          height: "calc(100vh - 250px)",
        }}
      >
        <Grid container direction={"column"} item spacing={2} xs={11}>
          <Grid item>
            <div className="w-3/4">
              <Controller
                name="Address"
                control={control}
                rules={{
                  required: " Address is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="flex w-full gap-2">
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        className="w-full"
                        // restrictions={{
                        //   country: "AE",
                        // }}
                      >
                        <TextInput
                          {...field}
                          variant="outlined"
                          label="Address"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      </Autocomplete>
                      <div
                        className="mt-8 text-genieRed"
                        onClick={() => {
                          getCurrentLocation();
                        }}
                      >
                        <FmdGoodIcon />
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <div className="w-3/4 flex items-center gap-2">
              <Controller
                name="Location"
                control={control}
                rules={{
                  required: "Location is Required",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="flex w-full flex-col gap-2">
                      <MapComponent
                        location={location}
                        handleDragEvent={handleDragEvent}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LocationDetails;
