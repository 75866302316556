import { Box, Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import BasicButton from "../../../components/Button";
import { useAuth } from "../../../utils/hooks";
import { useFieldArray, useForm } from "react-hook-form";
import RestaurantLoginDetails from "./RestaurantLoginDetails";
import RestaurantDetails from "./RestaurantDetails";
import CousineDetails from "./CousineDetails";
import LocationDetails from "./LocationDetails";
import SocialMediaDetails from "./SocialMediaDetails";
import { PageLoader } from "../../../components/loader";
import { toast } from "react-toastify";
import TimingsDetails from "./TimingDeatils";
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg"
import NewGenie from "../../../assets/Icons/NewGenie.svg"
import CustomProgress from "../../../components/CustomProgress";

function OnBoarding() {
  const {
    onPartnerSignUp,
    nextPage,
    PageNumber,
    onEmailCheckUp,
    isEmailCheckLoading,
    selectedCousine,
    previousPage,
    selectedServiceType,
    // selectedType,
    onFileUploadUp,
    handleKeyDown,
    // steps,
  } = useAuth();

  const headerContainer = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const { handleSubmit, control, setValue, watch } = useForm({
    mode: "all",
    defaultValues: useMemo(
      () => ({
        email: "",
        phone: "",
        password: "",
        cpassword: "",
        name: "",

        Address: "",
        Location: {},
        Timing: [
          {
            day: "Monday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Tuesday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Wednesday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Thursday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Friday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Saturday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
          {
            day: "Sunday",
            schedule: [{ fromTime: "", toTime: "" }],
            isClosed: false,
          },
        ],
      }),
      []
    ),
  });

  const { fields: timingList, update } = useFieldArray({
    control,
    name: "Timing",
  });

  const onSubmit = (data) => {
    if (PageNumber === 1) {
      onEmailCheckUp(data?.email);
    }

    if (PageNumber === 2) {
      // if (selectedType.length == 0) {
      //   toast.error("Please Select the Establishment Type ");
      //   return;
      // } else {
      onFileUploadUp(data);
      nextPage();
      // }
    }

    if (PageNumber === 3) {
      selectedCousine.length > 0
        ? nextPage()
        : toast.error("Please Select the Cousine");
    }

    if (PageNumber === 4) {
      nextPage();
    }
    if (PageNumber === 5) {
      nextPage();
    }
    if (PageNumber === 6) {
      if (selectedServiceType.length === 0) {
        toast.error("Please Select the Service Type ");
        return;
      } else {
        onPartnerSignUp(data);
        // nextPage();
      }
    }
    // if (PageNumber === 8) {
    //   onPartnerSignUp(data);
    // }

    // if (PageNumber === 7) {
    //   if (selectedServiceType.length === 0) {
    //     toast.error("Please Select the Service Type ");
    //     return;
    //   } else {
    //     // onPartnerSignUp(data);
    //     nextPage();
    //   }
    // }
  };

  const handleAppendSchedule = (index) => {
    const newTimingList = [...timingList];

    newTimingList[index].schedule.push({ fromTime: "", toTime: "" });
    update(index, newTimingList[index]);
    // setValue("Timing", newTimingList);
  };

  const handleRemoveSchedule = (dayIndex, slotIndex) => {
    const currentSchedule = timingList[dayIndex].schedule;

    if (currentSchedule.length > 1) {
      const newSchedule = currentSchedule.filter((_, index) => index !== slotIndex);

      update(dayIndex, {
        ...timingList[dayIndex],
        schedule: newSchedule,
      });
    }
  };

  // const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  //   color: "grey", // Default color for inactive steps
  //   fontSize: "1.5rem", // Increase font size for labels
  //   "& .MuiStepIcon-root": {
  //     color: "grey", // Default color for inactive icons
  //     fontSize: "2rem", // Increase size for icons
  //     "&.Mui-active": {
  //       color: "black", // Color for active icons
  //     },
  //     "&.Mui-completed": {
  //       color: "#fff", // Color for completed icons
  //     },
  //   },
  //   "& .MuiStepLabel-label": {
  //     color: "#C6C3C2 !important", // Default color for inactive labels
  //     fontSize: "1.2rem", // Increase font size for labels
  //     "&.Mui-active": {
  //       color: "#000 !important", // Color for active labels
  //     },
  //     "&.Mui-completed": {
  //       color: "#fff !important", // Color for completed labels
  //     },
  //   },
  // }));

  const labels = [
    "Login Details",
    "Restaurant Details",
    "Cuisine  Details",
    "Location  Details",
    "Timing  Details",
    "Services & Social Media "
  ]

  useEffect(() => {
    const updateHeaderHeight = () => {
      const headerHeight = headerContainer.current?.offsetHeight || 0
      setHeaderHeight(headerHeight)
    }

    updateHeaderHeight()

    window.addEventListener('resize', updateHeaderHeight)

    return () => {
      window.removeEventListener('resize', updateHeaderHeight)
    }
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <Grid container xs={12}>
          <Grid item xs={12} sm={3} sx={{ height: { xs: "fit-content", sm: "100vh" } }}>
            <Grid
              container
              direction="column"
              // justifyContent="space-between"
              sx={{ background: "#B80E0C", height: { xs: "fit-content", sm: "100vh" }, width: "100%", borderRadius: { xs: "0px", sm: "0px 20px 20px 0px" } }}
            >
              {/* <img src={logo} alt="Main Logo" className=" w-1/2 mt-6 " /> */}
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: { xs: "start", sm: "center" },
                    maxWidth: "226px",
                    margin: { sm: "auto" },
                    padding: { xs: "12px 20px", sm: "60px 10px" }
                    // justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <img className="w-[225px] self-end" src={NewGenie} alt="" />
                  </Box>
                  <Box sx={{ height: { xs: "36px", sm: "74.56px" } }}>
                    <img className="h-full" src={Isolation_Mode} alt="" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={9} sx={{ padding: "0px 20px" }}>
            {/* <Button onClick={() => { console.log(getValues()) }} sx={{ position: "absolute" }}>Check Data</Button> */}
            <Grid container direction="column">
              <Grid
                item
                container
                ref={headerContainer}
                sx={{paddingBottom: "2px"}}
              >
                {/* <div className="w-full mb-[38px] flex justify-between items-center mt-[5vh] gap-2"> */}
                <Box sx={{ width: "100%", margin: { xs: "20px 0 18px 0", sm: "38px 0 36px 0" }, display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}>
                  <div className="font-medium text-[32px] leading-[35px] text-[#3E3E3E] flex-1">{labels[PageNumber - 1]}</div>
                  <Box sx={{ flexDirection: { xs: "column", sm: "row" }, gap: { xs: "10px", sm: "20px" } }} className="w-full flex flex-1 items-center justify-end">
                    {PageNumber !== 1 && (
                      <BasicButton
                        variant="outlined"
                        color="genieRed"
                        sx={{
                          textTransform: "none",
                          maxWidth: "250px",
                          width: "100%",
                          height: "40px",
                          fontWeight: "500"
                        }}
                        onClick={() => previousPage()}
                      >
                        <span className="font-poppins font-medium">
                          Previous
                        </span>
                      </BasicButton>
                    )}
                    <BasicButton
                      color="genieRed"
                      type="submit"
                      sx={{
                        textTransform: "none",
                        maxWidth: "250px",
                        width: "100%",
                        height: "40px",
                        color: "#fff",
                        fontWeight: "500"
                      }}
                      disabled={isEmailCheckLoading}
                    >
                      <span className="font-medium font-poppins">
                        {isEmailCheckLoading ? (
                          <PageLoader />
                        ) : PageNumber === 6 ? (
                          "Finish"
                        ) : (
                          "Next"
                        )}
                      </span>
                    </BasicButton>
                  </Box>
                </Box>
                {/* </div> */}
                <CustomProgress count={6} current={PageNumber} />
              </Grid>

              {/* <div className="w-full overflow-y-scroll h-[calc(100vh-116.26px)] py-[6vh]"> */}
              <Box sx={{ overflowY: "auto", height: { xs: `calc(100vh - ${headerHeight + 60}px)`, sm: `calc(100vh - ${headerHeight}px)` }, padding: "40px 0" }}>
                {PageNumber === 1 && (
                  <RestaurantLoginDetails control={control} watch={watch} />
                )}

                {PageNumber === 2 && <RestaurantDetails control={control} />}

                {PageNumber === 3 && <CousineDetails />}
                {PageNumber === 4 && (
                  <LocationDetails control={control} setValue={setValue} />
                )}
                {PageNumber === 5 && (
                  <TimingsDetails
                    watch={watch}
                    control={control}
                    handleAppendSchedule={handleAppendSchedule}
                    timingList={timingList}
                    handleRemoveSchedule={handleRemoveSchedule}
                  />
                )}
                {PageNumber === 6 && <SocialMediaDetails control={control} />}

                {/* {PageNumber === 7 && <ServiceType />}
                {PageNumber === 8 && <CustomLinkDetails control={control} />} */}
              </Box>
              {/* </div> */}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default OnBoarding;
