import React, { useState, useRef, useEffect } from "react";
import {
  useController,
  Control,
  FieldValues,
  FieldPath,
} from "react-hook-form";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropDown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface Option {
  value: string;
  label: string;
}

interface MultiSelectDropdownProps<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  options: Option[];
  placeholder?: string;
  width?: string;
}

function MultiSelectDropdown<T extends FieldValues>({
  name,
  control,
  options,
  placeholder = "Select Cuisines",
  width = "w-1/2"
}: MultiSelectDropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleItemClick = (clickedOption: Option) => {
    const newValue = Array.isArray(value)
      ? value.some((item: Option) => item.value === clickedOption.value)
        ? value.filter((item: Option) => item.value !== clickedOption.value)
        : [...value, clickedOption]
      : [clickedOption];
    onChange(newValue);
  };

  const removeItem = (itemToRemove: Option) => {
    onChange(value.filter((item: Option) => item.value !== itemToRemove.value));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${width} relative`} ref={dropdownRef}>
      <div
        className={`border  border-[#e2e8f0] border-solid rounded-md p-2 flex justify-between items-center cursor-pointer bg-[#F7F7F7] ${error ? "border-red-500" : "border-gray-300"
          }`}
        onClick={toggleDropdown}
      >
        <span className="text-gray-700">
          {value && value.length > 0 ? `${value.length} selected` : placeholder}
        </span>
        <ArrowDropUpIcon
          className={`transition-transform duration-300 ${isOpen ? "rotate-180" : ""
            }`}
        />
      </div>

      {isOpen && (
        <div className={`absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto`}>
          {options.length === 0 && <div className="p-2 text-center ">No Options</div>}
          {options.map((option) => (
            <div
              key={option.value}
              className="flex items-center p-2 bg-[#F7F7F7] hover:bg-gray-100 cursor-pointer"
              onClick={() => handleItemClick(option)}
            >
              <input
                type="checkbox"
                checked={
                  value &&
                  value.some((item: Option) => item.value === option.value)
                }
                onChange={() => { }}
                className="mr-2 bg-lightGrey"
              />
              {option.label}
            </div>
          ))}
        </div>
      )}

      <div className="flex flex-wrap mt-2">
        {value &&
          value.map((item: Option) => (
            <div
              key={item.value}
              className="bg-gray-200 rounded px-4 py-2 m-1 flex items-center text-sm"
            >
              {item.label}
              <HighlightOffIcon
                className="ml-2 cursor-pointer text-gray-600 hover:text-gray-800"
                sx={{ fontSize: 16 }}
                onClick={() => removeItem(item)}
              />
            </div>
          ))}
      </div>
      {error && <p className="mt-1 text-xs text-red-500">{error.message}</p>}
    </div>
  );
}

export default MultiSelectDropdown;