import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAuth, useRestaurant } from "../../utils/hooks";
import { FormHelperText, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
function DragAndDropCoverPic({
  onChange,
  label,
  displayPicture,
  error,
  helperText,
  handleRegenerate,
  like = true,
  Dislike = true,
  generate = true,
  upload = false,
  s3URl,
}) {
  const { file, setFile,openEditModel, setOpenEditModel } = useAuth();

  const [removedDisplayPicture, setRemovedDisplayPicture] = useState(false);
  const { gptCheck } = useRestaurant();

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".png, .jpg",
    maxSize: 5242880,
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      onChange(acceptedFiles[0]);
      setRemovedDisplayPicture(true);
    },
    onDropRejected: (err) => {
      if (err[0].errors[0].code === "file-invalid-type") {
        alert(
          "Could not read the selected file. Please upload only JPG or PNG format file!"
        );
      }
      if (err[0].errors[0].code === "file-too-large") {
        alert("File should not exceed more than 5mb!");
      }
    },
  });

  const handleDelete = () => {
    setFile([]);
    setRemovedDisplayPicture(true);
    onChange("");
  };

  const showUploadPic = () => {
    if (removedDisplayPicture) {
      return !file[0];
    }
    if (displayPicture) {
      // displayPicture = {
      //   ...displayPicture,
      //   url: URL.createObjectURL(displayPicture),
      // };
      return false;
    }
    return !displayPicture;
  };

  return (
    <div className="flex flex-col ">
      {label && <p className="text-secondary-dark mb-9.5">{label}</p>}

      {showUploadPic() ? (
        <div
          {...getRootProps({
            className:
              "dropzone w-262 h-47 rounded-2.5 bg-secondary-light p-18 cursor-pointer",
          })}
          className={`border ${
            error ? "border-error-main" : "border-darkGray"
          } p-10 border-dashed border-2  w-full border-grey-500 rounded-lg`}
        >
          <p>
            Drag & drop your image or{" "}
            <span className="text-genieRed">Browse your files</span>
          </p>
          {/* <div className="flex justify-center">
            <p className="text-subtitle2 bottomText">
              Image size: 900px (h) x 400px (w)
            </p>
          </div> */}
          <input {...getInputProps()} />
        </div>
      ) : (
        // <div className="flex flex-col ">
        //   <div className="w-262 h-47 cursor-pointer mb-2">
        //     <img
        //       alt="profile pic"
        //       className="w-[124px] h-[124px] rounded-5"
        //       src={file[0]?.preview || displayPicture}
        //       style={{ objectFit: "cover" }}
        //     />
        //     {!s3URl && <h1 className="text-genieRed mt-2"> </h1>}
        //   </div>
        //   <div className="flex mb-5">
        //     {like && (
        //       <div
        //         // onClick={handleDelete}
        //         className="avatarOptions cursor-pointer text-genieRed m-4"
        //       >
        //         <p className="text-button delete">Like</p>
        //       </div>
        //     )}
        //     {Dislike && (
        //       <div
        //         // onClick={handleDelete}
        //         className="avatarOptions cursor-pointer text-genieRed m-4"
        //       >
        //         <p className="text-button delete">Dislike</p>
        //       </div>
        //     )}
        //     {/* {upload && !s3URl && (
        //       <div
        //         onClick={() => uploadFunction(file[0])}
        //         className="avatarOptions cursor-pointer text-genieRed m-4"
        //       >
        //         <p className="text-button delete">Upload</p>
        //       </div>
        //     )} */}

        //     {generate && (
        //       <div
        //         onClick={handleRegenerate}
        //         className="avatarOptions cursor-pointer text-genieRed m-4"
        //       >
        //         <p className="text-button delete">
        //           {gptCheck ? "Regenerate" : "Generate"}
        //         </p>
        //       </div>
        //     )}

        //     <div
        //       onClick={handleDelete}
        //       className="avatarOptions cursor-pointer text-genieRed m-4"
        //     >
        //       <p className="text-button delete">Delete</p>
        //     </div>
        //   </div>
        // </div>
        <div
          className={`border   relative flex justify-between${
            error ? "border-error-main" : "border-darkGray"
          } p-2 border-dashed border-2  w-full border-grey-500 rounded-lg`}
        >
          <div className="relative">
            <img
              alt="profile pic"
              className=" rounded-10 w-42  h-42"
              src={file[0]?.preview || displayPicture}
              style={{ objectFit: "cover" }}
            />
            <div className="absolute -bottom-3  right-0  w-fit p-[.35rem] rounded-full bg-gray-800 hover:bg-gray-700 border border-gray-600">
                <EditIcon sx={{ color: "#fff" }} onClick={ ()=>{setOpenEditModel(!openEditModel)}} />
            </div>
          </div>
          <div className="absolute right-3">
            <CloseIcon sx={{ color: "#ED1703" }} onClick={handleDelete} />
          </div>
        </div>
      )}
      {error && (
        <FormHelperText className="text-xs   leading-5 text-[#ED1703]">
          <Typography variant="body2" sx={{ textWrap: "wrap", color: "Red" }}>
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </div>
  );
}

export default DragAndDropCoverPic;
