import { useState } from "react";
import axios from "axios";
import LocalStorageService from "../../Constants/localStorage";

const LocalStorage = LocalStorageService.getService();

export default function useToken() {
  const getToken = () => {
    const tokenString = LocalStorage.getAccessToken();
    return tokenString;
  };
  const getCartId = () => {
    const CartId = LocalStorage.getCartID();
    return JSON.parse(CartId);
  };
  const getOrderId = () => {
    const OrderId = LocalStorage.getOrderID();
    return JSON.parse(OrderId);
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    setToken(userToken);
    LocalStorage.setToken(userToken);
  };
  const saveUser = (user) => {
    LocalStorage.setUser(user);
  };
  const saveOrderID = (order) => {
    LocalStorage.setOrderID(order);
  };
  const saveCartID = (user) => {
    LocalStorage.setCartId(user);
  };

  const getUserDetails = () => {
    return LocalStorage.getUser();
  };
  const RemoveCartID = () => {
    return LocalStorage.clearCartID();
  };
  const RemoveOrderID = () => {
    return LocalStorage.clearOrderID();
  };

  const removeToken = () => {
    LocalStorage.clearToken();
    setToken(null);
    axios.defaults.headers.common.Authorization = "";
  };

  return {
    setToken: saveToken,
    removeToken,
    token,
    getToken,
    saveUser,
    getUserDetails,
    getCartId,
    saveCartID,
    saveOrderID,
    getOrderId,
    RemoveCartID,
    RemoveOrderID
  };
}
