import { Accordion, AccordionDetails, AccordionSummary, Badge, Grid } from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import BasicButton from "../../../components/Button";
import { useDiner, useRestaurant } from "../../../utils/hooks";
import useToken from "../../../utils/hooks/useToken";
import { useNavigate, useParams } from "react-router";
import DrawerComponent from "../../../components/Drawer/Index";
import AddNote from "./addnote";
import Isolation_Mode from "../../../assets/images/Isolation_Mode.svg";
import cart from "../../../assets/Icons/Cart.svg";
import backArrow from "../../../assets/Icons/backArrow.svg";
import spiceIcon from "../../../assets/Icons/spiceIcon.png";
import broccoli from "../../../assets/Icons/broccoli.png";
import customization from "../../../assets/Icons/customization.svg";
import Counter from "../../../components/Counter";
import DetailsDropdown from "../../../components/DetailsDropdown";
import { Star } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import emptyCart from "../../../assets/images/emptyCart.png";
import cancel from "../../../assets/Icons/cancel.svg"

let cartListChanges = [];
function RestaurantCart() {
  const {
    useGetCartItem,
    isDrawerNoteOpen,
    toggleNoteDrawer,
    cartList,
    setCartList,
    OnAddCart,
    // addCartMenuIsLoading,
    placeOrder,
    cartListDiff,
    useGetCartCount
  } = useDiner();

  const { useGetMenuById } = useRestaurant()

  const { getCartId } = useToken();
  const { id } = useParams();
  const navigate = useNavigate();
  const restaurantId = useParams();
  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");

  const { isLoading: cartDataIsLoading } = useGetCartItem({
    cartID: getCartId(),
    partnerId: id,
  });

  /**
   * Calculates the total price of items in the shopping cart by reducing the cartList array.
   * @param {{Array<{quantity: number, oPrice: number, dPrice: number}>}} cartList - The list of items in the shopping cart.
   * @returns The total price of all items in the shopping cart.
   */
  const totalCartPrice = cartList?.reduce(
    (a, b) => a + b?.quantity * (b.oPrice - b.dPrice),
    0
  );

  const handleDiff = () => {
    for (let i = 0; i < cartListChanges?.length; i++) {
      if (cartListDiff[i]?.item_id === cartList[i]?.item_id) {
        if (cartListChanges[i]?.quantity - cartListDiff[i]?.quantity !== 0) {
          OnAddCart({
            itemId: cartList[i]?.item_id,
            count: cartListChanges[i]?.quantity - cartListDiff[i]?.quantity,
            partnerId: id,
            tableSequence: tableId,
          });
        }
      }
    }
  };

  /**
   * Increment the quantity of a specific item in the cart list by 1.
   * @param {{string}} itemId - The ID of the item to increment the quantity for.
   * @returns None
   */
  const increment = (itemId) => {
    const updatedApi = cartList.map((item) => {
      if (item.item_id === itemId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });

    setCartList(updatedApi);
    cartListChanges = updatedApi;
  };

  /**
   * Decrements the quantity of a specific item in the cart list by 1.
   * @param {{string}} itemId - The unique identifier of the item to decrement.
   * @returns None
   */
  const decrement = (itemId) => {
    const updatedApi = cartList?.map((item) => {
      if (item.item_id === itemId) {
        return { ...item, quantity: item.quantity - 1 };
      }

      return item;
    });
    cartListChanges = updatedApi;
    setCartList(updatedApi);
  };

  const { data: cartCount } = useGetCartCount({
    partnerId: restaurantId?.id,
    cartId: getCartId(),
  });

  const { data: menuItem } = useGetMenuById("66e9fa97d97965a7068d839c")
  useEffect(() => {
    console.log(menuItem);
  }, [menuItem]);

  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#b80e0c]  flex justify-between items-end  px-[1.25rem] py-[0.75rem]"
        >
          <img
            src={Isolation_Mode}
            alt=""
            srcSet=""
            className=""
            height={"36px"}
          // width={"166px"}
          />
          {/* TODO: Add cart count functionality */}
          <Badge badgeContent={cartCount?.result?.cartItemCount}
            // onClick={() => navigate("*")}
            sx={{ cursor: "pointer", "& .MuiBadge-badge": { backgroundColor: "white", color: "#B80E0C", fontWeight: 600, position: "absolute", top: "3px", right: "4px" } }}>
            <img src={cart} alt="cart" />
          </Badge>
        </Grid>

        <Grid
          container
          item
          xs={12}
          direction="row"
          gap="12px"
          alignItems="center"
          className="p-4"
        >
          <img className="h-[20px] w-[20px] cursor-pointer" src={backArrow} alt=""
            onClick={() => {
              handleDiff()
              navigate(-1)
            }}
          />
          <h1 className="  font-[500] text-[14px]">
            My Cart
          </h1>
        </Grid>

        {/* {(cartList?.length >= 1) ? ( */}
        <>
          {cartDataIsLoading ? (
            <div className="flex flex-col justify-center items-center h-[calc(100vh-232.17px)]">
              <div className="h-14 w-14 border-4 border-gray-300 border-t-red-600 rounded-full animate-spin mb-4" />
              <p className="text-gray-600">
                Loading...
              </p>
            </div>
          ) : (
            <>
              {cartList && cartList.length > 0 ?
                <Grid
                  container
                  item
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  className=" overflow-auto"
                  style={{
                    maxHeight: "calc(100vh - 119.17px)",
                    height: "fit-content",
                    padding: "0 16px",
                    paddingBottom: "119.17px",
                    gap: "12px"
                  }}
                >
                  {/* {cartList?.map((cartItem) => {
                    return (
                      <Grid
                        container
                        gap={2}
                        item
                        xs={12}
                        key={cartItem?._id}
                        className={`${cartItem === cartList[cartList.length - 1] ? '' : 'border-b-[1px] border-[#F3F0F3]'} pb-[10px] overflow-auto`}
                        justifyContent={"space-between"}
                      >
                        <div>
                          <div className="mb-[18px]">
                            <div className="flex items-center max-h-3">
                              <div className="mr-1">
                                {[...Array(2)].map((_, index) => (
                                  <img
                                    key={index}
                                    src={spiceIcon}
                                    alt="Spice"
                                    className="w-3 h-3 inline-block"
                                  />
                                ))}
                              </div>
                              <div>
                                {[...Array(1)].map((_, index) => (
                                  <img
                                    key={index}
                                    src={broccoli}
                                    alt="Veg"
                                    className="w-3 h-3 inline-block"
                                  />
                                ))}
                              </div>
                            </div>
                            <h1 className="font-normal text-[18px]">
                              {cartItem?.item_name}
                            </h1>
                          </div>
                          <DetailsDropdown headerText="More Details"
                            // content={item?.ingredients?.reduce((acc, ingredient, index, array) => {
                            //   return acc + ingredient + (index === array.length - 1 ? "" : ", ");
                            // }, "")}
                            content="stuff"
                          // content={item?.description}
                          />
                          <div className={`flex items-center gap-[4px] mt-[8px] cursor-pointer`}
                          // onClick={() => handleClick(item)}
                          >
                            <img src={customization} alt="" />
                            <span className="text-[#b80e0c] underline">Customize</span>
                          </div>
                        </div>
                        <div>
                          <Counter
                            value={cartItem?.quantity}
                            increment={() => increment(cartItem?.item_id)}
                            decrement={() => decrement(cartItem?.item_id)}
                            className="mx-4 my-3"
                          />

                          <div className="flex justify-between items-center">
                            <div className="text-[#377931] flex items-center gap-[3px]">
                              <Star sx={{ height: "15px", width: "15px" }} />
                              <span className="font-normal">4.6</span>
                            </div>

                            <h1 className="font-semibold text-[18px]">
                              <span className="text-xs text-[#4d4d4d]">AED </span>
                              {(cartItem?.oPrice - cartItem?.dPrice).toFixed(2)}
                            </h1>
                          </div>
                        </div>
                      </Grid>
                    );
                  })} */}
                  <Accordion
                    expanded
                    sx={{ width: "100%", boxShadow: "none", border: "none", borderRadius: "0px" }}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ padding: 0, fontWeight: "500", fontSize: "16px", minHeight: "0px", margin: 0, '& .MuiAccordionSummary-content': { margin: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }, '&.Mui-expanded': { minHeight: '48px' } }}
                    >
                      <span className="text-[#000000]">ORDER #1</span>
                      <div className="flex items-center gap-1 text-[#B80E0C]"
                        onClick={(e) => {
                          e.stopPropagation()
                          console.log("clicked")
                        }}
                      >
                        <img className="h-[14px]" src={cancel} alt="" />
                        <span className="font-normal text-[14px]">Clear Order</span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0, gap: "12px", display: "flex", flexDirection: "column" }}>
                      {cartList?.map((cartItem) => {
                        return (
                          <Grid
                            container
                            gap={2}
                            item
                            xs={12}
                            key={cartItem?._id}
                            className={`${cartItem === cartList[cartList.length - 1] ? '' : 'border-b-[1px] border-[#F3F0F3]'} pb-[10px] overflow-auto`}
                            sx={{ flexWrap: "nowrap" }}
                            justifyContent={"space-between"}
                          >
                            <div>
                              <div className="mb-[18px]">
                                <div className="flex items-center max-h-3">
                                  <div className="mr-1">
                                    {[...Array(cartItem?.spicinessLevel)].map((_, index) => (
                                      <img
                                        key={index}
                                        src={spiceIcon}
                                        alt="Spice"
                                        className="w-3 h-3 inline-block"
                                      />
                                    ))}
                                  </div>
                                  <div>
                                    {[...Array(1)].map((_, index) => (
                                      <img
                                        key={index}
                                        src={broccoli}
                                        alt="Veg"
                                        className="w-3 h-3 inline-block"
                                      />
                                    ))}
                                  </div>
                                </div>
                                <h1 className="font-normal text-[18px]">
                                  {cartItem?.item_name}
                                </h1>
                              </div>
                              <DetailsDropdown headerText="More Details"
                                // content={item?.ingredients?.reduce((acc, ingredient, index, array) => {
                                //   return acc + ingredient + (index === array.length - 1 ? "" : ", ");
                                // }, "")}
                                content={cartItem?.description}
                              // content={item?.description}
                              />
                              <div className={`flex items-center gap-[4px] mt-[8px] cursor-pointer`}
                              // onClick={() => handleClick(item)}
                              >
                                <img src={customization} alt="" />
                                <span className="text-[#b80e0c] underline">Customize</span>
                              </div>
                            </div>
                            <div className="flex flex-col items-end">
                              <Counter
                                value={cartItem?.quantity}
                                increment={() => increment(cartItem?.item_id)}
                                decrement={() => decrement(cartItem?.item_id)}
                                className="mx-4 my-3"
                                leftButtonColor="red"
                              />

                              <div className="flex justify-between items-center gap-3 h-[20px] mt-[7px]">
                                <div className="text-[#377931] flex items-center gap-[3px]">
                                  <Star sx={{ height: "15px", width: "15px" }} />
                                  <span className="font-normal">4.6</span>
                                </div>

                                <h1 className="font-semibold text-[18px] text-nowrap">
                                  <span className="text-xs text-[#4d4d4d]">AED </span>
                                  {(cartItem?.oPrice - cartItem?.dPrice).toFixed(2)}
                                </h1>
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                :
                <div className="flex flex-col items-center justify-center h-[calc(100vh-180px)] flex-grow px-4">
                  <img className="max-w-[231px]" src={emptyCart} alt="" />
                  <p className="font-medium text-[18px] mb-[20px]">Your basket is empty</p>
                  <BasicButton
                    color="genieRed"
                    sx={{
                      textTransform: "none",
                      color: "#fff",
                      padding: "12px 16px",
                      fontWeight: "500",
                      width: "100%",
                    }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    <span className="font-medium flex items-center gap-[8px]">
                      <AddIcon height={"14px"} width={"14px"} />
                      <span className="leading-snug">Add Items</span>
                    </span>
                  </BasicButton>
                </div>
              }
            </>
          )}
          <Grid
            container
            item
            xs={12}
            direction="column"
            display={cartList?.length > 0 ? "flex" : "none"}
            className={`px-4 pt-2 pb-3 absolute bottom-0 border-t-[1px] bg-white border-[#d1d1d1] z-10`}
          >
            <div className="flex justify-between ">
              <div>
                <h1 className=" font-normal text-[16px] text-[#252525]">
                  Total
                </h1>
                <h3 className=" font-[500] text-[12px] text-[#777777]">
                  ({cartList?.length}{" "}
                  {cartList?.length > 1 ? "items" : "item"})
                </h3>
              </div>
              <h1 className="font-semibold text-[20px] text-[#252525]">
                <span className="text-[#4D4D4D] text-[14px]">AED </span>
                {parseFloat(totalCartPrice)?.toFixed(2)}
              </h1>
            </div>
            <div className="flex justify-between  items-center  pt-2">
              {/* <div className="flex flex-col">
                <div className="text-quicksand font-[600] text-[14px] max-w-[30vw] overflow-hidden ">
                  {cartData?.result?.notes}
                </div>
                <div
                  className="text-genieRed flex items-center "
                  onClick={toggleNoteDrawer}
                >
                  {cartData?.result?.notes ? (
                    <CreateIcon sx={{ color: "#ED1703" }} />
                  ) : (
                    <NoteOutlinedIcon sx={{ color: "#ED1703" }} />
                  )}
                  <span className="ml-2 font-[600] text-[14px] text-genieRed">
                    {cartData?.result?.notes ? "Edit a note" : " Add a note"}
                  </span>
                </div>
              </div> */}

              <BasicButton
                color="genieRed"
                type="submit"
                // width: "236px", height: "36px"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  padding: "12px 16px",
                  fontWeight: "500",
                  width: "100%"
                }}
                onClick={() => {
                  handleDiff();
                  placeOrder(id);
                }}
              >
                <span className="font-medium">
                  Place Order
                </span>
              </BasicButton>
            </div>
          </Grid>
        </>
        {/* ) : ( */}
        {/* <EmptyBasket navigate={navigate} /> */}
        {/* )} */}
      </Grid>

      <DrawerComponent
        position="bottom"
        open={isDrawerNoteOpen}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
          },
        }}
        onClose={toggleNoteDrawer}
      >
        <AddNote />
      </DrawerComponent>
    </>
  );
}

export default RestaurantCart;
