import { API } from "../../Constants/services";
import { getApi, postApi } from "../config/apis";

export const logIn = (data) => postApi(`${API.logIn}/signin`, data);
export const PartnerLogIn = (data) =>
  postApi(`${API.partnerLogIn}/signin`, data);
export const PartnerSignUp = (data) =>
  postApi(`${API.partnerLogIn}/signup`, data);
export const mutateRefreshToken = (data) =>
  postApi(`${API.logIn}/refresh`, data);
export const ResetPasswordAPI = (data) =>
  postApi(`${API.partnerLogIn}/forgot-password`, data);

export const CheckFileUpload = (data) =>
  postApi(`/upload`, data);

export const ChangePasswordAPI = (data) => {
  const { token, password } = data;
  return postApi(`${API.partnerLogIn}/reset-password/${token}`, {
    password: password,
  });
};

export const CheckEmailExistOrNot = (email) =>
  getApi(`/partner/auth/check-email?email=${email}`);

export const getAllEstablishmentType = () => getApi(`/admin/establishment`);
export const getAllServiceType = () => getApi(`/admin/service`);
