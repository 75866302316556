import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { FormHelperText, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

function TimePickerComponent({ value = dayjs(), onChange, helpertext, error, label,disabled }) {
  return (
    <DemoContainer
      components={["TimePicker", "MobileTimePicker", "DesktopTimePicker"]}
    >
      <DemoItem label={label}>
        <TimePicker
          value={value}
          onChange={onChange}
          disabled={disabled}
          slotProps={{
            textField: {
              helperText: helpertext,
              error: error,
            },
          }}
        />
        {error && helpertext && (
          <FormHelperText className="text-xs leading-5 text-red-600">
            <Typography variant="body2">{helpertext}</Typography>
          </FormHelperText>
        )}
      </DemoItem>
    </DemoContainer>
  );
}

export default TimePickerComponent;
