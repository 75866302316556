import { Grid } from "@mui/material";
import React, { useState } from "react";
import BasicButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import HeaderWrapper from "../../../components/headerwrapper";
import { TextInput } from "../../../components/InputBox";
import SearchIcon from "@mui/icons-material/Search";
import { useRestaurant, useSuperAdmin } from "../../../utils/hooks";
import CustomTabPanel from "../../../components/Tabs/CustomtabPanels";
import MenuComponent from "./MenuList";
import { PageLoader } from "../../../components/loader";
import NoDataComponent from "../../../components/NoDataComponent";
import DraggableTabs from "../../../components/Tabs/DragableComponent";

function MenuManagement() {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { useGetTabList, useGetTabListContent } = useRestaurant();
  const { tabValue } = useSuperAdmin();
  const { data: tabData } = useGetTabList();

  const { data: menuList } = useGetTabListContent(
    tabData?.result[tabValue]?._id
  );

  /**
   * Maps over the tabData result array to create a new array of objects with label and id properties.
   * The label is generated by combining the food category and count of each item.
   * @returns An array of objects with label and id properties.
   */
  const menuTabList = tabData?.result.map((item) => ({
    label: `${item.food_category} (${item?.count})`,
    _id: item?._id,
  }));
  return (
    <>
      <HeaderWrapper>
        <div
          className="overflow-auto"
          style={{
            // height:"100vh"
            height: "calc(100vh - 51px)",
          }}
        >
          <Grid container sx={12} spacing={2} className="p-[24px]">
            <Grid container xs={12} justifyContent="space-between">
              <Grid item>
                <h1 className=" ml-5 text-[24px] font-[600] font-semibold font-quicksand">
                  Menu
                </h1>
              </Grid>
              <Grid item>
                <BasicButton
                  color="primary"
                  sx={{
                    textTransform: "none",
                    width: "126px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                  onClick={() => navigate("create")}
                >
                  Create item
                </BasicButton>
              </Grid>
            </Grid>
            <Grid item xs={8} md={3}>
              <TextInput
                variant="outlined"
                placeholder={"Search"}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                // autoFocus={autoFocus}
                precontent={<SearchIcon sx={{ color: "#7F7F7F" }} />}
              />
            </Grid>

            <Grid item xs={12}>
              {!menuTabList ? (
                <PageLoader />
              ) : tabData?.result.length === 0 ? (
                <NoDataComponent tittle={"Add Menu to Restaurant "} />
              ) : (
                <DraggableTabs tabs={menuTabList || []}>
                  {(value) => {
                    return (
                      <>
                        <CustomTabPanel value={value} index={value}>
                          {menuList?.result.length === 0 ? (
                            <NoDataComponent tittle={"No Items Added"} />
                          ) : (
                            <MenuComponent list={menuList?.result} />
                          )}
                        </CustomTabPanel>
                      </>
                    );
                  }}
                </DraggableTabs>
              )}
            </Grid>
          </Grid>
        </div>
      </HeaderWrapper>
    </>
  );
}

export default MenuManagement;
