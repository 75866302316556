import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { TextInput } from "../../../components/InputBox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../../utils/hooks";

function RestaurantLoginDetails({ control, watch }) {
  const { toggleIcon, togglePasswordIcon } = useAuth();
  const password = watch("password");

  return (
    <>
      <Grid item container xs={5} spacing={2}>
        <Grid item xs={12}>
          <h1 className=" text-3xl mt-[20px]">Restaurant Details</h1>
          <h3 className="text-sm font-medium mt-4 ">
            Details about your Restaurant login Credentials
          </h3>
        </Grid>
        <Grid item xs={6.5}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <TextInput
                  {...field}
                  type="email"
                  variant="outlined"
                  label="Email *"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6.5}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone number is required",
              // pattern: {
              //   value: /^((\+971|00971|0)?)(5[024568]\d{7})$/,
              //   message: "Invalid Dubai phone number"
              // }
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <TextInput
                  {...field}
                  type="number"
                  variant="outlined"
                  label="Phone Number *"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6.5}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is Required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long",
              },
              pattern: {
                value:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#!])[A-Za-z\d@$!%*?&#!]{8,}$/,
                message:
                  "Must contain at least one uppercase,lowercase,number and  special character",
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <TextInput
                  {...field}
                  type={toggleIcon ? "Password" : "text"}
                  variant="outlined"
                  placeholder=""
                  label="Password *"
                  postcontent={
                    toggleIcon ? (
                      <RemoveRedEyeIcon
                        sx={{ color: "#1101A1FFF" }}
                        onClick={togglePasswordIcon}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ color: "#171A1FFF" }}
                        onClick={togglePasswordIcon}
                      />
                    )
                  }
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6.5}>
          <Controller
            name="cpassword"
            control={control}
            rules={{
              required: "Confirm Password is Required",
              validate: (value) =>
                value === password || "Passwords do not match",
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <TextInput
                  {...field}
                  type={toggleIcon ? "Password" : "text"}
                  variant="outlined"
                  placeholder=""
                  label="Confirm Password *"
                  postcontent={
                    toggleIcon ? (
                      <RemoveRedEyeIcon
                        sx={{ color: "#171A1FFF" }}
                        onClick={togglePasswordIcon}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ color: "#171A1FFF" }}
                        onClick={togglePasswordIcon}
                      />
                    )
                  }
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default RestaurantLoginDetails;
