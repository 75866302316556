const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';

const API_VERSION = 'v1';

const API = {
  // auth urls
  logIn: '/admin/auth',
  partnerLogIn:"/partner/auth"
 
};

export { API_SERVER_URL, API_VERSION, API };
