import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useAuth } from "../../../utils/hooks";
import DragAndDropCoverPic from "../../../components/Dropzone";
import { TextInput } from "../../../components/InputBox";
import { Controller } from "react-hook-form";
import { PageLoader } from "../../../components/loader";
import CheckIcon from "@mui/icons-material/Check";
import DragAndDropLogoPic from "../../../components/Dropzone/DropAndCrop";

function RestaurantDetails({ control }) {
  const {
    useGetEstablishmentType,
    selectedEstablishment,
    selectedType,
    setSelectedType,
    s3URl


  } = useAuth();

  const { isLoading: EstablishmentTypeIsLoading } = useGetEstablishmentType();

  return (
    <>
      <Grid item container>
        <Grid item xs={12}>
          <h1 className=" text-3xl mt-[20px]  ">Restaurant Details</h1>
          <h3 className="text-sm font-medium mt-4 ">
            Details about your Restaurant and Shares
          </h3>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            overflowY: "scroll",
            marginTop: "10px",
            height: "calc(100vh - 250px)",
          }}
        >
          <Grid item xs={11.5} lg={6.5} sx={{ marginTop: "10px" }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: "Restaurant Name  is Required",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <TextInput
                    variant="outlined"
                    label="Name *"
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={11.5} lg={6.5} sx={{ marginTop: "10px" }}>
            <label className=" font-quicksand font-[700]  block mb-2 text-[14px] text-[#252525]">
              Logo
            </label>

            <Controller
              name="logo"
              control={control}
              rules={{
                required: "Logo is Required",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <DragAndDropLogoPic
                    {...field}
                 
                    // displayPicture={field.value?.url || s3URl}
                    error={!!error}
                    helperText={error ? error.message : null}
                    //   handleRegenerate={handleRegenerate}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={11.5} lg={6.5} sx={{ marginTop: "15px" }}>
            <label className=" font-quicksand font-[700]  block mb-2 text-[14px] text-[#252525]">
              Establishment type
            </label>
            <Grid container item spacing={2}>
              {EstablishmentTypeIsLoading ? (
                <div className=" w-1/2 h-1/2  flex justify-center items-baseline">
                  <PageLoader />
                </div>
              ) : (
                selectedEstablishment?.map((itemsList, index) => (
                  <Grid
                    item
                    key={index}
                    xs={3}
                    onClick={() => {
                      setSelectedType((prevSelectedType) => {
                        if (!prevSelectedType.includes(itemsList?._id)) {
                          return [...prevSelectedType, itemsList?._id];
                        } else {
                          return prevSelectedType.filter(
                            (item) => item !== itemsList?._id
                          );
                        }
                      });
                    }}
                  >
                    <div
                      className={`rounded  hover:border-[#ED1703]  hover:border-2   ${
                        selectedType?.includes(itemsList?._id)
                          ? "bg-red-50 border-[#ED1703] border-2"
                          : "bg-white"
                      }  border p-5 flex justify-center gap-2 hover:bg-red-50`}
                    >
                      {/* {/* <itemsList.icons sx={{ color: "#ED1703" }} />  */}
                      {selectedType?.includes(itemsList?._id) && (
                        <CheckIcon sx={{ color: "#ED1703" }} />
                      )}

                      <h1>{itemsList.establishment_type}</h1>
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
    </>
  );
}

export default RestaurantDetails;
