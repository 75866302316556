import { Grid } from '@mui/material'
import React from 'react'
import BasicButton from '../../../../components/Button'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import cancel from "../../../../assets/Icons/cancel.svg";
import { TextInput } from '../../../../components/InputBox';
import add from "../../../../assets/Icons/add.svg";
import { useRestaurant } from '../../../../utils/hooks';
import ButtonWithIcon from '../../../../components/ButtonWithIcon';
import plus from '../../../../assets/Icons/plus.svg'
import closeIcon from "../../../../assets/Icons/close.svg"



const CategoryModal = ({ data, close, handleDelete, handleSubmit }) => {
    const { addListFormValue, setAddListFormValue, addList, setAddList } = useRestaurant();

    // console.log(data)
    const { control } = useForm({
        defaultValues: {
            category: data ? data : ""
        }
    })

    const { fields: categoryList, remove, append } = useFieldArray({
        control,
        name: "category",
    });

    return (
        <>
            <div className="flex justify-between items-center">
                <p className="font-semibold">Category</p>
                <img className="cursor-pointer" onClick={close} src={closeIcon} alt="" />
            </div>
            <Grid container xs={12} className='my-[18px]'>
                <Grid xs={4} sx={{ display: "flex"}}>
                    <p className="text-[#757373] basis-40 font-semibold mt-[10.3px]">ADD CATEGORY<span className="text-[#b80e0c]">*</span></p>
                </Grid>
                <Grid xs={8} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {categoryList.map(((item, index) => (
                        <div key={item.id}>
                            <Controller
                                name={`category[${index}].label`}
                                control={control}
                                render={({ field }) => (
                                    <div className='flex items-center gap-[10px] w-100'>
                                        <TextInput
                                            {...field}
                                            placeholder="Enter category"
                                            variant="outlined"
                                            disabled={true}
                                        />
                                        <img
                                            className="cursor-pointer"
                                            src={cancel}
                                            alt="delete"
                                            onClick={() => {
                                                // remove(index)
                                                handleDelete(item.value)
                                                remove(index)
                                            }}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    )))}
                    {!addList ?
                        <ButtonWithIcon icon={plus} text="Add new Item" onClick={() => setAddList(true)} />
                        :
                        <TextInput
                            variant="outlined"
                            placeholder="Type other categories..."
                            value={addListFormValue}
                            onChange={(e) => setAddListFormValue(e.target.value)}
                            postContent={
                                <img
                                    className="cursor-pointer"
                                    src={add}
                                    alt="add"
                                    onClick={() => {
                                        append({ label: addListFormValue })
                                        handleSubmit()
                                    }}
                                />
                            }
                        />
                    }
                </Grid>
            </Grid>
            <Grid xs={12}>
                <BasicButton
                    color="genieRed"
                    type="submit"
                    sx={{
                        textTransform: "none",
                        display: "flex",
                        color: "white",
                        width: "100%"
                    }}
                    onClick={close}
                >
                    close
                </BasicButton>
            </Grid>
        </>
    )
}

export default CategoryModal