import AppRoutes from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContextContainer from "./utils/Context/context-container";
import { BrowserRouter as Router } from "react-router-dom";
import {ReactQueryDevtools} from "react-query/devtools"

function App() {
  return (
    <>
      <Router>
        <ContextContainer>
          <AppRoutes />
          <ToastContainer />
        </ContextContainer>
        <ReactQueryDevtools position="bottom-right" />
      </Router>
    </>
  );
}

export default App;
