import { Checkbox, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../assets/images/FrameTrimmed2.png";
import BasicButton from "../../../components/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DrawerComponent from "../../../components/Drawer/Index";
import { useDiner } from "../../../utils/hooks";
import PeopleCount from "./PeopleCount";
import SpicePreferences from "./SpicePreferences";
import DietaryPreferences from "./DietaryPreferences";
import Allergic from "./Allergic";
import Carousel from "react-material-ui-carousel";
import { Paper, Typography, Button, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuCard from "../MenuCard";
import RecommendationCard from "../MenuCard/RecommendationCard";
// import Dot from '@mui/material/Dot';

function Recommendations() {
  const navigate = useNavigate();
  const [spicyModel, setSpicyModel] = useState(false);
  const [countModel, setCountModel] = useState(false);
  const [typeModel, setTypeModel] = useState(false);
  const [allergiesModel, setAllergiesModel] = useState(false);
  const [categoryModel, setCategoryModel] = useState(false);
  const restaurantId = useParams();

  const {
    decrementKidsCount,
    incrementKidsCount,
    decrementAdultCount,
    incrementAdultCount,
    spicyPreferences,
    setSpicyPreferences,
    useGetSpiceLevel,
    dietaryPreferencesText,
    setDietaryPreferencesText,
    dietaryPreferences,
    setDietaryPreferences,
    allergicPreferences,
    setAllergicPreferences,
    handleCheckboxChange,
    setGenieCheck,

    useGetGenieExp,
    useGetFoodCategory,
    genieRecommendation,
    countDiner,
  } = useDiner();

  useGetGenieExp({
    restId: restaurantId?.id,
  });

  const { data: menuCategory } = useGetFoodCategory(restaurantId?.id);

  const colors = ["#E7B6FE", "#FFD700", "#ADFF2F", "#FF69B4", "#87CEEB"];

  const { data: spiceLevel } = useGetSpiceLevel({ restId: restaurantId?.id });
  return (
    <>
      <Grid container direction="column">
        <Grid
          item
          sx={12}
          className=" bg-[#ED1703]  flex justify-center items-end h-[82px]  "
        >
          <img
            src={Logo}
            alt=""
            srcSet=""
            className="pb-3"
            height={"40px"}
            width={"166px"}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          height={"30px"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className=" p-3   "
        >
          <h1 className="text-quicksand  font-[700] text-[16px]">
            Your Grubgenie recommendations
          </h1>
          <CloseIcon onClick={() => navigate(-1)} />
        </Grid>
        <Grid container item spacing={2} sx={12} className="px-[16px]">
          <Grid item xs={4} alignItems={"center"}>
            <BasicButton
              color="secondary"
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                borderRadius: "50px",
                border: "1px solid #80808050",
              }}
              onClick={() => setCountModel(true)}
            >
              <h1 className="flex  truncate">
                {countDiner?.adultCount}{" "}
                {countDiner?.adultCount == 1 ? "person" : "persons"}
              </h1>
              <KeyboardArrowDownIcon
                sx={{ marginLeft: "1px", color: "#808080" }}
              />
            </BasicButton>
          </Grid>
          <Grid item xs={4} alignItems={"center"}>
            <BasicButton
              color="secondary"
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                borderRadius: "50px",
                border: "1px solid #80808050",
              }}
              onClick={() => setTypeModel(true)}
            >
              <h1 className="truncate">{dietaryPreferences?.value}</h1>
              <KeyboardArrowDownIcon
                sx={{ marginLeft: "4px", color: "#808080" }}
              />
            </BasicButton>
          </Grid>
          <Grid item xs={4} alignItems={"center"}>
            <BasicButton
              color="secondary"
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                borderRadius: "50px",
                border: "1px solid #80808050",
              }}
              onClick={() => setSpicyModel(true)}
            >
              {spicyPreferences?.value}
              <KeyboardArrowDownIcon
                sx={{ marginLeft: "4px", color: "#808080" }}
              />
            </BasicButton>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          sx={12}
          className="px-[16px] pt-[10px]"
        >
          <Grid item xs={4} alignItems={"center"}>
            <BasicButton
              color="secondary"
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                borderRadius: "50px",
                border: "1px solid #80808050",
              }}
              onClick={() => setAllergiesModel(true)}
            >
              Allergies
              <KeyboardArrowDownIcon
                sx={{ marginLeft: "4px", color: "#808080" }}
              />
            </BasicButton>
          </Grid>
          <Grid item xs={4} alignItems={"center"}>
            <BasicButton
              color="secondary"
              sx={{
                textTransform: "none",
                width: "100%",
                height: "36px",
                borderRadius: "50px",
                border: "1px solid #80808050",
              }}
              onClick={() => setCategoryModel(true)}
            >
              Category
              <KeyboardArrowDownIcon
                sx={{ marginLeft: "4px", color: "#808080" }}
              />
            </BasicButton>
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12} className="pt-[10px] ">
          <Grid item xs={12} alignItems={"center"}>
            {genieRecommendation &&
              Object.keys(genieRecommendation)?.map((product, index) => (
                <Carousel
                  autoPlay={false}
                  animation="slide"
                  sx={{ backgroundColor: colors[index % colors.length] }}
                >
                  {/* //TODO:HandleEmpty array */}
                  {genieRecommendation[product].map((item) => (
                    <Paper
                      key={index}
                      elevation={0}
                      sx={{
                        padding: 2,
                        height: "180px",
                        backgroundColor: colors[index % colors.length],
                      }}
                    >
                      <RecommendationCard
                        list={item}
                        isLoading={false}
                        color={colors[index % colors.length]}
                      />
                    </Paper>
                  ))}
                </Carousel>
              ))}
          </Grid>
        </Grid>
      </Grid>
      {countModel && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={countModel}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              //   height: "150px",
            },
          }}
          onClose={() => {
            return setCountModel(false), setGenieCheck(true);
          }}
        >
          <>
            <h1 className="pt-8  pl-3 font-quicksand font-[700] text-[20px]">
              Diner
            </h1>
            <PeopleCount
              decrementAdultCount={decrementAdultCount}
              countDiner={countDiner}
              incrementAdultCount={incrementAdultCount}
              decrementKidsCount={decrementKidsCount}
              incrementKidsCount={incrementKidsCount}
            />
          </>
        </DrawerComponent>
      )}
      {typeModel && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={typeModel}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              //   height: "150px",
            },
          }}
          onClose={() => {
            return setTypeModel(false), setGenieCheck(true);
          }}
        >
          <>
            <h1 className="pt-8  pl-3 font-quicksand font-[700] text-[20px]"></h1>
            <DietaryPreferences
              dietaryPreferences={dietaryPreferences}
              setDietaryPreferences={setDietaryPreferences}
              dietaryPreferencesText={dietaryPreferencesText}
              setDietaryPreferencesText={setDietaryPreferencesText}
            />
          </>
        </DrawerComponent>
      )}
      {categoryModel && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={categoryModel}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              //   height: "150px",
            },
          }}
          onClose={() => {
            return setCategoryModel(false), setGenieCheck(true);
          }}
        >
          <>
            <h1 className="pt-8  pl-3 font-quicksand font-[700] text-[20px]">
              Categories
            </h1>
            <Grid
              container
              item
              xs={12}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {menuCategory?.result?.map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ borderBottom: "2px solid #D1D1D1", padding: "4px " }}
                >
                  <Grid item xs={10}>
                    <h1 className="font-quicksand font-[600] text-[14px]">
                      {item.food_category}
                    </h1>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Checkbox
                      color="default"
                      // sx={{
                      //     color: "#ED1703",
                      //     '&.Mui-checked': {
                      //       color: "#ED1703",
                      //     },
                      //   }}
                      checked={item.checked}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        </DrawerComponent>
      )}
      {spicyModel && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={spicyModel}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              //   height: "150px",
            },
          }}
          onClose={() => {
            return setSpicyModel(false), setGenieCheck(true);
          }}
        >
          <>
            <h1 className="pt-8  pl-3 font-quicksand font-[700] text-[20px]"></h1>
            <SpicePreferences
              setSpicyPreferences={setSpicyPreferences}
              spicyPreferences={spicyPreferences}
              spiceLevel={spiceLevel}
            />
          </>
        </DrawerComponent>
      )}
      {allergiesModel && (
        <DrawerComponent
          position="bottom"
          visiblePuller={true}
          open={allergiesModel}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "20px 20px 0 0",
              //   height: "150px",
            },
          }}
          onClose={() => {
            return setAllergiesModel(false), setGenieCheck(true);
          }}
        >
          <>
            <h1 className="pt-8  pl-3 font-quicksand font-[700] text-[20px]"></h1>
            <Allergic
              setAllergicPreferences={setAllergicPreferences}
              allergicPreferences={allergicPreferences}
            />
          </>
        </DrawerComponent>
      )}
    </>
  );
}

export default Recommendations;
