import React from "react";
import { Grid } from "@mui/material";
import { PageLoader } from "../../../components/loader";
import plate from "../../../assets/images/plate.jpg";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DrawerComponent from "../../../components/Drawer/Index";
import MenuCardDrawer from "./MenuCardDrawer";
import { useDiner } from "../../../utils/hooks";
import NoDataComponent from "../../../components/NoDataComponent";
import { useLocation, useParams } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

function RecommendationCard({ list, isLoading, color }) {
  const { id: restId } = useParams();
  const location = useLocation();
  const tableId = new URLSearchParams(location.search).get("tableId");
  const {
    toggleDrawer,
    isDrawerOpenValue,
    setDrawerOpenValue,
    isDrawerOpen,
    OrderModel,
    PersonalizeEvent,
  } = useDiner();

  /**
   * Handles the click event for a specific item.
   * @param {{object}} item - The item that was clicked.
   * @returns None
   */
  // const handleClick = (item) => {
  //   toggleDrawer();
  //   PersonalizeEvent({ itemIds: [item?._id], evenType: "CLICK" });
  //   setDrawerOpenValue(item);
  // };

  return (
    <>
      {isLoading || !list ? (
        <PageLoader />
      ) : (
        <div>
          <Grid
            container
            direction="column"
            sm={12}
            // className={`${OrderModel ? "mb-[58px]" : ""} `}
          >
            {list.length === 0 ? (
              <NoDataComponent tittle={"Menu Not Found "} />
            ) : (
              <Grid item>
                {/* <Grid item onClick={() => handleClick(list)}> */}
                <div
                  key={list?._id}
                  className={`flex items-center ${"bg-"[color]}    `}
                >
                  <div className="flex flex-col justify-between  w-full">
                    <h1 className="font-Quicksand  w-fit font-[700] text-[12px] bg-red-50 rounded-xl h-fit p-1 px-4">
                      {list?.foodCategory.toUpperCase()}
                    </h1>
                    <div className="flex ml-2 mt-4">
                      <div className="max-w-[75%] flex flex-col justify-center  truncate  mr-2">
                        <h1 className=" font-quickSand font-[600] text-[16px]">
                          {list?.item_name}
                        </h1>
                        <h2 className="font-quickSand font-[700] text-[16px]  text-[#3F3F3F]">
                          AED {list?.oPrice - list?.dPrice}
                        </h2>

                        <p className="  font-quickSand font-[500] text-[12px] max-w-[65vw] text-[#3F3F3F]">
                          {list?.description}
                        </p>
                        <p className="  font-quickSand font-[500] text-[12px] w-[70vw] text-[#3F3F3F] truncate">
                          Contains: {list?.contains}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-6">
                    <div className="bg-[#3F3F3F] rounded px-6 p-1">
                      <ShoppingCartOutlinedIcon sx={{ color: "white" }} />
                    </div>
                    <img
                      src={plate}
                      alt="Menu"
                      className="h-[100px] rounded-[50%]"
                    />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      <DrawerComponent
        position="bottom"
        open={isDrawerOpen}
        visiblePuller={true}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0 0",
          },
        }}
        onClose={() => toggleDrawer({ restId })}
      >
        <MenuCardDrawer
          data={isDrawerOpenValue}
          addToChart={() => toggleDrawer({ restId, tableId })}
        />
      </DrawerComponent>
    </>
  );
}

export default RecommendationCard;
